import React from "react";
import "./AddressShower.scss";

class AddressShower extends React.Component {
  render() {
    const { name, body, handleClick } = this.props;
    return (
      <React.Fragment>
        <div className="main-container">
          <div className="container">
            <p className="name">{name}</p>
            <React.Fragment>
              {handleClick ? (
                <button onClick={handleClick} className="button">
                  edit
                </button>
              ) : (
                <></>
              )}
            </React.Fragment>
          </div>
          <div className="body">
            <p className="body-text">{body}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddressShower;
