import downscale from "downscale";
const imageLoader = require("Lib/loadImage");

export async function downscaleImage(
  imageFile,
  desiredPercentOfOriginalSize,
  fileName
) {
  const imageSize = await getImageSize(imageFile);
  const desiredWidth = imageSize.width * desiredPercentOfOriginalSize;
  const desiredHeight = imageSize.height * desiredPercentOfOriginalSize;
  const downscaledImage = await downscale(
    imageFile,
    desiredWidth,
    desiredHeight,
    { quality: 0.85, returnBlob: true }
  ).then((res) => {
    return res;
  });
  return downscaledImage;
}

export function getImageSize(imageFile) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = new Image();
      img.src = e.target.result;
      img.onload = function () {
        resolve({ height: this.height, width: this.width });
      };
    };
    reader.readAsDataURL(imageFile);
  });
}

export function loadImage(file, options) {
  return new Promise((resolve, reject) => {
    imageLoader(
      file,
      function (img, data) {
        const exif = data.exif;
        img.toBlob((blob) => resolve({ imageBlob: blob, exif }), "image/jpeg");
      },
      { canvas: true, meta: true, ...options }
    );
  });
}
