import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import I18n from "./I18n/I18n";

const firebaseConfig = {
  apiKey: "AIzaSyDsbLt5WE_oRy9JUDmIoNVvHtFNJEfODtg",
  authDomain: "scanning-api-arylla.firebaseapp.com",
  projectId: "scanning-api-arylla",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export const signOut = () => {
  firebase.auth().signOut();
};

export const user = () => {
  return firebase.auth().currentUser;
};

export const signIn = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const logResult = (data, errorInput = null) => {
  //Parse Error
  if (!(errorInput == null)) {
    let errLog = "";
    if (errorInput.message) {
      errLog += errorInput.message;
    }
    if (errorInput.stack) {
      errLog += "\nstack: " + errorInput.stack;
    }
    if (errorInput.status) {
      errLog += "\nstatus: " + errorInput.status;
    }
    if (errorInput.response) {
      errLog += "\nresponse: " + errorInput.response;
    }
    if (errorInput.toString()) {
      errLog += "\nstring: " + errorInput.toString();
    }
    data["error"] = errLog;
  }

  data["dateTime"] = firebase.firestore.FieldValue.serverTimestamp();
  data["env"] = process.env.REACT_APP_ENV;

  //Drop out the 'products' key because it can be very long
  if ("products" in data) {
    delete data["products"];
  }

  //Drop out undefined values
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      delete data[key];
    }
  });

  db.collection("webapp_usage").add(data);
};

export const getDemoProduct = async (project, serial) => {
  const productsRef = db
    .collection("products")
    .where("arylla.project", "==", project)
    .where("arylla.serial", "==", serial)
    .limit(1);

  let product = await productsRef.get();

  if (product.docs.length === 0) {
    console.log("NO PRODUCT FOUND!");
    return false;
  }

  const doc = product.docs[0];
  const docData = doc.data();
  return docData;
};

export const getProduct = async (project, serial) => {
  let productsRef = db
    .collection("read-write")
    .orderBy("dateTime", "desc")
    .where("project", "==", project)
    .where("serial", "==", serial)
    .limit(1);
  await firebase.auth().signInAnonymously();
  let product = await productsRef.get();

  if (product.docs.length === 0)
    return {
      text: I18n.scan.result.readWrite.noData,
    };

  const doc = product.docs[0];
  const docData = doc.data();

  return docData;
};

export const writeProduct = (data) => {
  data["dateTime"] = firebase.firestore.FieldValue.serverTimestamp();

  db.collection("read-write").add(data);
};

export const createListing = async () => {
  const data = {
    datetime_created: firebase.firestore.FieldValue.serverTimestamp(),
    status: "INCOMPLETE",
  };
  var doc = db.collection("listings_" + process.env.REACT_APP_ENV).doc();
  var docId;
  await doc.set(data).then(() => {
    docId = doc.id;
  });
  return docId;
};

export const getDocument = async (collection, id) => {
  const docRef = db.collection(collection).doc(id);
  let doc = await docRef.get();
  return doc.data();
};

export const updateDocument = async (collection, id, data) => {
  await db.collection(collection).doc(id).update(data);
};

export const getUser = async (user) => {
  const usersRef = db.collection("users").doc(user.uid);
  let userDoc = await usersRef.get();
  if (userDoc.exists) {
    return userDoc.data();
  } else {
    const userData = {
      contact: {
        email: user.email,
        name: user.displayName,
        phone: user.phoneNumber,
      },
    };
    await db.collection("users").doc(user.uid).set(userData);
    return userData;
  }
};
