import React from "react";
import { Transition } from "react-transition-group";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";

import Checkbox from "rc-checkbox";

import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ReactLoading from "react-loading";

import "./RecommerceScanner.scss";
import { AddressShower, BenButton, AryllaSpinner } from "SharedComponents";
import I18n from "I18n";
import { loadImage } from "Utils";
import { HTTP, LOWERCASE_REDIRECTS } from "Constants";

import * as FirestoreService from "../../firebase";
import * as Recommerce from "../../recommerce";

class HttpError extends Error {
  constructor(status, response) {
    super(status);
    this.name = "HttpError";
    this.status = status;
    this.response = response;
  }
}

class ScanError extends Error {
  constructor(message) {
    super(message);
    this.name = "ScanError";
    this.message = message;
  }
}

class RecommerceScanner extends React.Component {
  state = {
    isLoading: false,
    isLoadingRecommerce: false,
    showScanResult: false,
    scanTitle: "",
    scanBody: "",
    scanTitleStyle: "",
    listing: "",
    showHelpModal: false,
    forceEditAddress: false,
    forceGetPermission: false,
    accountConfirmed: false,
    page: "",
    productTitle: "",
    productImageSrc: "",
    productSize: "",
    condition: "",
    contractData: "",
    firestoreId: "",
    user: null,
    name: "",
    email: "",
    street1: "",
    street2: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    contact: "",
    termsOfService: "",
    ebayUrl: "",
    userPrice: "",
    progress: 0,
  };

  PrettyText = () => {
    return (
      <>
        <div className="pretty-text">
          <p>{this.props.text}</p>
        </div>
      </>
    );
  };

  ProductImage = () => {
    return (
      <>
        <div className="recommerce-image-outside-wrapper basic-flex">
          <div className="recommerce-image-wrapper">
            <img
              alt={"product"}
              className="recommerce-product-image"
              src={this.state.productImageSrc}
            />
          </div>
        </div>
      </>
    );
  };

  CompactProductImage = () => {
    return (
      <>
        <div className="recommerce-image-wrapper-compact basic-flex">
          <img
            alt={"product"}
            className="recommerce-product-image"
            src={this.state.productImageSrc}
          />
        </div>
      </>
    );
  };

  CompactProductPreview = () => {
    return (
      <>
        <div className="compact-product-container">
          <this.CompactProductImage />
          <div className="compact-text">
            <p>{this.state.productInfo}</p>
          </div>
        </div>
      </>
    );
  };

  massageScanResult(response) {
    const result = response.result;
    const project = response.project;
    const id = response.id;
    const genericResponse = {
      project: project,
      result: result,
      id: id,
    };
    if (result > 0) {
      return {
        ...genericResponse,
        ...{
          scanTitle: this.props.hideAuthentic
            ? ""
            : I18n.recommerce.result.authentic.title,
          scanTitleStyle: this.props.hideAuthentic ? "" : "success",
          scanBody: this.props.hideAuthentic
            ? I18n.recommerce.result.authentic.description
            : I18n.recommerce.result.authentic.serial + " " + result.toString(),
          listing: true,
        },
      };
    }

    if (result === 0) {
      return {
        ...genericResponse,
        ...{
          scanTitle: I18n.recommerce.result.nonAuthentic.title,
          scanTitleStyle: "danger",
          scanBody: I18n.recommerce.result.nonAuthentic.description,
        },
      };
    }

    return {
      ...genericResponse,
      ...{
        scanTitle: I18n.errors.scanFailed,
        scanTitleStyle: "warning",
        scanBody:
          this.props.customText.errorScanMessage ||
          I18n.errors[result] ||
          I18n.errors.imageProcessingGeneric,
      },
    };
  }

  productPreviewAdvance = () => {
    this.setState({
      page: "condition",
    });
  };

  ProductPreview = () => {
    return (
      <>
        <this.ProductImage />
        <div className="recommerce-product-info basic-flex basic-padding">
          Size: {this.state.productSize}
        </div>
      </>
    );
  };

  ProductPreviewButtons = () => {
    return (
      <>
        <BenButton
          label={I18n.recommerce.buttons.productPreview.advance}
          handleClick={this.productPreviewAdvance}
        />
        <BenButton
          label={I18n.recommerce.buttons.productPreview.return}
          handleClick={this.closeResultDrawer}
          reverse={true}
        />
      </>
    );
  };

  conditionSelect = (response) => {
    this.setState({
      condition: response["value"],
    });
  };

  conditionAdvance = () => {
    //Show loading screen because it takes some time to get the price
    this.setState({
      isLoadingRecommerce: true,
    });
    //Save condition to firebase and advance to next screen
    const addConditionData = {
      condition: this.state.condition,
    };
    FirestoreService.updateDocument(
      "listings_" + process.env.REACT_APP_ENV,
      this.state.firestoreId,
      addConditionData
    ).then(() => {
      //Get contract info from api
      Recommerce.getContractInfo(this.state.firestoreId).then((result) => {
        //Save to state var
        this.setState({
          contractData: result["pretty_print"].join("\n"),
          userPrice: result["user_price"],
          page: "preliminaryContractPricing",
          isLoadingRecommerce: false,
        });
      });
    });
  };

  conditionReturn = () => {
    this.setState({
      page: "productPreview",
    });
  };

  Condition = () => {
    return (
      <>
        <this.ProductImage />
        <Dropdown
          className="basic-flex"
          options={Recommerce.conditionOptions}
          onChange={this.conditionSelect}
          placeholder="Select condition"
          value={this.state.condition}
        />
      </>
    );
  };

  ConditionButtons = () => {
    return (
      <>
        <BenButton
          isDisabled={!this.state.condition}
          label={I18n.recommerce.buttons.condition.advance}
          handleClick={this.conditionAdvance}
        />
        <BenButton
          label={I18n.recommerce.buttons.globalReturn}
          handleClick={this.conditionReturn}
          reverse={true}
        />
      </>
    );
  };

  contractAdvance = () => {
    //Update listing status
    const addListingData = {
      status: "PENDING",
    };
    FirestoreService.updateDocument(
      "listings_" + process.env.REACT_APP_ENV,
      this.state.firestoreId,
      addListingData
    ).then(() => {
      //Show new page
      this.setState({
        page: "userInfo",
      });
    });
  };

  preliminaryContractPricingAdvance = () => {
    this.setState({
      page: "userPreliminaryContractReview",
      user: true,
      accountConfirmed: true,
    });
  };

  preliminaryContractPricingReturn = () => {
    this.setState({
      page: "condition",
    });
  };

  PreliminaryContractPricing = () => {
    return (
      <>
        <this.CompactProductPreview />
        <h3 className="basic-flex basic-padding">
          {I18n.recommerce.preliminaryContract.tradeInValue}
        </h3>
        <h1 className="user-price basic-flex basic-padding">
          {this.state.userPrice}
        </h1>
      </>
    );
  };

  PreliminaryContractPricingButtons = () => {
    return (
      <>
        <BenButton
          label={I18n.recommerce.buttons.preliminaryContract.pricing.advance}
          handleClick={this.preliminaryContractPricingAdvance}
        />
        <BenButton
          label={I18n.recommerce.buttons.productPreview.return}
          handleClick={this.closeResultDrawer}
          reverse={true}
        />
      </>
    );
  };

  preliminaryContractReviewReturn = () => {
    this.setState({
      page: "preliminaryContractPricing",
    });
  };

  PreliminaryContractReview = () => {
    return (
      <>
        <this.CompactProductPreview />
        <p className="contract-information basic-flex basic-padding">
          Resale App makes it easy to trade and resell{" "}
          <strong>authentic</strong> products. It matches you with a buyer in
          the secondary market. Please visit arylla.com for a full list of terms
          and conditions.
          <br />
          <br />
          Click Approve to proceed.
        </p>
      </>
    );
  };

  PreliminaryContractReviewButtons = () => {
    return (
      <>
        <React.Fragment>
          {this.state.user ? (
            this.state.accountConfirmed ? (
              <BenButton
                label={
                  I18n.recommerce.buttons.preliminaryContract.review
                    .isConfirmedAdvance
                }
                handleClick={this.userInfoAdvance}
              />
            ) : (
              <BenButton
                label={
                  I18n.recommerce.buttons.preliminaryContract.review
                    .isSignedInAdvance
                }
                handleClick={this.contractAdvance}
              />
            )
          ) : (
            <BenButton
              label={
                I18n.recommerce.buttons.preliminaryContract.review
                  .isSignedOutAdvance
              }
              handleClick={this.contractAdvance}
            />
          )}
        </React.Fragment>
        <BenButton
          label={I18n.recommerce.buttons.globalReturn}
          handleClick={this.preliminaryContractReviewReturn}
          reverse={true}
        />
      </>
    );
  };

  UserPreliminaryContractReview = () => {
    return <this.PreliminaryContractReview />;
  };

  UserPreliminaryContractReviewButtons = () => {
    return <this.PreliminaryContractReviewButtons />;
  };

  userInfoAdvance = () => {
    //Show loading screen because it takes some time to upload to ebay
    this.setState({ isLoadingRecommerce: true });

    //List product
    Recommerce.listOnEbay(this.state.firestoreId).then((response) => {
      this.setState({
        ebayUrl: response.url,
        page: "complete",
        isLoadingRecommerce: false,
        scanTitle: I18n.recommerce.titles.complete,
      });
    });
  };

  handleLogin = () => {
    FirestoreService.signIn().then((result) => {
      this.setState({
        user: result.user,
      });
      FirestoreService.getUser(result.user).then((result) => {
        let existingAddress = {};
        //Fill in name if available (address name take priority)
        if (result.hasOwnProperty("contact")) {
          const contactKeys = ["name", "email"];
          for (const contactKey of contactKeys) {
            if (result.contact.hasOwnProperty(contactKey))
              existingAddress[contactKey] = result.contact[contactKey];
          }
        }

        //Fill in permission info if available
        if (result.hasOwnProperty("permission")) {
          const permissionKeys = ["contact", "termsOfService"];
          for (const permissionKey of permissionKeys) {
            if (result.permission.hasOwnProperty(permissionKey))
              existingAddress[permissionKey] = result.permission[permissionKey];
          }
        } else this.setState({ forceGetPermission: true });

        //Fill in address info if available
        if (result.hasOwnProperty("address")) {
          const keys = [
            "name",
            "street1",
            "street2",
            "city",
            "zip",
            "state",
            "country",
          ];
          for (const key of keys) {
            if (result.address.hasOwnProperty(key))
              existingAddress[key] = result.address[key];
          }
        }

        this.setState({ ...existingAddress });

        //Determine if an address must be input
        if (
          !(
            this.state.name &&
            this.state.email &&
            this.state.street1 &&
            this.state.city &&
            this.state.zip &&
            this.state.state &&
            this.state.country
          )
        )
          this.setState({ forceEditAddress: true });

        //Determine if permission must be input
        if (!this.state.termsOfService)
          this.setState({ forceGetPermission: true });
        else this.setState({ accountConfirmed: true });
      });
    });
  };

  handleLogout = () => {
    FirestoreService.signOut().then((result) => {
      this.setState({
        user: null,
      });
    });
  };

  RecommerceSignIn = () => {
    return (
      <>
        <button className="pretty-button basic-flex" onClick={this.handleLogin}>
          {I18n.recommerce.buttons.preliminaryContract.signIn.google}
        </button>
      </>
    );
  };

  handleCheckboxChange = ({ target }) => {
    this.setState({ [target.name]: target.checked });
  };

  handleUserChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  pick(o, ...fields) {
    return fields.reduce((a, x) => {
      if (o.hasOwnProperty(x)) a[x] = o[x];
      return a;
    }, {});
  }

  formatAddress = () => {
    let addressString = "";
    if (this.state.street2) addressString += this.state.street2 + "-";
    addressString += this.state.street1;
    addressString += "\n";
    addressString += this.state.city + ", ";
    addressString += this.state.state + ", ";
    addressString += this.state.country;
    addressString += "\n";
    addressString += this.state.zip;
    return addressString;
  };

  handleEditAddressClick = () => {
    this.setState({ forceEditAddress: true });
  };

  recommerceUserGetPermissionAdvance = () => {
    //Write new user data to firestore
    FirestoreService.updateDocument("users", this.state.user.uid, {
      permission: this.pick(this.state, "contact", "termsOfService"),
    });

    this.setState({ forceGetPermission: false, accountConfirmed: true });
  };

  RecommerceUserGetPermission = () => {
    return (
      <>
        <label className="basic-flex basic-padding">
          <Checkbox onChange={this.handleCheckboxChange} name="contact" />
          &nbsp; {I18n.recommerce.permission.contact}
        </label>
        <label className="basic-flex basic-padding">
          <Checkbox
            onChange={this.handleCheckboxChange}
            name="termsOfService"
          />
          &nbsp; {I18n.recommerce.permission.termsOfService}
        </label>
      </>
    );
  };

  RecommerceUserGetPermissionButtons = () => {
    return (
      <>
        <BenButton
          label={I18n.recommerce.buttons.globalAdvance}
          isDisabled={!this.state.termsOfService}
          handleClick={this.recommerceUserGetPermissionAdvance}
        />
      </>
    );
  };

  recommerceUserInfoDisplayAdvance = () => {
    this.setState({
      accountConfirmed: true,
      page: "preliminaryContractReview",
    });
  };

  RecommerceUserInfoDisplay = () => {
    return (
      <>
        <AddressShower
          className="basic-flex"
          name={
            I18n.recommerce.buttons.preliminaryContract.accountInformation.name
          }
          body={this.state.name}
        />
        <AddressShower
          className="basic-flex"
          name={
            I18n.recommerce.buttons.preliminaryContract.accountInformation.email
          }
          body={this.state.email}
        />
        <AddressShower
          className="basic-flex"
          name={
            I18n.recommerce.buttons.preliminaryContract.accountInformation
              .address
          }
          body={this.formatAddress()}
          handleClick={this.handleEditAddressClick}
        />
      </>
    );
  };

  RecommerceUserInfoDisplayButtons = () => {
    return (
      <>
        <BenButton
          label={
            I18n.recommerce.buttons.preliminaryContract.accountInformation
              .advance
          }
          handleClick={this.recommerceUserInfoDisplayAdvance}
        />
      </>
    );
  };

  recommerceUserInfoEditAdvance = () => {
    this.setState({ forceEditAddress: false });
  };

  RecommerceUserInfoEdit = () => {
    return (
      <>
        <div className="basic-flex">
          <FloatingLabel
            className="floating-label"
            name="name"
            placeholder="Your name"
            type="text"
            value={this.state.name}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="street1"
            placeholder="Address"
            type="text"
            value={this.state.street1}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="street2"
            placeholder="Apartment, suite, etc. (optional)"
            type="text"
            value={this.state.street2}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="city"
            placeholder="City"
            type="text"
            value={this.state.city}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="state"
            placeholder="State / Province"
            type="text"
            value={this.state.state}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="zip"
            placeholder="Zip / Postal Code"
            type="text"
            value={this.state.zip}
            onChange={this.handleUserChange}
          />

          <FloatingLabel
            className="floating-label"
            name="country"
            placeholder="Country"
            type="text"
            value={this.state.country}
            onChange={this.handleUserChange}
          />
        </div>
      </>
    );
  };

  RecommerceUserInfoEditButtons = () => {
    return (
      <>
        <BenButton
          label={I18n.recommerce.buttons.globalAdvance}
          handleClick={this.recommerceUserInfoEditAdvance}
          isDisabled={
            !(
              this.state.name &&
              this.state.email &&
              this.state.street1 &&
              this.state.city &&
              this.state.zip &&
              this.state.state &&
              this.state.country
            )
          }
        />
      </>
    );
  };

  User = () => {
    return (
      <>
        {this.state.user ? (
          this.state.name &&
          this.state.email &&
          this.state.street1 &&
          this.state.city &&
          this.state.zip &&
          this.state.state &&
          this.state.country &&
          !this.state.forceEditAddress ? (
            this.state.termsOfService && !this.state.forceGetPermission ? (
              <this.RecommerceUserInfoDisplay />
            ) : (
              <this.RecommerceUserGetPermission />
            )
          ) : (
            <this.RecommerceUserInfoEdit />
          )
        ) : (
          <this.RecommerceSignIn />
        )}
      </>
    );
  };

  UserButtons = () => {
    return (
      <>
        {this.state.user ? (
          this.state.name &&
          this.state.email &&
          this.state.street1 &&
          this.state.city &&
          this.state.zip &&
          this.state.state &&
          this.state.country &&
          !this.state.forceEditAddress ? (
            this.state.termsOfService && !this.state.forceGetPermission ? (
              <this.RecommerceUserInfoDisplayButtons />
            ) : (
              <this.RecommerceUserGetPermissionButtons />
            )
          ) : (
            <this.RecommerceUserInfoEditButtons />
          )
        ) : (
          <></>
        )}
      </>
    );
  };

  Complete = () => {
    return (
      <>
        <p className="contract-information basic-flex basic-padding">
          {I18n.recommerce.complete}
        </p>
        <button
          className="pretty-button basic-flex"
          onClick={() => window.location.assign(this.state.ebayUrl)}
        >
          {I18n.recommerce.buttons.complete.ebayUrl}
        </button>
      </>
    );
  };

  CompleteButtons = () => {
    return (
      <>
        <BenButton
          label={I18n.recommerce.buttons.complete.signInAdvance}
          handleClick={this.closeResultDrawer}
        />
      </>
    );
  };

  closeResultDrawer = () => {
    if (this.state.showScanResult) {
      this.setState({ showScanResult: false, isLoading: false });
      setTimeout(
        () =>
          this.setState({
            isLoadingRecommerce: false,
            scanTitle: "",
            scanBody: "",
            scanTitleStyle: "",
            listing: "",
            showHelpModal: false,
            page: "",
            productTitle: "",
            productImageSrc: "",
            productSize: "",
            condition: "",
            contractData: "",
            firestoreId: "",
            ebayUrl: "",
            userPrice: "",
            progress: 0,
          }),
        1000
      );
    }
    //Reset state variables
  };

  redirectAlgorithmCase(algorithm) {
    algorithm = algorithm.toLowerCase();
    return LOWERCASE_REDIRECTS[algorithm] || algorithm;
  }

  createListingAndGetLocation = async () => {
    const firestoreId = await FirestoreService.createListing();
    await Recommerce.getUserLocation(firestoreId);
    this.setState({ firestoreId: firestoreId, progress: 20 });
  };

  analyzePhoto = async (e) => {
    var massagedResults = null;
    const startDateTime = Date.now();
    const algorithm = this.redirectAlgorithmCase(this.props.algorithm);
    const formData = new FormData();
    const image = e.target.files[0];
    const { imageBlob, exif } = await loadImage(image, {});
    const sendDateTime = Date.now();

    formData.append("image", imageBlob);
    formData.append("algorithm", algorithm.toString());
    if (exif) {
      formData.append("exif", JSON.stringify(exif.getAll()));
    }
    if (this.props.url) {
      formData.append("source", this.props.url);
    }
    formData.append(
      "dateTimeLog",
      JSON.stringify({ start: startDateTime, send: sendDateTime })
    );

    try {
      const response = await fetch(`${HTTP.BASE_URL}/v1/scan`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response);
      }

      const responseJson = await response.json();

      massagedResults = this.massageScanResult(responseJson);
      if (massagedResults.listing) {
        //There is a valid product found
        this.setState({
          ...this.pick(
            massagedResults,
            "scanTitle",
            "scanBody",
            "scanTitleStyle",
            "listing"
          ),
        });
      } else {
        //valid product was not found (error or blank)
        this.setState({
          ...this.pick(
            massagedResults,
            "scanTitle",
            "scanBody",
            "scanTitleStyle",
            "listing"
          ),
        });
        throw new ScanError("Invalid Response");
      }
    } catch (err) {
      if (err instanceof HttpError) {
        //Show the user 400 level messages
        if (err.status === 406) {
          //User facing error
          const responseJson = await err.response.json();
          this.setState({
            ...this.pick(
              this.massageScanResult({
                result: responseJson.errorCode,
              }),
              "scanTitle",
              "scanBody",
              "scanTitleStyle",
              "listing"
            ),
          });
        } else {
          //Developer facing error
          const responseJson = await err.response.json();
          this.setState({
            ...this.pick(
              this.massageScanResult({
                result: responseJson.errorCode,
              }),
              "scanTitle",
              "scanBody",
              "scanTitleStyle",
              "listing"
            ),
          });
        }
      } else if (err instanceof TypeError) {
        this.setState({
          ...this.pick(
            this.massageScanResult({
              result: { result: -1000 },
            }),
            "scanTitle",
            "scanBody",
            "scanTitleStyle",
            "listing"
          ),
        });
      } else {
        this.setState({
          ...this.pick(
            this.massageScanResult({
              result: { result: -2000 }, //unknown error
            }),
            "scanTitle",
            "scanBody",
            "scanTitleStyle",
            "listing"
          ),
        });
      }
      throw new ScanError("Scanning Error");
    } finally {
      return massagedResults;
    }
  };

  setScanAndPrice = async (massagedResults) => {
    const addScanData = {
      scan: {
        project: massagedResults.project,
        result: massagedResults.result,
        id: massagedResults.id,
      },
    };
    await FirestoreService.updateDocument(
      "listings_" + process.env.REACT_APP_ENV,
      this.state.firestoreId,
      addScanData
    );
    this.setState({ progress: 50 });
    await Recommerce.singleRecommerceFetch(
      this.state.firestoreId,
      "list_product",
      "product"
    );
    this.setState({ progress: 60 });
    let pricingEndpoint = "pricing";
    if (this.props.msrpPrice) {
      pricingEndpoint = "pricing_msrp";
    }
    await Recommerce.singleRecommerceFetch(
      this.state.firestoreId,
      "list_product",
      pricingEndpoint
    );
  };

  onChange = async (e) => {
    this.setState({
      isLoading: true,
      showScanResult: false,
    });

    try {
      //1
      const [, massagedResults] = await Promise.all([
        this.createListingAndGetLocation(),
        this.analyzePhoto(e),
      ]);
      this.setState({ progress: 40 });
      //2
      if (!massagedResults.listing) throw new ScanError("Invalid results");

      await Promise.all([
        this.setScanAndPrice(massagedResults),
        Recommerce.singleRecommerceFetch(
          this.state.firestoreId,
          "list_product",
          "parcel"
        ),
        Recommerce.singleRecommerceFetch(
          this.state.firestoreId,
          "list_product",
          "ship/buy"
        ),
        FirestoreService.updateDocument(
          "listings_" + process.env.REACT_APP_ENV,
          this.state.firestoreId,
          { condition: "Like New" }
        ),
      ]);
      this.setState({ progress: 80 });
      //3
      const [contractResult, listing] = await Promise.all([
        Recommerce.singleRecommerceFetch(
          this.state.firestoreId,
          "list_product",
          "contract"
        ),
        FirestoreService.getDocument(
          "listings_" + process.env.REACT_APP_ENV,
          this.state.firestoreId
        ),
      ]);

      //Show result
      this.setState({
        productTitle: listing.product.title,
        productImageSrc: listing.product.image,
        contractData: contractResult["pretty_print"].join("\n"),
        userPrice: contractResult["user_price"],
        page: "preliminaryContractPricing",
        condition: "Like New",
        isLoadingRecommerce: false,
        showScanResult: true,
      });
      if (this.props.hideAuthentic) {
        this.setState({
          scanTitle: listing.product.title,
          productInfo: "Size: " + listing.product.size,
        });
      } else {
        this.setState({
          productInfo: listing.product.title,
        });
      }
    } catch (err) {
      this.setState({ showScanResult: true });
    } finally {
      this.setState({ progress: 100 });
      setTimeout(() => this.setState({ isLoading: false }), 2000);
    }
  };

  render() {
    const {
      isLoading,
      isLoadingRecommerce,
      scanTitle,
      scanBody,
      scanTitleStyle,
      listing,
      page,
      showScanResult,
      showHelpModal,
      progress,
    } = this.state;
    const scanButtonText = isLoading
      ? ""
      : this.props.customText.scanButton
      ? this.props.customText.scanButton
      : I18n.recommerce.scanButton;
    const loadingClass = isLoading ? "loading" : "";
    const showFullDrawerClass = listing ? "full" : "";

    return (
      <React.Fragment>
        <div
          className="scan-container-recommerce"
          onClick={this.closeResultDrawer}
        >
          <div className="instructions-container-recommerce">
            <span className="title-recommerce">
              <b>
                {this.props.customText.instructions
                  ? this.props.customText.instructions
                  : I18n.recommerce.instructions.title}
              </b>
            </span>
            <span className="step">
              {this.props.customText.step1
                ? this.props.customText.step1
                : I18n.recommerce.instructions.step1}
            </span>
            <span className="step">
              {this.props.customText.step2
                ? this.props.customText.step2
                : I18n.recommerce.instructions.step2}
            </span>
            <span className="step">
              {this.props.customText.step3
                ? this.props.customText.step3
                : I18n.recommerce.instructions.step3}
            </span>
          </div>
          {isLoading ? (
            <div className="progress-bar-container">
              <CircularProgressbarWithChildren
                value={`${progress}`}
                background
                backgroundPadding="6"
                styles={buildStyles({
                  pathColor: this.props.loadPathColor || "lightgrey",
                  backgroundColor: this.props.loadColor || "#666666",
                  trailColor: this.props.loadColor || "#666666",
                })}
              >
                <ReactLoading
                  type="bubbles"
                  color={this.props.loadPathColor || "lightgrey"}
                />
              </CircularProgressbarWithChildren>
            </div>
          ) : (
            <div className="scan-button-container-recommerce">
              <label
                htmlFor="file-upload"
                className={`scan-button-recommerce ${loadingClass}`}
              >
                {scanButtonText}
              </label>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                id="file-upload"
                onChange={this.onChange}
              />
            </div>
          )}

          <button
            onClick={() => this.setState({ showHelpModal: true })}
            className="help-link-recommerce"
          >
            {this.props.customText.tutorial
              ? this.props.customText.tutorial
              : I18n.recommerce.tutorial}
          </button>
        </div>

        <div
          className={`help-container-recommerce ${showHelpModal ? "show" : ""}`}
          onClick={() => this.setState({ showHelpModal: false })}
        >
          <div className="help-modal-recommerce">
            <div className="instructions-gif-recommerce">
              <div
                className="close-button-recommerce"
                onClick={() => this.setState({ showHelpModal: false })}
              >
                x
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/${
                  this.props.instructionsVideo || "photo-instructions.gif"
                }`}
                alt="instructional video"
              ></img>
            </div>
          </div>
        </div>

        <Transition in={showScanResult} timeout={1000}>
          {(transitionState) => (
            <div
              className={`result-container-recommerce ${transitionState} ${showFullDrawerClass}`}
              onClick={this.closeWriteForm}
            >
              <div className={`results-card-recommerce `}>
                <div className={`header-recommerce ${scanTitleStyle}`}>
                  <span>{scanTitle}</span>
                </div>
                <div className="body-recommerce">{scanBody}</div>
              </div>
              <div className="result-body-recommerce">
                {isLoadingRecommerce ? (
                  <AryllaSpinner />
                ) : (
                  <>
                    {page === "productPreview" && (
                      <React.Fragment>
                        <this.ProductPreview />
                      </React.Fragment>
                    )}
                    {page === "condition" && (
                      <React.Fragment>
                        <this.Condition />
                      </React.Fragment>
                    )}
                    {page === "preliminaryContractPricing" && (
                      <React.Fragment>
                        <this.PreliminaryContractPricing />
                      </React.Fragment>
                    )}
                    {page === "userPreliminaryContractReview" && (
                      <React.Fragment>
                        <this.UserPreliminaryContractReview />
                      </React.Fragment>
                    )}
                    {page === "preliminaryContractReview" && (
                      <React.Fragment>
                        <this.PreliminaryContractReview />
                      </React.Fragment>
                    )}
                    {page === "userInfo" && (
                      <React.Fragment>
                        <this.User />
                      </React.Fragment>
                    )}
                    {page === "complete" && (
                      <React.Fragment>
                        <this.Complete />
                      </React.Fragment>
                    )}
                  </>
                )}
              </div>
              <div className="result-buttons">
                {!isLoadingRecommerce && (
                  <>
                    {page === "productPreview" && (
                      <this.ProductPreviewButtons />
                    )}
                    {page === "condition" && <this.ConditionButtons />}
                    {page === "preliminaryContractPricing" && (
                      <this.PreliminaryContractPricingButtons />
                    )}
                    {page === "userPreliminaryContractReview" && (
                      <this.UserPreliminaryContractReviewButtons />
                    )}
                    {page === "preliminaryContractReview" && (
                      <this.PreliminaryContractReviewButtons />
                    )}
                    {page === "userInfo" && <this.UserButtons />}
                    {page === "complete" && <this.CompleteButtons />}
                  </>
                )}
              </div>
            </div>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}

export default RecommerceScanner;
