import React from 'react';
import { downscaleImage } from 'Utils'

class TimingTest extends React.Component {
  NUM_REQUESTS = 100
  DOWNSCALE_PERCENT = 0.85
  DO_DOWNSCALE = false

  files = []
  fileIndex = 0
  results = []
  fileSize = null
  output = [
    ["Image name", "Image size (Bytes)", "Model result", "Request preparation (ms)", "Image uplaod (ms)", "Serverside validation (ms)", "C++ model preocessing time (ms)", "Response download (ms)", "Unaccounted for client time (ms)", "Unaccounted for server time (ms)"]
  ]

  sendRequest = (iteration, formData, fileName, fileSize) => {
    let uploadStart = 0
    let uploadEnd = 0

    var xhr = new XMLHttpRequest();
    xhr.open("POST", `https://na-test-xnfwxuz2ha-nn.a.run.app/v1/timing_test?${new Date().getTime()}`);
    xhr.upload.onloadstart = () => uploadStart = Date.now()
    xhr.upload.onloadend = () => uploadEnd = Date.now()
    xhr.onload = (result) => {
      this.results.push({ result: xhr.responseText, imageUploadTime: (uploadEnd - uploadStart), fileName: fileName, fileSize: fileSize })
      if (iteration < this.NUM_REQUESTS - 1) {
        iteration++
        this.sendRequest(iteration, formData, fileName, fileSize)
      } else {
        this.fileIndex++
        const nextFile = this.files[this.fileIndex]
        if (nextFile) {
          this.calculateLoadTimesForImage(nextFile)
        } else {
          this.saveResults()
        }
      }
    }
    xhr.send(formData)
  }

  saveResults = () => {

    const allResources = performance.getEntriesByType("resource");
    const resources = allResources.filter(resource => resource.name.includes('https://na-test-xnfwxuz2ha-nn.a.run.app/v1/timing_test'))
    console.log(resources)
    for (let i = 0; i < resources.length; i++) {
      resources.splice(i, 1);
    }

    console.log(resources)
    console.log(this.results)
    // if (resources.length !== this.results.length) {
    //   alert('Error: mismatched number of results and timing resources')
    // }



    for (let i = 0; i < resources.length; i++) {
      const fileName = this.results[i].fileName
      const fileSize = this.results[i].fileSize
      const response = JSON.parse(this.results[i].result)
      const modelResponse = response.result
      const preparationAndResolutionTime = resources[i].connectEnd - resources[i].startTime //redirects, caching, DNS lookup, establishing TCP connection
      const imageUploadTime = this.results[i].imageUploadTime
      const clientMeasuredServerProcessingTime = resources[i].responseStart - resources[i].requestStart
      const serverSideValidationTime = (response.param_validation_time * 1000)
      const modelRunTime = (response.model_run_time * 1000)
      const serverMeasuredServerProcessingTime = modelRunTime + serverSideValidationTime
      const responseDownloadTime = resources[i].responseEnd - resources[i].responseStart
      const totalDuration = resources[i].duration
      const totalClientTime = preparationAndResolutionTime + clientMeasuredServerProcessingTime + responseDownloadTime
      const unaccountedClientTime = totalDuration - totalClientTime
      const unaccountedServerTime = clientMeasuredServerProcessingTime - serverMeasuredServerProcessingTime

      this.output.push([fileName, fileSize, modelResponse, preparationAndResolutionTime, imageUploadTime, serverSideValidationTime, modelRunTime, responseDownloadTime, unaccountedClientTime, unaccountedServerTime])

    }
    this.downloadCSV()
  }

  calculateLoadTimes = () => {
    this.files = document.getElementById('image').files;
    this.calculateLoadTimesForImage(this.files[0])
  }

  calculateLoadTimesForImage = async (image) => {
    let iteration = 0

    const fileName = image.name
    let downscaledImage = image
    if(this.DO_DOWNSCALE) {
      downscaledImage = await downscaleImage(image, this.DOWNSCALE_PERCENT, fileName)
    }
    const fileSize = downscaledImage.size
    const formData = new FormData()

    formData.append('image', downscaledImage, `${fileName}${Math.random()}`)
    formData.append('algorithm', 'rp6_expensive')

    this.sendRequest(iteration, formData, fileName, fileSize)
  }

  downloadCSV() {
    // Prepare and download CSV
    let csvContent = "data:text/csv;charset=utf-8,";
    this.output.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `speed-test-downsample-${this.DOWNSCALE_PERCENT}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    return (
      <div className="timing-test-page">
        <p>Ensure the chrome cache is disabled in the network tab of developer tools.</p>
        <input type="file" id="image" multiple />
        <button onClick={this.calculateLoadTimes}>Calculate load times</button>
      </div>
    );

  }
}

export default TimingTest;
