import React from "react";

import "./MockRead.scss";
import { CustomHeader, CustomTagReader } from "SharedComponents";
import { withRouter } from "react-router";

class Scan extends React.Component {
  componentDidMount() {
    document.title = "Arylla Demo";
  }

  render() {
    return (
      <div className="scan-page mock-read">
        <CustomHeader title={"Read from tag"} />
        <CustomTagReader
          algorithm={"aryllatag_paper31"}
          url={this.props.match.url}
        />
      </div>
    );
  }
}

export default withRouter(Scan);
