import React from "react";
import { Transition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import "./CustomTagReader.scss";
import I18n from "I18n";
import { loadImage } from "Utils";
import { HTTP, PRODUCTS as PRODUCTS_GENERIC } from "Constants";

class CustomTagReader extends React.Component {
  IMAGE_CROP_RATIO = 1.25;

  state = {
    isLoading: false,
    showScanResult: false,
    scanResult: false,
    showHelpModal: false,
  };

  massageScanResult(response) {
    const result = response.result;
    if (result > 0) {
      const product = this.lookupProduct(result);

      if (product.isCounterfeit) {
        return {
          title: I18n.scan.result.nonAuthentic.title,
          titleStyle: "danger",
          body: I18n.scan.result.nonAuthentic.description,
        };
      } else {
        return {
          title: I18n.scan.result.authentic.title,
          titleStyle: "success",
          body: I18n.scan.result.authentic.description,
          product: product,
        };
      }
    }

    if (result === 0) {
      return {
        title: I18n.scan.result.nonAuthentic.title,
        titleStyle: "danger",
        body: I18n.scan.result.nonAuthentic.description,
      };
    }

    return {
      title: I18n.errors.scanFailed,
      titleStyle: "warning",
      body: I18n.errors[result] || I18n.errors.imageProcessingGeneric,
    };
  }

  closeResultDrawer = () => {
    if (this.state.showScanResult) {
      this.setState({ showScanResult: false });
    }
  };

  lookupProduct(serialNumber) {
    if (serialNumber === 1437) {
      return {
        type: "readDemo",
        name: "Perry Everett",
        authenticationMethod: "Manual",
        brand: "Gucci",
        // style: "",
        // serialNumber: ""
      };
    }
    if (serialNumber === 1392) {
      return {
        type: "readDemo",
        name: "Perry Everett",
        authenticationMethod: "Entrupy",
        brand: "Louis Vuitton",
        // style: "",
        // serialNumber: "",
      };
    }

    const product = this.props.products
      ? this.props.products[serialNumber]
      : PRODUCTS_GENERIC[serialNumber];
    if (product) {
      return {
        title: product.title,
        subtitle: product.subtitle,
        imageSrc: product.image,
        footnote: `Serial #: ${product.serialNumber}`,
        productUrl: product.link,
        isCounterfeit: false || product.isCounterfeit,
        externalLink: product.externalLink,
      };
    } else {
      return {
        title: "Product not found",
        footnote: `Serial #: ${serialNumber}`,
      };
    }
  }

  onChange = async (e) => {
    this.setState({
      isLoading: true,
      scanResult: false,
      showScanResult: false,
    });
    const algorithm = this.props.algorithm;
    const formData = new FormData();
    const image = e.target.files[0];
    const { imageBlob, exif } = await loadImage(image, {
      aspectRatio: this.IMAGE_CROP_RATIO,
    });

    formData.append("image", imageBlob);
    formData.append("algorithm", algorithm.toString());
    if (this.props.url) {
      formData.append("source", this.props.url);
    }
    fetch(`${HTTP.BASE_URL}/v1/scan`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        const massagedResults = this.massageScanResult(response);
        if (massagedResults.product && massagedResults.product.externalLink) {
          return window.location.assign(massagedResults.product.externalLink);
        } else {
          this.setState({
            showScanResult: true,
            scanResult: massagedResults,
          });
        }
      })
      .catch(() => {
        this.setState({
          showScanResult: true,
          scanResult: this.massageScanResult({ result: -1000 }), // cannot reach API
        });
      })
      .finally(() => {
        setTimeout(() => this.setState({ isLoading: false }), 1000);
      });
  };

  render() {
    const { isLoading, scanResult, showScanResult, showHelpModal } = this.state;
    const scanButtonText = isLoading ? "" : I18n.scan.scanButton;
    const loadingClass = isLoading ? "loading" : "";
    const showFullDrawerClass = scanResult.product ? "full" : "";

    return (
      <React.Fragment>
        <div
          className="scan-container custom-tag-reader"
          onClick={this.closeResultDrawer}
        >
          <div className="instructions-container">
            <span className="title">
              <b>{I18n.scan.instructions.title}</b>
            </span>
            <span className="step">{I18n.scan.instructions.step1}</span>
            <span className="step">{I18n.scan.instructions.step2}</span>
            <span className="step">{I18n.scan.instructions.step3}</span>
          </div>
          <div className="scan-button-container">
            <label
              htmlFor="file-upload"
              className={`scan-button ${loadingClass}`}
            >
              {scanButtonText}
            </label>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              id="file-upload"
              onChange={this.onChange}
            />
          </div>
          <h5
            onClick={() => this.setState({ showHelpModal: true })}
            className="help-link"
          >
            Tutorial (video)
          </h5>
        </div>

        <div
          className={`help-container ${showHelpModal ? "show" : ""}`}
          onClick={() => this.setState({ showHelpModal: false })}
        >
          <div className="help-modal">
            <div className="instructions-gif">
              <div
                className="close-button"
                onClick={() => this.setState({ showHelpModal: false })}
              >
                x
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/photo-instructions.gif`}
              ></img>
            </div>
          </div>
        </div>

        <Transition in={showScanResult} timeout={1000}>
          {(transitionState) => (
            <div
              className={`result-container custom-tag-reader ${transitionState} ${showFullDrawerClass}`}
            >
              <div className="result-card-title">Result:</div>
              <div className={`results-card `}>
                <div className={`header ${scanResult.titleStyle}`}>
                  <span>{scanResult.title}</span>
                </div>
                <div className="body">{scanResult.body}</div>
              </div>
              <div className="result-body">
                {scanResult.product &&
                  scanResult.product.type === "readDemo" && (
                    <React.Fragment>
                      <label className="result-label">Name</label>
                      <div className="result-value">
                        {scanResult.product.name}
                      </div>
                      <label className="result-label">
                        Authentication Method
                      </label>
                      <div className="result-value">
                        {scanResult.product.authenticationMethod}
                      </div>
                      <label className="result-label">Brand</label>
                      <div className="result-value">
                        {scanResult.product.brand}
                      </div>
                      {/* <label className="result-label">Style</label>
                    <div className="result-value">{scanResult.product.style}</div>
                    <label className="result-label">Serial Number</label>
                    <div className="result-value">{scanResult.product.serialNumber}</div> */}
                    </React.Fragment>
                  )}
                {scanResult.product &&
                  scanResult.product.type !== "readDemo" && (
                    <React.Fragment>
                      <h2 className="title">{scanResult.product.title}</h2>
                      <h3 className="subtitle">
                        {scanResult.product.subtitle}
                      </h3>
                      {scanResult.product.imageSrc && (
                        <div
                          className="image-wrapper"
                          onClick={() =>
                            window.open(scanResult.product.productUrl, "_blank")
                          }
                        >
                          <FontAwesomeIcon
                            className="link-icon"
                            icon={faExternalLinkAlt}
                          />
                          <img
                            alt={"product"}
                            className="product-image"
                            src={scanResult.product.imageSrc}
                          />
                        </div>
                      )}
                      <span className="footnote">
                        {scanResult.product.footnote}
                      </span>
                    </React.Fragment>
                  )}
              </div>
            </div>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}

export default CustomTagReader;
