import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    pageTitle: "Arylla Demo",
    headerTitle: "Arylla Scan",
    disabledScan: {
      instructions: {
        disabledMessage:
          "This feature can only be used with a phone or tablet.",
      },
    },
    recommerce: {
      scanButton: "Scan",
      result: {
        title: "Result:",
        authentic: {
          title: "Authentic",
          description: "",
          serial: "Serial #:",
        },
        nonAuthentic: {
          title: "Cannot verify.",
          description: "This product could not be verified as authentic.",
        },
      },
      instructions: {
        title: "Instructions:",
        step1: "1) Tap below to open the camera 👇",
        step2: "2) Take a photo of the label to resell",
        step3: "",
      },
      tutorial: "Having trouble scanning?\nTap here for the tutorial video.",
      preliminaryContract: {
        tradeInValue: "Estimated Resale Price:",
      },
      permission: {
        contact:
          "I would like to receive special offersdfdfdf and discounts right to my inbox",
        termsOfService: "I agree to the terms of service and privacy policy",
      },
      complete:
        "\nThanks for building a more sustainable world!\n\nWe will contact you with a shipping label when the product sells.",
      titles: {
        complete: "Congratulations!",
        logIn: "Log In",
        accountInfo: "Account Info",
      },
      buttons: {
        globalReturn: "Back",
        globalAdvance: "Next",
        productPreview: {
          advance: "Resell This Product",
          return: "Scan Another Product",
        },
        condition: {
          advance: "Next - Summary",
        },
        preliminaryContract: {
          pricing: {
            advance: "Next - Terms",
          },
          signIn: {
            google: "Sign in With Google",
          },
          review: {
            isSignedInAdvance: "Next - Account Info",
            isConfirmedAdvance: "Approve",
            isSignedOutAdvance: "Log In To Post",
          },
          accountInformation: {
            edit: "edit",
            name: "name",
            email: "email",
            address: "address",
            advance: "Confirm",
          },
        },
        complete: {
          signInAdvance: "Trade Another Item",
          signOutAdvance: "Done - Sign Out",
          ebayUrl: "Go To Ebay Listing",
        },
      },
    },
    scan: {
      scanButton: "Camera",
      result: {
        title: "Result:",
        authentic: {
          title: "Authentic",
          description: "",
          serial: "Serial #:",
        },
        nonAuthentic: {
          title: "Cannot verify.",
          description: "This product could not be verified as authentic.",
        },
        readWrite: {
          noData: "No Data Written to Tag",
          write: "Write to Tag",
          close: "Scan again",
          writeSave: "Save",
          input: "Write data here...",
        },
      },
      instructions: {
        title: "Instructions:",
        step1: "1) Tap below to open the camera 👇",
        step2: "2) Tap ⚡ to turn on the flash",
        step3: "3) Take a photo of the connected label 🔗",
        videoStep1: "1) Tab below to start the video feed 👇",
        videoStep2: "2) Give permission to use the camera 🔓",
        videoStep3: "3) Point the camera at the connected label 🔗",
      },
      tutorial: "Having trouble scanning?\nTap here for the tutorial video.",
    },
    alt: {
      logo: "logo",
    },
    errors: {
      error: "Error",
      imageProcessingGeneric: "Image processing error. Please try again.",
      scanFailed: "Scan Failed.",
      "-7": "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-30":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-31":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-32":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-33":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-34":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-35":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-36":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-51":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-52":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-53":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-56":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-57":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-59":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-82":
        "This image can not be processed. Please ensure that the tag is in focus and not overexposed.",
      "-83":
        "This image can not be processed. Please ensure that the tag is in focus and snot overexposed.",
      "-90": "Unable to identify product. Please try taking another photo.",
      "-93":
        "This tag is obsolete.  Please get in touch with Arylla Inc. for an updated sample.",
      "-60":
        "Underexposure detected. Please ensure the tag is not in direct sunlight to avoid underexposure.",
      "-61":
        "Overexposure detected. Please ensure the background is not black to avoid overexposure.",
      "-25":
        "Overexposure detected.  If you are taking the photo against a black background, try taking a photo with the flash turned off.",
      "-800":
        "No data found, please try again.  If you are still unable to verify, please contact your supplier.",
      "-1000": "Cannot reach API. Please try again later.",
      "-1101": "Device camera not yet supported.",
      "-1102": "The requested project is not live.  Please contact developer.",
      "-1103": "Invalid image type.  Please contact developer.",
      "-2000": "Unknown error.  Please contact developer.",
      "-3000":
        "Laava feature finding error.  Please try again with the tag in focus and not overexposed.",
    },
    general: {
      errorCode: "Error code",
    },
    misc: {
      Scanner: "Scanner",
      totalSecurityStep1:
        "1) Tap below to open the camera\n2) Tap ⚡ to turn on the flash",
      totalSecurityStep2:
        "3) Ensure the entire label is in the frame\n4) Ensure the image is in focus",
      totalSecurityStep3: "5) Take a photo\n6) Tap Use Photo",
    },
  },
  zh: {
    pageTitle: "Arylla 演示",
    headerTitle: "Arylla 扫描",
    disabledScan: {
      instructions: {
        disabledMessage: "此功能只能与手机或平板电脑一起使用。",
      },
    },
    scan: {
      scanButton: "相机",
      result: {
        title: "结果",
        authentic: {
          title: "真正的",
          description: "",
          serial: "串行:",
        },
        nonAuthentic: {
          title: "无法验证。",
          description: "该产品无法验证为正品。",
        },
      },
      instructions: {
        title: "使用说明",
        step1: "1) 点击下方打开相机 👇",
        step2: "2) 点按 ⚡ 打开闪光灯",
        step3: "3) 拍摄连接标签的照片 🔗",
        videoStep1: "1) 下面的选项卡启动视频源 👇",
        videoStep2: "2) 授予使用相机的权限 🔓",
        videoStep3: "3) 将相机对准连接的标签 🔗",
      },
      tutorial: "扫描有问题？\n单击此处查看教程视频",
    },
    errors: {
      imageProcessingGeneric:
        "无法处理此图像。 确保标签在焦点上并且没有过度曝光。",
      scanFailed: "扫描失败。",
      "-7": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-30": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-31": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-32": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-33": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-34": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-35": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-36": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-51": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-52": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-53": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-56": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-57": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-59": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-82": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-83": "无法处理此图像。 确保标签在焦点上并且没有曝光过度。",
      "-90": "无法识别产品。 请尝试拍摄另一张照片。",
      "-93": "此标记已过时。 请联系 Arylla Inc. 获取更新样品。",
      "-60": "检测到曝光不足。 确保标签没有暴露在直射阳光下，以免曝光不足",
      "-61": "检测到曝光过度。 确保背景不是黑色以避免过度曝光。",
      "-25":
        "检测到曝光过度。 如果您在黑色背景下拍照，请尝试在关闭闪光灯的情况下拍照。",
      "-800": "没有找到数据，请重试。 如果您仍然无法验证，请联系您的提供商。",
      "-1000": "无法访问 API。 请稍后再试。",
      "-1101": "尚不支持设备相机。",
      "-1102": "请求的项目不是实时的。 请联系开发商。",
      "-1103": "图片类型无效。 请联系开发商。",
      "-2000": "未知的错误。 请联系开发商。",
      "-3000":
        "Laava 计算机视觉错误。 请在对焦标签且不要曝光过度的情况下重试。",
    },
    misc: {
      Scanner: "掃描器",
      totalSecurityStep1: "1) 按下相机图示\n2) 按 ⚡ 图示开啟闪光灯",
      totalSecurityStep2: "3) 确保整个防伪标签於相机框內\n4) 确保对焦清晰",
      totalSecurityStep3: "5) 拍攝\n6) 使用相片",
    },
  },
});
