import React, { useState } from "react";
import { Transition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Helmet } from "react-helmet";

import ReactLoading from "react-loading";

import "./Scanner.scss";
import I18n from "I18n";
import { downscaleImage, loadImage } from "Utils";
import {
  HTTP,
  LOWERCASE_REDIRECTS,
  DOWNSAMPLE_VALUES,
  PRODUCTS as PRODUCTS_GENERIC,
} from "Constants";

import * as FirestoreService from "../../firebase";
import * as Laava from "../../laava";

class HttpError extends Error {
  constructor(status, response) {
    super(status);
    this.name = "HttpError";
    this.status = status;
    this.response = response;
  }
}

class Scanner extends React.Component {
  state = {
    isLoading: false,
    showScanResult: false,
    scanResult: false,
    showHelpModal: false,
    showWriteForm: false,
    formValue: "",
    progress: 0,
  };

  massageScanResult(response, product = null, errorInput = null) {
    const logData = {
      ...this.props,
      ...response,
      ...product,
      lang: I18n.getInterfaceLanguage(),
    };
    FirestoreService.logResult(logData, errorInput);
    const result = response.result;
    const project = response.project;
    if (result > 0) {
      if (product.isCounterfeit) {
        return {
          title: I18n.scan.result.nonAuthentic.title,
          titleStyle: "danger",
          body: I18n.scan.result.nonAuthentic.description,
        };
      } else {
        const bodyTextArray = [I18n.scan.result.authentic.description];
        if (!product.isFound || product.showSerial) {
          bodyTextArray.pop(); //Remove the authentic description so the serial number is more obvious
          bodyTextArray.push(
            this.props.customText.serial || I18n.scan.result.authentic.serial
          );
          bodyTextArray.push(result.toString());
        }

        if (product.readWrite) {
          return {
            title: this.props.hideAuthentic
              ? ""
              : this.props.customText.authentic ||
                I18n.scan.result.authentic.title,
            body: bodyTextArray.join(" "),
            titleStyle: this.props.hideAuthentic ? "" : "success",
            project: project,
            serial: result,
            readWrite: true,
          };
        }

        return {
          title: this.props.hideAuthentic
            ? product.title
              ? product.title
              : ""
            : this.props.customText.authentic ||
              I18n.scan.result.authentic.title,
          titleStyle: this.props.hideAuthentic ? "" : "success",
          body: bodyTextArray.join(" "),
          product: product,
          fullImage: false || product.fullImage,
        };
      }
    }

    if (result === 0) {
      return {
        title:
          this.props.customText.counterfeitScanTitle ||
          I18n.scan.result.nonAuthentic.title,
        titleStyle: "danger",
        body:
          this.props.customText.counterfeitScanMessage ||
          I18n.scan.result.nonAuthentic.description,
      };
    }

    return {
      title: this.props.customText.errorScanTitle || I18n.errors.scanFailed,
      titleStyle: "warning",
      body:
        this.props.customText.errorScanMessage ||
        I18n.errors[result] ||
        I18n.errors.imageProcessingGeneric,
    };
  }

  closeResultDrawer = () => {
    if (this.state.showScanResult) {
      this.setState({ showScanResult: false });
    }
    if (this.state.showWriteForm) {
      this.setState({ showWriteForm: false });
    }
    //Reset state variables
    this.setState({
      formValue: "",
    });
    //Sign out user
    if (FirestoreService.user()) {
      FirestoreService.signOut();
    }
  };

  closeWriteForm = (event) => {
    if (this.state.showWriteForm) {
      this.setState({
        showWriteForm: false,
      });
      if (
        this.state.scanResult.firestoreProduct.text ===
        I18n.scan.result.readWrite.noData
      ) {
        this.setState({
          formValue: "",
        });
      } else {
        this.setState({
          formValue: this.state.scanResult.firestoreProduct.text,
        });
      }
    }
  };

  writeFormClick = (event) => {
    event.stopPropagation();
  };

  async lookupProduct(project, serialNumber) {
    if (serialNumber <= 0) return null;
    if (this.props.cloudProducts) {
      let product = await FirestoreService.getDemoProduct(
        project,
        serialNumber
      );
      if (!product) {
        const aliasDoc = await FirestoreService.getDocument("alias", project);
        if (aliasDoc) {
          product = await FirestoreService.getDemoProduct(
            aliasDoc.alias,
            serialNumber
          );
        }
      }
      if (!product) {
        product = await FirestoreService.getDemoProduct(project, "default");
      }
      if (!product) {
        const aliasDoc = await FirestoreService.getDocument("alias", project);
        if (aliasDoc) {
          product = await FirestoreService.getDemoProduct(
            aliasDoc.alias,
            "default"
          );
        }
      }
      if (product) {
        return {
          title: product.product.title,
          subtitle: product.product.description,
          imageSrc:
            `${process.env.PUBLIC_URL}` +
            product.product.image.replace("https://demo.arylla.com", ""),
          footnote: product.product.footnote
            ? product.product.footnote
            : `Serial #: ${serialNumber}`,
          productUrl: product.product.link,
          externalLink: product.product.externalLink,
          isCounterfeit: false || product.product.isCounterfeit,
          isFound: true,
          showSerial: false || product.product.showSerial,
          fullImage: false || product.product.fullImage,
        };
      } else {
        let productResponse = {
          isFound: false,
          isCounterfeit: false,
        };
        if (this.props.readWrite) productResponse["readWrite"] = true;
        return productResponse;
      }
    } else {
      const product = this.props.products
        ? this.props.products.hasOwnProperty(serialNumber)
          ? this.props.products[serialNumber]
          : this.props.products["default"]
        : //If the serial number doesn't exist, use 'defualt'.
          //If 'default' doesn't exist either, then it'll return an empty object,
          //which is checked next
          PRODUCTS_GENERIC[serialNumber];
      if (product) {
        return {
          title: product.title,
          titleImage: product.titleImage,
          subtitle: product.subtitle,
          imageSrc: product.image,
          footnote: product.footnote
            ? product.footnote
            : `Serial #: ${product.serialNumber}`,
          productUrl: product.link,
          isCounterfeit: false || product.isCounterfeit,
          externalLink: product.externalLink,
          isFound: true,
          showSerial: false || product.showSerial,
          fullImage: false || product.fullImage,
        };
      } else {
        let productResponse = { isFound: false };
        if (this.props.readWrite) productResponse["readWrite"] = true;
        return productResponse;
      }
    }
  }

  redirectAlgorithmCase(algorithm) {
    algorithm = algorithm.toLowerCase();
    return LOWERCASE_REDIRECTS[algorithm] || algorithm;
  }

  addData = () => {
    const data = {
      text: this.state.formValue,
      project: this.state.scanResult.project,
      serial: this.state.scanResult.serial,
    };
    FirestoreService.writeProduct(data);

    //update the state
    let oldFirestoreProduct = { ...this.state.scanResult.firestoreProduct };
    oldFirestoreProduct.text = this.state.formValue;
    let oldScanResult = { ...this.state.scanResult };
    oldScanResult.firestoreProduct = oldFirestoreProduct;
    this.setState({
      scanResult: oldScanResult,
    });
  };

  handleDataSubmit = (event) => {
    this.setState({
      showWriteForm: false,
    });
    event.preventDefault();

    //Validate that new data has been entered
    if (this.state.scanResult.firestoreProduct.text !== this.state.formValue)
      this.addData();
  };

  handleDataChange = (e) => {
    this.setState({ formValue: e.target.value });
  };

  onChange = async (e) => {
    try {
      this.setState({
        isLoading: true,
        scanResult: false,
        showScanResult: false,
        showWriteForm: false,
      });
      const startDateTime = Date.now();
      const algorithm = this.redirectAlgorithmCase(this.props.algorithm);
      const formData = new FormData();
      const image = e.target.files[0];
      let { imageBlob, exif } = await loadImage(image, {});
      if (algorithm in DOWNSAMPLE_VALUES) {
        const imageName = image.name;
        const imageFile = new File([imageBlob], imageName);
        imageBlob = await downscaleImage(
          imageFile,
          DOWNSAMPLE_VALUES[algorithm],
          imageName
        );
        if (!this.props.laava) {
          formData.append("downsamplePercent", DOWNSAMPLE_VALUES[algorithm]);
        }
      }

      if (this.props.laava) {
        //Send to Laava API
        imageBlob = await Laava.laavaFetch(imageBlob);
        this.setState({ progress: 60 });
      }

      formData.append("image", imageBlob);
      formData.append("algorithm", algorithm.toString());
      if (exif) {
        formData.append("exif", JSON.stringify(exif.getAll()));
      }
      if (this.props.url) {
        formData.append("source", this.props.url);
      }
      formData.append(
        "dateTimeLog",
        JSON.stringify({ start: startDateTime, send: Date.now() })
      );

      const response = await fetch(`${HTTP.BASE_URL}/v1/scan`, {
        method: "POST",
        body: formData,
      });
      this.setState({ progress: 100 });

      //Check for OK return
      if (!response.ok) {
        throw new HttpError(response.status, response);
      }

      const responseJson = await response.json();

      const product = await this.lookupProduct(
        responseJson.project,
        responseJson.result
      );

      const massagedResults = this.massageScanResult(responseJson, product);
      if (massagedResults.product && massagedResults.product.externalLink) {
        return window.location.assign(massagedResults.product.externalLink);
      } else if (massagedResults.readWrite) {
        const firestoreResponse = await FirestoreService.getProduct(
          massagedResults.project,
          massagedResults.serial
        );

        massagedResults["firestoreProduct"] = firestoreResponse;
        this.setState({
          showScanResult: true,
          scanResult: massagedResults,
        });
        if (firestoreResponse.text !== I18n.scan.result.readWrite.noData) {
          this.setState({ formValue: response.text });
        }
      } else {
        this.setState({
          showScanResult: true,
          scanResult: massagedResults,
        });
      }
    } catch (err) {
      if (err instanceof HttpError) {
        //Show the user 400 level messages
        if (err.status === 406) {
          //User facing error
          err.response.json().then((response) => {
            this.setState({
              showScanResult: true,
              scanResult: this.massageScanResult(
                {
                  result: response.errorCode,
                },
                null,
                err
              ),
            });
            return null;
          });
        } else {
          //Developer facing error
          err.response.json().then((response) => {
            this.setState({
              showScanResult: true,
              scanResult: this.massageScanResult(
                {
                  result: response.errorCode,
                },
                null,
                err
              ),
            });
            return null;
          });
        }
      } else if (err instanceof TypeError) {
        this.setState({
          showScanResult: true,
          scanResult: this.massageScanResult(
            {
              result: -1000,
            },
            null,
            err
          ), // cannot reach API
        });
      } else if (err instanceof Laava.LaavaError) {
        this.setState({
          showScanResult: true,
          scanResult: this.massageScanResult(
            {
              result: -3000,
            },
            null,
            err
          ), // Laava FF Error
        });
      } else {
        this.setState({
          showScanResult: true,
          scanResult: this.massageScanResult(
            {
              result: -2000,
            },
            null,
            err
          ), // unknown error
        });
      }
    } finally {
      setTimeout(() => this.setState({ isLoading: false, progress: 0 }), 1000);
    }
  };

  render() {
    const {
      isLoading,
      scanResult,
      showScanResult,
      showHelpModal,
      showWriteForm,
      formValue,
      progress,
    } = this.state;
    const scanButtonText = isLoading
      ? ""
      : this.props.customText.scanButton
      ? this.props.customText.scanButton
      : I18n.scan.scanButton;
    const loadingClass = isLoading ? "loading" : "";
    const showFullDrawerClass =
      scanResult.product || scanResult.firestoreProduct ? "full" : "";
    const showFullImage =
      scanResult.product && scanResult.fullImage ? "fullImage" : "";

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="apple-itunes-app"
            content="app-id=1550844529, app-clip-bundle-id=com.arylla.demo.Clip, app-clip-display=card"
          />
        </Helmet>
        <div className="scan-container" onClick={this.closeResultDrawer}>
          {this.props.linkButton && (
            <button
              className="link-button"
              onClick={() => window.open(this.props.linkButton.link, "_self")}
            >
              {this.props.linkButton.text}
            </button>
          )}
          <div className="instructions-container">
            <span className="title">
              <b>
                {this.props.customText.instructions
                  ? this.props.customText.instructions
                  : I18n.scan.instructions.title}
              </b>
            </span>
            <span className="step">
              {this.props.customText.step1
                ? this.props.customText.step1
                : I18n.scan.instructions.step1}
            </span>
            <span className="step">
              {this.props.customText.step2
                ? this.props.customText.step2
                : I18n.scan.instructions.step2}
            </span>
            <span className="step">
              {this.props.customText.step3
                ? this.props.customText.step3
                : I18n.scan.instructions.step3}
            </span>
          </div>
          {isLoading && this.props.laava ? (
            <div className="progress-bar-container">
              <CircularProgressbarWithChildren
                value={`${progress}`}
                background
                backgroundPadding="6"
                styles={buildStyles({
                  pathColor: this.props.loadPathColor || "white",
                  backgroundColor: this.props.loadColor || "#3484f7",
                  trailColor: this.props.loadColor || "#3484f7",
                })}
              >
                <ReactLoading
                  type="bubbles"
                  color={this.props.loadPathColor || "white"}
                />
              </CircularProgressbarWithChildren>
            </div>
          ) : (
            <div className="scan-button-container">
              <label
                htmlFor="file-upload"
                className={`scan-button ${loadingClass}`}
              >
                {scanButtonText}
              </label>
              <input
                type="file"
                accept="image/*"
                capture="environment"
                id="file-upload"
                onChange={this.onChange}
              />
            </div>
          )}
          {!this.props.hideTutorial && (
            <button
              onClick={() => this.setState({ showHelpModal: true })}
              className="help-link"
            >
              {this.props.customText.tutorial
                ? this.props.customText.tutorial
                : I18n.scan.tutorial}
            </button>
          )}
        </div>

        <div
          className={`help-container ${showHelpModal ? "show" : ""}`}
          onClick={() => this.setState({ showHelpModal: false })}
        >
          <div className="help-modal">
            <div className="instructions-gif">
              <div
                className="close-button"
                onClick={() => this.setState({ showHelpModal: false })}
              >
                x
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/${
                  this.props.instructionsVideo || "photo-instructions.gif"
                }`}
                alt="instructional video"
              ></img>
            </div>
          </div>
        </div>

        <Transition in={showScanResult} timeout={1000}>
          {(transitionState) => (
            <div
              className={`result-container ${showFullImage} ${transitionState} ${showFullDrawerClass}`}
              onClick={this.closeWriteForm}
            >
              {scanResult.fullImage ? (
                <>
                  {scanResult.product && (
                    <div>
                      {scanResult.product.imageSrc && (
                        <img
                          alt={"product"}
                          className="full-image"
                          src={scanResult.product.imageSrc}
                        />
                      )}
                      <div className="full-image-filler"></div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="result-card-title">
                    {this.props.customText.result || I18n.scan.result.title}
                  </div>
                  <div className={`results-card `}>
                    <div className={`header ${scanResult.titleStyle}`}>
                      <span>{scanResult.title}</span>
                    </div>
                    <div className="body">{scanResult.body}</div>
                  </div>
                  <div className="result-body">
                    {scanResult.product && (
                      <React.Fragment>
                        <h2 className="title">
                          {scanResult.product.titleImage && (
                            <img
                              alt={"product"}
                              className="product-image"
                              src={scanResult.product.titleImage}
                            />
                          )}
                          {this.props.hideAuthentic
                            ? ""
                            : scanResult.product.title}
                        </h2>
                        <h3 className="subtitle">
                          {scanResult.product.subtitle}
                        </h3>
                        {scanResult.product.imageSrc && (
                          <div
                            className="image-wrapper"
                            onClick={() => {
                              if (scanResult.product.productUrl) {
                                window.open(
                                  scanResult.product.productUrl,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            {scanResult.product.productUrl && (
                              <FontAwesomeIcon
                                className="link-icon"
                                icon={faExternalLinkAlt}
                              />
                            )}
                            <img
                              alt={"product"}
                              className="product-image"
                              src={scanResult.product.imageSrc}
                            />
                          </div>
                        )}
                        <span className="footnote">
                          {scanResult.product.footnote}
                        </span>
                        {this.props.dummyButton &&
                          scanResult.product.isFound && (
                            <button className="dummy-button" disabled>
                              {this.props.dummyButton}
                            </button>
                          )}
                      </React.Fragment>
                    )}
                    {scanResult.firestoreProduct && (
                      <React.Fragment>
                        <h2 className="read">
                          {formValue
                            ? formValue
                            : scanResult.firestoreProduct.text}
                        </h2>
                        <button
                          className="write-button"
                          onClick={() => this.setState({ showWriteForm: true })}
                        >
                          {I18n.scan.result.readWrite.write}
                        </button>
                        <button
                          className="close-button"
                          onClick={this.closeResultDrawer}
                        >
                          {I18n.scan.result.readWrite.close}
                        </button>
                        <Transition in={showWriteForm} timeout={1000}>
                          {(writeTransitionState) => (
                            <div
                              className={`write-form-container ${writeTransitionState}`}
                              onClick={this.writeFormClick}
                            >
                              <form onSubmit={this.handleDataSubmit}>
                                <textarea
                                  value={formValue}
                                  onChange={this.handleDataChange}
                                  placeholder={I18n.scan.result.readWrite.input}
                                  className="write-input"
                                >
                                  {formValue}
                                </textarea>
                                <button
                                  type="submit"
                                  className="write-button-save"
                                >
                                  {I18n.scan.result.readWrite.writeSave}
                                </button>
                              </form>
                            </div>
                          )}
                        </Transition>
                      </React.Fragment>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}

export default Scanner;
