import React from "react";
import I18n from "I18n";
import "./InstantHeader.scss";

class InstantHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <header className="instant-header">
          <div className="instant-header-container">
            <div className="app-icon-container">
              <img
                alt={I18n.alt.logo}
                src={process.env.PUBLIC_URL + "/instant-app-icon.png"}
              />
              <div className="app-title-container">
                <h4 className="app-title">Showroom</h4>
                <h6 className="app-subtitle">
                  Open in the Showroom instant app
                </h6>
              </div>
            </div>

            <div className="app-button-container">
              <button
                className="app-button"
                onClick={() =>
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.arylla.demo&launch=true`,
                    "_self"
                  )
                }
              >
                OPEN
              </button>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default InstantHeader;
