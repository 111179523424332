import React from "react";

import "./GooseScan.scss";
import { Scanner } from "SharedComponents";
import { ALGORITHMS } from "Constants";
import { PRODUCTS_GOOSE } from "Constants";
import { withRouter } from "react-router";

class EmbeddedScan extends React.Component {
  constructor(props) {
    super(props);
    const algorithmKey = this.props.match.params.algorithm;
    this.state = {
      algorithm: ALGORITHMS.find((algo) => algo.key === algorithmKey)
        .projectName,
    };
  }

  componentDidMount() {
    document.title = "Arylla Scanner";
  }

  render() {
    return (
      <div className="embedded-scan-page">
        <Scanner
          algorithm={this.state.algorithm}
          products={PRODUCTS_GOOSE}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(EmbeddedScan);
