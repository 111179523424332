const laavaKey = "106df0b9.d22e8dba229115d28e2ba04a";
const laavaUrl = "https://api.laava.id/process";
const laavaScale = 0.66;

export class LaavaError extends Error {
  constructor(status, response) {
    super(status);
    this.name = "LaavaError";
    this.status = status;
    this.response = response;
  }
}

export const laavaFetch = async (imageBlob) => {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", laavaKey);

  var formdata = new FormData();
  formdata.append("image", imageBlob);
  formdata.append("options", '{"fullColourOutputScale":' + laavaScale + "}");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(laavaUrl, requestOptions);

  if (!response.ok) {
    throw new LaavaError(response.status, response);
  }

  const returnImageBlob = await response.blob();

  return returnImageBlob;
};
