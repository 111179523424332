const development = {
  BASE_URL: "http://localhost:3001",
  RECOMMERCE_URL: "http://localhost:5000",
  SELF_URL: "https://localhost:3000",
  SELF_URL_ANONYMOUS: "https://localhost:3000",
};
const staging = {
  BASE_URL: "https://api-staging.arylla.com",
  RECOMMERCE_URL: "https://recommerce-api-staging-6g6h43daia-ue.a.run.app",
  SELF_URL: "https://staging.arylla.com",
  SELF_URL_ANONYMOUS: "https://webapp-staging-6g6h43daia-ue.a.run.app",
};
const production = {
  BASE_URL: "https://api.arylla.com",
  RECOMMERCE_URL: "https://recommerce-api-6g6h43daia-ue.a.run.app",
  SELF_URL: "https://demo.arylla.com",
  SELF_URL_ANONYMOUS: "https://webapp-production-6g6h43daia-ue.a.run.app",
};

function httpVariables() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return development;
    case "staging":
      return staging;
    default:
      return production;
  }
}

export default {
  ...httpVariables(),
};
