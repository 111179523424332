export default {
  //key is serial number
  2584: {
    serialNumber: "PO 51884",
  },
  6843: {
    serialNumber: "PO 51882",
  },
  7324: {
    serialNumber: "PO 51880",
  },
  11412: {
    serialNumber: "PO 51879",
  },
  15510: {
    serialNumber: "PO 51883",
  },
  24459: {
    serialNumber: "PO 51881",
  },
};

export const artfan_products = {
  1000: {
    serialNumber: 1000,
    title: "Negative Fifteen Plate",
    subtitle: "Yuriy Luzov",
    link: "https://artfan.global/products/negative-fifteen-plate",
    image: `${process.env.PUBLIC_URL}/artfan 1000 Negative Fifteen P.jpg`,
  },
  1001: {
    serialNumber: 1001,
    title: "Green Copper Raku Vase #2",
    subtitle: "Rebecca Browning-Yager",
    link: "https://artfan.global/products/green-copper-raku-vase-2",
    image: `${process.env.PUBLIC_URL}/artfan 1001 Green Copper Raku .jpg`,
  },
  1002: {
    serialNumber: 1002,
    title: "Black Copper Raku Vase #1",
    subtitle: "Rebecca Browning-Yager",
    link: "https://artfan.global/products/black-copper-raku-vase-1",
    image: `${process.env.PUBLIC_URL}/artfan 1002 Black Copper Raku .jpg`,
  },
  1003: {
    serialNumber: 1003,
    title: "Turquoise Red Copper Raku Vase #4",
    subtitle: "Rebecca Browning-Yager",
    link: "https://artfan.global/products/turquoise-red-copper-raku-vase-4",
    image: `${process.env.PUBLIC_URL}/artfan 1003 Turquoise Red Copp.jpg`,
  },
  1004: {
    serialNumber: 1004,
    title: "Star Petals",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/products/star-petals",
    image: `${process.env.PUBLIC_URL}/artfan 1004 Star Petals.jpg`,
  },
  1005: {
    serialNumber: 1005,
    title: "Universal Mind",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/universal-mind",
    image: `${process.env.PUBLIC_URL}/artfan 1005 Universal Mind.jpg`,
  },
  1006: {
    serialNumber: 1006,
    title: "The Sun Shines For All Without Reservation",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/the-sun-shines-for-all-without-reservation",
    image: `${process.env.PUBLIC_URL}/artfan 1006 The Sun Shines For.png`,
  },
  1007: {
    serialNumber: 1007,
    title: "Not Two",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/not-two",
    image: `${process.env.PUBLIC_URL}/artfan 1007 Not Two.jpg`,
  },
  1008: {
    serialNumber: 1008,
    title: "Maya",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/maya",
    image: `${process.env.PUBLIC_URL}/artfan 1008 Maya.jpg`,
  },
  1009: {
    serialNumber: 1009,
    title: "Dawn of Transcendental Jubilance",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/dawn-of-transcendental-jubilance",
    image: `${process.env.PUBLIC_URL}/artfan 1009 Dawn of Transcende.jpg`,
  },
  1010: {
    serialNumber: 1010,
    title: "Call to Evolve",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/collections/derek-carpenter-collection/products/call-to-evolve",
    image: `${process.env.PUBLIC_URL}/artfan 1010 Call to Evolve.jpg`,
  },
  1011: {
    serialNumber: 1011,
    title: "Frosted Fuck-Spokes",
    subtitle: "Derek Carpenter (Art of One)",
    link: "https://artfan.global/products/frosted-fuck-spokes",
    image: `${process.env.PUBLIC_URL}/artfan 1011 Frosted Fuck-Spoke.jpg`,
  },
  1012: {
    serialNumber: 1012,
    title: "Memory of Janice",
    subtitle: "Joan Liebowitz",
    link: "https://artfan.global/products/memory-of-janice",
    image: `${process.env.PUBLIC_URL}/artfan 1012 Memory of Janice.jpg`,
  },
  1013: {
    serialNumber: 1013,
    title: "The Black Pot",
    subtitle: "Joan Liebowitz",
    link: "https://artfan.global/products/the-black-pot",
    image: `${process.env.PUBLIC_URL}/artfan 1013 The Black Pot.jpg`,
  },
  1014: {
    serialNumber: 1014,
    title: "Angry Azalea",
    subtitle: "Joan Liebowitz",
    link: "https://artfan.global/products/angry-azalea",
    image: `${process.env.PUBLIC_URL}/artfan 1014 Angry Azalea.jpg`,
  },
  1022: {
    serialNumber: 1022,
    title: "Mad Honey",
    subtitle: "Michael Heltebrake",
    link: "https://artfan.global/products/mad-honey",
    image: `${process.env.PUBLIC_URL}/artfan 1022 Mad Honey.jpg`,
  },
  1023: {
    serialNumber: 1023,
    title: "Symmetriarum Difformitatem: Quinque",
    subtitle: "Katlynn Kaplan",
    link: "https://artfan.global/products/symmetriarum-difformitatem-quinque",
    image: `${process.env.PUBLIC_URL}/artfan 1023 Symmetriarum Diffo.jpg`,
  },
  1024: {
    serialNumber: 1024,
    title: "Love Basket",
    subtitle: "Bob Davis",
    link: "https://artfan.global/products/love-basket",
    image: `${process.env.PUBLIC_URL}/artfan 1024 Love Basket.jpg`,
  },
  1213: {
    serialNumber: 1213,
    title: "Symmetriarum Difformitatem: Quinque",
    subtitle: "Katlynn Kaplan",
    link: "https://artfan.global/products/symmetriarum-difformitatem-quinque",
    image: `${process.env.PUBLIC_URL}/artfan 1023 Symmetriarum Diffo.jpg`,
  },
  4000: {
    serialNumber: 4000,
    title: "Oxygen Train",
    subtitle: "ArtFan ID 388063",
    image: `${process.env.PUBLIC_URL}/oxygen-train.jpg`,
  },
  4001: {
    serialNumber: 4001,
    title: "Oxygen Train",
    subtitle: "ArtFan ID 336863",
    image: `${process.env.PUBLIC_URL}/oxygen-train.jpg`,
  },
  4004: {
    serialNumber: 4004,
    title: "Oxygen Train",
    subtitle: "ArtFan ID 772001",
    image: `${process.env.PUBLIC_URL}/oxygen-train.jpg`,
  },
};

export const avery_products = {
  default: {
    titleImage: `${process.env.PUBLIC_URL}/ojo-de-togre-logo.svg`,
    subtitle: "Genuine Mexican Mezcal\nTap the image below to learn more!",
    image: `${process.env.PUBLIC_URL}/ojo-de-togre-product.png`,
    link: "https://ojodetigremezcal.com/es/elaboracion-mezcal-joven-mezcal-blanco/ ",
    showSerial: true,
  },
};

export const goat_products = {
  1: {
    serialNumber: 1,
    title: "Air Jordan 1 Retro High OG Patina",
    subtitle: "GOAT ID: serial #974341",
    image: `${process.env.PUBLIC_URL}/goat-shoe.png`,
  },
};

export const redcross_products = {
  default: {
    title: "N95 Respirator",
    subtitle:
      "Made in Vietnam\nProduct ID: 01\nManufacturing Date: January 1, 2022\nFactory Code: EFGH5678\nBatch Number: 01",
    image: `${process.env.PUBLIC_URL}/n95-drawing.jpg`,
    link: "https://blogs.cdc.gov/niosh-science-blog/2020/03/16/n95-preparedness/",
    showSerial: true,
    footnote: "Tap the icon above for N95 fitting instructions",
  },
};

export const redcross_products_en = {
  default: {
    title: "Disposable Particulate Respirator",
    subtitle:
      "Thank you for your support. Please click the logo below to authenticate your purchase.",
    image: `${process.env.PUBLIC_URL}/redcross-logo.png`,
    link: "https://products.redcross.ca/pages/respirator-validation",
    showSerial: true,
  },
};

export const redcross_products_fr = {
  default: {
    title: "Respirateur jetable contre les particules",
    subtitle:
      "Merci pour votre soutien. Veuillez cliquer sur le logo ci-dessous pour authentifier votre achat.",
    image: `${process.env.PUBLIC_URL}/redcross-logo.png`,
    link: "https://products.redcross.ca/fr/pages/respirator-validation",
    showSerial: true,
  },
};

export const rpacProducts = {
  2584: {
    serialNumber: 2584,
    externalLink: "https://www.thenorthfacerenewed.com",
    // isCounterfeit: true
  },
  6843: {
    serialNumber: 6843,
    externalLink: "https://www.thenorthfacerenewed.com",
    // externalLink: 'https://www.thenorthfacerenewed.com/collections/men-tops-jackets/products/mens-mcmurdo-parka-iii?variant=31599805530161'
  },
  7324: {
    serialNumber: 7324,
    externalLink: "https://www.thenorthfacerenewed.com",
    // externalLink: 'https://www.thenorthfacerenewed.com/collections/men-vests/products/mens-aconcagua-vest?variant=14383624880177'
  },
  11412: {
    serialNumber: 11412,
    externalLink: "https://www.thenorthfacerenewed.com",
    // externalLink: 'https://www.thenorthfacerenewed.com/collections/men-tops-sweatshirts-hoodies/products/mens-glacier-alpine-1-4-zip?variant=15509649457201'
  },
  15510: {
    serialNumber: 15510,
    externalLink: "https://www.thenorthfacerenewed.com",
    // externalLink: 'https://www.thenorthfacerenewed.com/collections/women-bottoms/products/womens-train-n-logo-jogger?variant=16310485418033'
  },
  24459: {
    serialNumber: 24459,
    externalLink: "https://www.thenorthfacerenewed.com",
    // externalLink: 'https://www.thenorthfacerenewed.com/collections/women-jackets/products/womens-metropolis-parka-ii-new?variant=31592171700273'
  },
};

export const rpacTrialProducts = {
  default: {
    image: `${process.env.PUBLIC_URL}/rpac-product.jpg`,
    link: "https://www.r-pac.com/sc",
    showSerial: true,
  },
};

export const gooseProducts = {
  2584: {
    serialNumber: 2584,
    externalLink:
      "https://www.canadagoose.com/ca/en/counterfeit/counterfeit.html",
  },
  6843: {
    serialNumber: 6843,
    externalLink: "https://mtag.io/p120380001",
  },
  7324: {
    serialNumber: 7324,
    externalLink: "https://mtag.io/p120379995",
  },
  11412: {
    serialNumber: 11412,
    externalLink: "https://mtag.io/p1zo5rt?interaction=true",
  },
  15510: {
    serialNumber: 15510,
    externalLink: "https://mtag.io/p120379979",
  },
  24459: {
    serialNumber: 24459,
    externalLink: "https://mtag.io/p121268460",
  },
};
export const nikeProducts = {
  2584: {
    serialNumber: 2584,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
  6843: {
    serialNumber: 6843,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
  7324: {
    serialNumber: 7324,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
  11412: {
    serialNumber: 11412,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
  15510: {
    serialNumber: 15510,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
  24459: {
    serialNumber: 24459,
    externalLink:
      "https://www.nike.com/ca/t/snkr-sox-basketball-crew-socks-grV9cv",
  },
};

export const aotcProducts = {
  1341: {
    serialNumber: 1341,
    externalLink: "https://tapscan.io/20-0012-purity-iq-bp-demo/",
  },
  1344: {
    serialNumber: 1344,
    externalLink: "https://tapscan.io/20-0012-purity-iq-bp-demo/",
  },
  1350: {
    serialNumber: 1350,
    externalLink: "https://tapscan.io/20-0012-purity-iq-bp-demo/",
  },
  1355: {
    serialNumber: 1355,
    externalLink: "https://mtag.io/p120379425",
  },
  1364: {
    serialNumber: 1364,
    externalLink: "https://mtag.io/p120379979",
  },
  1392: {
    serialNumber: 1392,
    externalLink: "https://mtag.io/p121268460",
  },
  1413: {
    serialNumber: 1413,
    externalLink: "https://mtag.io/p120379987",
  },
  1437: {
    serialNumber: 1437,
    externalLink: "https://mtag.io/p120380001",
  },
  1440: {
    serialNumber: 1440,
    externalLink: "https://mtag.io/p120379995",
  },
  1443: {
    serialNumber: 1443,
    externalLink: "https://mtag.io/p120379425",
  },
  316: {
    serialNumber: 316,
    externalLink: "https://mtag.io/p120379987",
  },
  313: {
    serialNumber: 313,
    externalLink: "https://mtag.io/p120380001",
  },
  304: {
    serialNumber: 304,
    externalLink: "https://mtag.io/p120379995",
  },
  318: {
    serialNumber: 318,
    externalLink: "https://mtag.io/p120379425",
  },
};

export const arcteryx_products = {
  3: {
    serialNumber: 3,
    title: "Click on the link below to learn more:",
    subtitle: "ID# 807800",
    link: "https://arcteryx.com/ca/en/shop/mens/gamma-lt-hoody",
    image: `${process.env.PUBLIC_URL}/arcteryx-hoody-helios.jpg.webp`,
  },
  12: {
    serialNumber: 12,
    title: "Click on the link below to learn more:",
    subtitle: "ID# 628245",
    link: "https://arcteryx.com/ca/en/shop/mens/gamma-lt-hoody",
    image: `${process.env.PUBLIC_URL}/arcteryx-hoody-helios.jpg.webp`,
  },
  275: {
    serialNumber: 275,
    title: "Click on the link below to learn more:",
    subtitle: "ID# 56288",
    link: "https://arcteryx.com/ca/en/shop/mens/gamma-lt-hoody",
    image: `${process.env.PUBLIC_URL}/arcteryx-hoody-helios.jpg.webp`,
  },
  14464: {
    serialNumber: 14464,
    title: "Click on the link below to learn more:",
    subtitle: "ID# 255585",
    link: "https://arcteryx.com/ca/en/shop/mens/gamma-lt-hoody",
    image: `${process.env.PUBLIC_URL}/arcteryx-hoody-helios.jpg.webp`,
  },
  32767: {
    serialNumber: 32767,
    title: "Click on the link below to learn more:",
    subtitle: "ID# 628573",
    link: "https://arcteryx.com/ca/en/shop/mens/gamma-lt-hoody",
    image: `${process.env.PUBLIC_URL}/arcteryx-hoody-helios.jpg.webp`,
  },
};

export const cadicaProducts = {
  1: {
    serialNumber: 1,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  2: {
    serialNumber: 2,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  3: {
    serialNumber: 3,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  4: {
    serialNumber: 4,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  5: {
    serialNumber: 5,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  6: {
    serialNumber: 6,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  7: {
    serialNumber: 7,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  8: {
    serialNumber: 8,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  9: {
    serialNumber: 9,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  10: {
    serialNumber: 10,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  11: {
    serialNumber: 11,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  12: {
    serialNumber: 12,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  13: {
    serialNumber: 13,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  14: {
    serialNumber: 14,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  15: {
    serialNumber: 15,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  16: {
    serialNumber: 16,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  17: {
    serialNumber: 17,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  18: {
    serialNumber: 18,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  19: {
    serialNumber: 19,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  20: {
    serialNumber: 20,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  21: {
    serialNumber: 21,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  22: {
    serialNumber: 22,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  23: {
    serialNumber: 23,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  24: {
    serialNumber: 24,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  25: {
    serialNumber: 25,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  26: {
    serialNumber: 26,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  27: {
    serialNumber: 27,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  28: {
    serialNumber: 28,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  29: {
    serialNumber: 29,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  30: {
    serialNumber: 30,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  31: {
    serialNumber: 31,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  32: {
    serialNumber: 32,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  33: {
    serialNumber: 33,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  34: {
    serialNumber: 34,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  35: {
    serialNumber: 35,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  36: {
    serialNumber: 36,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  37: {
    serialNumber: 37,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
  38: {
    serialNumber: 38,
    externalLink: "https://www.cadica.com/it/prodotti/",
  },
};

export const cadicaGroupProducts = {
  default: {
    subtitle: "Click on the link below to learn more:",
    image: `${process.env.PUBLIC_URL}/thombrowne-sweater.png`,
    link: "https://www.thombrowne.com/ca/shopping/light-grey-shetland-wool-jersey-classic-crewneck-4-bar-pullover-15031359",
    showSerial: true,
  },
};

export const jv_dawnrays_products = {
  default: {
    externalLink: "http://www.dawnrays.com",
    serialNumber: "n/a",
  },
};

export const jv_pfizer_products = {
  default: {
    footnote: "单击上面的图标访问辉瑞网站。",
    link: "http://pfizer.com.cn",
    showSerial: true,
    image: `${process.env.PUBLIC_URL}/jv-pfizer-logo.svg`,
  },
};

export const chinesesecret_products = {
  default: {
    externalLink: "http://www.szmabo.com",
    serialNumber: "n/a",
  },
};

export const levis_products = {
  default: {
    externalLink: "https://mtag.io/u2atqyn",
    serialNumber: "n/a",
  },
};

export const lole_products = {
  1101: {
    serialNumber: 1101,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1103: {
    serialNumber: 1103,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  1105: {
    serialNumber: 1105,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle: "Product ID: LSW3955\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  1106: {
    serialNumber: 1106,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1107: {
    serialNumber: 1107,
    title: "Half Moon Joggers",
    subtitle: "Product ID: LSW3908\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1108: {
    serialNumber: 1108,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1109: {
    serialNumber: 1109,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  1110: {
    serialNumber: 1110,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle: "Product ID: LSW3955\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  1111: {
    serialNumber: 1111,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1113: {
    serialNumber: 1113,
    title: "Half Moon Joggers",
    subtitle: "Product ID: LSW3908\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1115: {
    serialNumber: 1115,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5012: {
    serialNumber: 5012,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5021: {
    serialNumber: 5021,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle: "Product ID: LSW3955\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5030: {
    serialNumber: 5030,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  5045: {
    serialNumber: 5045,
    title: "Half Moon Joggers",
    subtitle: "Product ID: LSW3908\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  5051: {
    serialNumber: 5051,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5057: {
    serialNumber: 5057,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5062: {
    serialNumber: 5062,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle: "Product ID: LSW3955\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5071: {
    serialNumber: 5071,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  5166: {
    serialNumber: 5166,
    title: "Half Moon Joggers",
    subtitle: "Product ID: LSW3908\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  5180: {
    serialNumber: 5180,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5111: {
    serialNumber: 5111,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5114: {
    serialNumber: 5114,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle: "Product ID: LSW3955\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5110: {
    serialNumber: 5110,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1171: {
    serialNumber: 1171,
    title: "Half Moon Joggers",
    subtitle: "Product ID: LSW3908\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1167: {
    serialNumber: 1167,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nThermore Ecodown® insulation\n10K/10K membrane\n7 LAYER CLASSIC: 100% POLYESTER",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1179: {
    serialNumber: 1179,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
};

export const pvh_products = {
  1101: {
    serialNumber: 1101,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1103: {
    serialNumber: 1103,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  1105: {
    serialNumber: 1105,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle:
      "Product ID: LSW3955\nTrade In Value: $20\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  1106: {
    serialNumber: 1106,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\nTrade In Value: $30\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1107: {
    serialNumber: 1107,
    title: "Half Moon Joggers",
    subtitle:
      "Product ID: LSW3908\nTrade In Value: $30\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1108: {
    serialNumber: 1108,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1109: {
    serialNumber: 1109,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  1110: {
    serialNumber: 1110,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle:
      "Product ID: LSW3955\nTrade In Value: $20\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  1111: {
    serialNumber: 1111,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\nTrade In Value: $30\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1113: {
    serialNumber: 1113,
    title: "Half Moon Joggers",
    subtitle:
      "Product ID: LSW3908\nTrade In Value: $30\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1115: {
    serialNumber: 1115,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5012: {
    serialNumber: 5012,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5021: {
    serialNumber: 5021,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle:
      "Product ID: LSW3955\nTrade In Value: $20\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5030: {
    serialNumber: 5030,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\nTrade In Value: $30\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  5045: {
    serialNumber: 5045,
    title: "Half Moon Joggers",
    subtitle:
      "Product ID: LSW3908\nTrade In Value: $30\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  5051: {
    serialNumber: 5051,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5057: {
    serialNumber: 5057,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5062: {
    serialNumber: 5062,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle:
      "Product ID: LSW3955\nTrade In Value: $20\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5071: {
    serialNumber: 5071,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\nTrade In Value: $30\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  5166: {
    serialNumber: 5166,
    title: "Half Moon Joggers",
    subtitle:
      "Product ID: LSW3908\nTrade In Value: $30\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  5180: {
    serialNumber: 5180,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  5111: {
    serialNumber: 5111,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
  5114: {
    serialNumber: 5114,
    title: "Agda Front Wrap Opening Long Dress",
    subtitle:
      "Product ID: LSW3955\nTrade In Value: $20\n71% modal 26% polyester 3% elastane",
    image: `${process.env.PUBLIC_URL}/lole-agda.png`,
  },
  5110: {
    serialNumber: 5110,
    title: "Irving Packable Jacket",
    subtitle:
      "Product ID: LUM0008\nTrade In Value: $30\n700 Insulated down\n100% Polyester Bluesign® Certified",
    image: `${process.env.PUBLIC_URL}/lole-irving.png`,
  },
  1171: {
    serialNumber: 1171,
    title: "Half Moon Joggers",
    subtitle:
      "Product ID: LSW3908\nTrade In Value: $30\n87% polyester 13% elastane",
    image: `${process.env.PUBLIC_URL}/lole-joggers.png`,
  },
  1167: {
    serialNumber: 1167,
    title: "Courchevel Jacket",
    subtitle:
      "Product ID: LUW0767\nTrade In Value: $30\nThermore Ecodown® insulation\n10K/10K membrane",
    image: `${process.env.PUBLIC_URL}/lole-courchevel.png`,
  },
  1179: {
    serialNumber: 1179,
    title: "Faith Original Jacket",
    subtitle:
      "Product ID: LUW0681\nTrade In Value: $30\nPrimary Fabric Is Certified Oeko-Tex®\n100% Polyester",
    image: `${process.env.PUBLIC_URL}/lole-faith.png`,
  },
};

export const ribkoff_products = {
  10: {
    title: "Asymmetrical Draped Top",
    subtitle: "ID # 123456",
    image: `${process.env.PUBLIC_URL}/ribkoff-shirt.jpg`,
    link: "https://www.josephribkoff.com/",
    serialNumber: "n/a",
  },
  44: {
    title: "Asymmetrical Draped Top",
    subtitle: "ID # 123456",
    image: `${process.env.PUBLIC_URL}/ribkoff-shirt.jpg`,
    link: "https://www.josephribkoff.com/",
    serialNumber: "n/a",
  },
  54: {
    title: "Asymmetrical Draped Top",
    subtitle: "ID # 123456",
    image: `${process.env.PUBLIC_URL}/ribkoff-shirt.jpg`,
    link: "https://www.josephribkoff.com/",
    serialNumber: "n/a",
  },
  102: {
    title: "Asymmetrical Draped Top",
    subtitle: "ID # 123456",
    image: `${process.env.PUBLIC_URL}/ribkoff-shirt.jpg`,
    link: "https://www.josephribkoff.com/",
    serialNumber: "n/a",
  },
  457: {
    title: "Asymmetrical Draped Top",
    subtitle: "ID # 123456",
    image: `${process.env.PUBLIC_URL}/ribkoff-shirt.jpg`,
    link: "https://www.josephribkoff.com/",
    serialNumber: "n/a",
  },
};

export const brandarmor_products = {
  4045: {
    serialNumber: 4045,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4045\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4043: {
    serialNumber: 4043,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4043\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4044: {
    serialNumber: 4044,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 4044\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  4046: {
    serialNumber: 4046,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4046\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1386: {
    serialNumber: 1386,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1386\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1364: {
    serialNumber: 1364,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1364\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1387: {
    serialNumber: 1387,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1387\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6051: {
    serialNumber: 6051,
    title: "Jordan MA2",
    subtitle: "Product ID: 6051\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6052: {
    serialNumber: 6052,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6052\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6053: {
    serialNumber: 6053,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6053\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  6054: {
    serialNumber: 6054,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 6054\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  4097: {
    serialNumber: 4097,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4098: {
    serialNumber: 4098,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4099: {
    serialNumber: 4099,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4100: {
    serialNumber: 4100,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4101: {
    serialNumber: 4101,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4102: {
    serialNumber: 4102,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4103: {
    serialNumber: 4103,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4104: {
    serialNumber: 4104,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4105: {
    serialNumber: 4105,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4106: {
    serialNumber: 4106,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4107: {
    serialNumber: 4107,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4108: {
    serialNumber: 4108,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4109: {
    serialNumber: 4109,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4110: {
    serialNumber: 4110,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4111: {
    serialNumber: 4111,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4112: {
    serialNumber: 4112,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4113: {
    serialNumber: 4113,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4114: {
    serialNumber: 4114,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4115: {
    serialNumber: 4115,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4116: {
    serialNumber: 4116,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4118: {
    serialNumber: 4118,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4119: {
    serialNumber: 4119,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4120: {
    serialNumber: 4120,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4121: {
    serialNumber: 4121,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4122: {
    serialNumber: 4122,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4123: {
    serialNumber: 4123,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4124: {
    serialNumber: 4124,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4125: {
    serialNumber: 4125,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4126: {
    serialNumber: 4126,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  4127: {
    serialNumber: 4127,
    title: "Beta AR Jacket",
    subtitle: "Model: X000007339\n\nTap the jacket below to learn more!\n",
    showSerial: true,
    link: "https://arcteryx.com/ca/en/shop/mens/beta-ar-jacket ",
    image: `${process.env.PUBLIC_URL}/brandarmor Beta AR Jacket.jpg`,
  },
  51234: {
    serialNumber: 51234,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51235: {
    serialNumber: 51235,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51236: {
    serialNumber: 51236,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51237: {
    serialNumber: 51237,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51238: {
    serialNumber: 51238,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51239: {
    serialNumber: 51239,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51240: {
    serialNumber: 51240,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51241: {
    serialNumber: 51241,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51242: {
    serialNumber: 51242,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51243: {
    serialNumber: 51243,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51244: {
    serialNumber: 51244,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51245: {
    serialNumber: 51245,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51246: {
    serialNumber: 51246,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51263: {
    serialNumber: 51263,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51264: {
    serialNumber: 51264,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51265: {
    serialNumber: 51265,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51266: {
    serialNumber: 51266,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51267: {
    serialNumber: 51267,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51268: {
    serialNumber: 51268,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51269: {
    serialNumber: 51269,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51270: {
    serialNumber: 51270,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51271: {
    serialNumber: 51271,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51272: {
    serialNumber: 51272,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51273: {
    serialNumber: 51273,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51274: {
    serialNumber: 51274,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51275: {
    serialNumber: 51275,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51276: {
    serialNumber: 51276,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51277: {
    serialNumber: 51277,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51278: {
    serialNumber: 51278,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
  51279: {
    serialNumber: 51279,
    title: "Genuine degree from the University of Waterloo",
    subtitle:
      "Bachelor of Applied Science\nHonours Chemical Engineering\nJohn Smith",
    showSerial: true,
    link: "https://uwaterloo.ca ",
    image: `${process.env.PUBLIC_URL}/brandarmor Genuine degree from.png`,
  },
};

export const sample_products = {
  4047: {
    serialNumber: 4047,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4047\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4053: {
    serialNumber: 4053,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4053\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4054: {
    serialNumber: 4054,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4054\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1377: {
    serialNumber: 1377,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1377\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1391: {
    serialNumber: 1391,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1391\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1392: {
    serialNumber: 1392,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1392\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6043: {
    serialNumber: 6043,
    title: "Jordan MA2",
    subtitle: "Product ID: 6043\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6048: {
    serialNumber: 6048,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6048\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6049: {
    serialNumber: 6049,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6049\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  151572: {
    serialNumber: 151572,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 151572\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  151569: {
    serialNumber: 151569,
    title: "Zipper Nylon Anorak Jacket",
    subtitle:
      "Product ID: 151569\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  112425: {
    serialNumber: 112425,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 112425\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  4048: {
    serialNumber: 4048,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4048\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4049: {
    serialNumber: 4049,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4049\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4050: {
    serialNumber: 4050,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4050\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1398: {
    serialNumber: 1398,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1398\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1397: {
    serialNumber: 1397,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1397\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1395: {
    serialNumber: 1395,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1395\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6045: {
    serialNumber: 6045,
    title: "Jordan MA2",
    subtitle: "Product ID: 6045\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6046: {
    serialNumber: 6046,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6046\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6047: {
    serialNumber: 6047,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6047\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  162872: {
    serialNumber: 162872,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 162872\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  187154: {
    serialNumber: 187154,
    title: "Zipper Nylon Anorak Jacket",
    subtitle:
      "Product ID: 187154\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  185641: {
    serialNumber: 185641,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 185641\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  4045: {
    serialNumber: 4045,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4045\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4043: {
    serialNumber: 4043,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4043\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4046: {
    serialNumber: 4046,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4046\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1386: {
    serialNumber: 1386,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1386\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1364: {
    serialNumber: 1364,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1364\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1387: {
    serialNumber: 1387,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1387\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6051: {
    serialNumber: 6051,
    title: "Jordan MA2",
    subtitle: "Product ID: 6051\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6052: {
    serialNumber: 6052,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6052\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6053: {
    serialNumber: 6053,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6053\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  6054: {
    serialNumber: 6054,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 6054\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  4044: {
    serialNumber: 4044,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 4044\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  4034: {
    serialNumber: 4034,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4034\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4035: {
    serialNumber: 4035,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4035\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4036: {
    serialNumber: 4036,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4036\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1327: {
    serialNumber: 1327,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1327\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1328: {
    serialNumber: 1328,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1328\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1349: {
    serialNumber: 1349,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1349\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6039: {
    serialNumber: 6039,
    title: "Jordan MA2",
    subtitle: "Product ID: 6039\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6041: {
    serialNumber: 6041,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6041\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6042: {
    serialNumber: 6042,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6042\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  101777: {
    serialNumber: 101777,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 101777\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  101821: {
    serialNumber: 101821,
    title: "Zipper Nylon Anorak Jacket",
    subtitle:
      "Product ID: 101821\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  102236: {
    serialNumber: 102236,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 102236\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  102238: {
    serialNumber: 102238,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 102238\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1350: {
    serialNumber: 1350,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1350\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1352: {
    serialNumber: 1352,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1352\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1353: {
    serialNumber: 1353,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1353\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  1355: {
    serialNumber: 1355,
    title: "Jordan MA2",
    subtitle: "Product ID: 1355\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  1356: {
    serialNumber: 1356,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 1356\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  4040: {
    serialNumber: 4040,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4040\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4041: {
    serialNumber: 4041,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4041\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4042: {
    serialNumber: 4042,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4042\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1324: {
    serialNumber: 1324,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1324\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1325: {
    serialNumber: 1325,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1325\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1326: {
    serialNumber: 1326,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1326\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6032: {
    serialNumber: 6032,
    title: "Jordan MA2",
    subtitle: "Product ID: 6032\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6033: {
    serialNumber: 6033,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6033\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6034: {
    serialNumber: 6034,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6034\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4031: {
    serialNumber: 4031,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4031\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4032: {
    serialNumber: 4032,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4032\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4033: {
    serialNumber: 4033,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4033\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1321: {
    serialNumber: 1321,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1321\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1322: {
    serialNumber: 1322,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1322\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1323: {
    serialNumber: 1323,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1323\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6026: {
    serialNumber: 6026,
    title: "Jordan MA2",
    subtitle: "Product ID: 6026\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6027: {
    serialNumber: 6027,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6027\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6028: {
    serialNumber: 6028,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6028\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4025: {
    serialNumber: 4025,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4025\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4026: {
    serialNumber: 4026,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4026\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4027: {
    serialNumber: 4027,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4027\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1009: {
    serialNumber: 1009,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1009\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1300: {
    serialNumber: 1300,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1300\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1301: {
    serialNumber: 1301,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1301\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6020: {
    serialNumber: 6020,
    title: "Jordan MA2",
    subtitle: "Product ID: 6020\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6021: {
    serialNumber: 6021,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6021\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6022: {
    serialNumber: 6022,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6022\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4028: {
    serialNumber: 4028,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4028\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4029: {
    serialNumber: 4029,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4029\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4030: {
    serialNumber: 4030,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4030\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1305: {
    serialNumber: 1305,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1305\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1306: {
    serialNumber: 1306,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1306\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1311: {
    serialNumber: 1311,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1311\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6023: {
    serialNumber: 6023,
    title: "Jordan MA2",
    subtitle: "Product ID: 6023\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6024: {
    serialNumber: 6024,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6024\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6025: {
    serialNumber: 6025,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6025\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4000: {
    serialNumber: 4000,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4000\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4001: {
    serialNumber: 4001,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4001\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4002: {
    serialNumber: 4002,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4002\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7006: {
    serialNumber: 7006,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7006\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7007: {
    serialNumber: 7007,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7007\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7008: {
    serialNumber: 7008,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7008\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6000: {
    serialNumber: 6000,
    title: "Jordan MA2",
    subtitle: "Product ID: 6000\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6001: {
    serialNumber: 6001,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6001\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6002: {
    serialNumber: 6002,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6002\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4003: {
    serialNumber: 4003,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4003\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4004: {
    serialNumber: 4004,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4004\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4005: {
    serialNumber: 4005,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4005\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7011: {
    serialNumber: 7011,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7011\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7013: {
    serialNumber: 7013,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7013\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7014: {
    serialNumber: 7014,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7014\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6003: {
    serialNumber: 6003,
    title: "Jordan MA2",
    subtitle: "Product ID: 6003\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6004: {
    serialNumber: 6004,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6004\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6005: {
    serialNumber: 6005,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6005\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4006: {
    serialNumber: 4006,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4006\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4009: {
    serialNumber: 4009,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4009\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4018: {
    serialNumber: 4018,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4018\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7015: {
    serialNumber: 7015,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7015\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7016: {
    serialNumber: 7016,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7016\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7017: {
    serialNumber: 7017,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7017\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6006: {
    serialNumber: 6006,
    title: "Jordan MA2",
    subtitle: "Product ID: 6006\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6007: {
    serialNumber: 6007,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6007\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6008: {
    serialNumber: 6008,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6008\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4019: {
    serialNumber: 4019,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4019\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4020: {
    serialNumber: 4020,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4020\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4021: {
    serialNumber: 4021,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4021\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7018: {
    serialNumber: 7018,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7018\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7019: {
    serialNumber: 7019,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7019\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7020: {
    serialNumber: 7020,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7020\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6011: {
    serialNumber: 6011,
    title: "Jordan MA2",
    subtitle: "Product ID: 6011\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6012: {
    serialNumber: 6012,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6012\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6013: {
    serialNumber: 6013,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6013\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4022: {
    serialNumber: 4022,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4022\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4023: {
    serialNumber: 4023,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4023\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4024: {
    serialNumber: 4024,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4024\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7021: {
    serialNumber: 7021,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7021\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7022: {
    serialNumber: 7022,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7022\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7023: {
    serialNumber: 7023,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7023\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  6016: {
    serialNumber: 6016,
    title: "Jordan MA2",
    subtitle: "Product ID: 6016\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  6017: {
    serialNumber: 6017,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 6017\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  6018: {
    serialNumber: 6018,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 6018\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  4012: {
    serialNumber: 4012,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4012\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4013: {
    serialNumber: 4013,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4013\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4014: {
    serialNumber: 4014,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4014\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7000: {
    serialNumber: 7000,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7000\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7001: {
    serialNumber: 7001,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7001\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7002: {
    serialNumber: 7002,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7002\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  5111: {
    serialNumber: 5111,
    title: "Jordan MA2",
    subtitle: "Product ID: 5111\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-ma2-mens-shoes-knK52s/DJ5187-001",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan MA2.png`,
  },
  5114: {
    serialNumber: 5114,
    title: "Jordan Pro Strong",
    subtitle: "Product ID: 5114\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-pro-strong-mens-shoes-Nt7HpB/DC8418-100",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Pro Strong.png`,
  },
  4015: {
    serialNumber: 4015,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 4015\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  4016: {
    serialNumber: 4016,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 4016\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  4017: {
    serialNumber: 4017,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 4017\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  7003: {
    serialNumber: 7003,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 7003\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  7004: {
    serialNumber: 7004,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 7004\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  7005: {
    serialNumber: 7005,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 7005\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  1179: {
    serialNumber: 1179,
    title: "Jordan Flight Club '91",
    subtitle: "Product ID: 1179\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://www.nike.com/t/jordan-flight-club-91-shoes-yP1k2R/555475-600",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Flight Club .png`,
  },
  1212: {
    serialNumber: 1212,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "Product ID: 1212\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/metallic-puffer-coat-with-hoodie-1",
    image: `${process.env.PUBLIC_URL}/samplepack Metallic Puffer Hoo.jpg`,
  },
  1214: {
    serialNumber: 1214,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle: "Product ID: 1214\nComposition: 100% Nylon\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/dolman-sleeve-dry-nylon-color-block-anorak-jacket-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Dolman Sleeve Color.jpg`,
  },
  1222: {
    serialNumber: 1222,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "Product ID: 1222\nComposition: 55% Cotton, 45% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/wool-like-plaid-crop-shacket",
    image: `${process.env.PUBLIC_URL}/samplepack International Wool .jpg`,
  },
  3013: {
    serialNumber: 3013,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3013\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3005: {
    serialNumber: 3005,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3005\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3003: {
    serialNumber: 3003,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3003\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1013: {
    serialNumber: 1013,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1013\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1011: {
    serialNumber: 1011,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1011\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1014: {
    serialNumber: 1014,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1014\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1122: {
    serialNumber: 1122,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1122\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1117: {
    serialNumber: 1117,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1117\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1126: {
    serialNumber: 1126,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1126\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2005: {
    serialNumber: 2005,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2005\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2007: {
    serialNumber: 2007,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2007\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2000: {
    serialNumber: 2000,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2000\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5112: {
    serialNumber: 5112,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5112\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5188: {
    serialNumber: 5188,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5188\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3002: {
    serialNumber: 3002,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3002\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3004: {
    serialNumber: 3004,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3004\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3009: {
    serialNumber: 3009,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3009\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1012: {
    serialNumber: 1012,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1012\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1002: {
    serialNumber: 1002,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1002\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1001: {
    serialNumber: 1001,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1001\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1131: {
    serialNumber: 1131,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1131\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1116: {
    serialNumber: 1116,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1116\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1119: {
    serialNumber: 1119,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1119\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2017: {
    serialNumber: 2017,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2017\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2006: {
    serialNumber: 2006,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2006\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2014: {
    serialNumber: 2014,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2014\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5187: {
    serialNumber: 5187,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5187\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5674: {
    serialNumber: 5674,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5674\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3006: {
    serialNumber: 3006,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3006\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3001: {
    serialNumber: 3001,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3001\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3007: {
    serialNumber: 3007,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3007\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1015: {
    serialNumber: 1015,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1015\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1007: {
    serialNumber: 1007,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1007\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1008: {
    serialNumber: 1008,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1008\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1123: {
    serialNumber: 1123,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1123\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1124: {
    serialNumber: 1124,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1124\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1125: {
    serialNumber: 1125,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1125\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2013: {
    serialNumber: 2013,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2013\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2001: {
    serialNumber: 2001,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2001\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2008: {
    serialNumber: 2008,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2008\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5697: {
    serialNumber: 5697,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5697\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5695: {
    serialNumber: 5695,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5695\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3014: {
    serialNumber: 3014,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3014\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3010: {
    serialNumber: 3010,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3010\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3011: {
    serialNumber: 3011,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3011\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1000: {
    serialNumber: 1000,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1000\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1005: {
    serialNumber: 1005,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1005\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1003: {
    serialNumber: 1003,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1003\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1129: {
    serialNumber: 1129,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1129\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1121: {
    serialNumber: 1121,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1121\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1137: {
    serialNumber: 1137,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1137\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2010: {
    serialNumber: 2010,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2010\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2003: {
    serialNumber: 2003,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2003\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2012: {
    serialNumber: 2012,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2012\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5107: {
    serialNumber: 5107,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5107\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5105: {
    serialNumber: 5105,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5105\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3012: {
    serialNumber: 3012,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3012\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3017: {
    serialNumber: 3017,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3017\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3027: {
    serialNumber: 3027,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3027\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1006: {
    serialNumber: 1006,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1006\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1004: {
    serialNumber: 1004,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1004\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1030: {
    serialNumber: 1030,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1030\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1143: {
    serialNumber: 1143,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1143\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1141: {
    serialNumber: 1141,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1141\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1139: {
    serialNumber: 1139,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1139\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2015: {
    serialNumber: 2015,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2015\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2009: {
    serialNumber: 2009,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2009\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2004: {
    serialNumber: 2004,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2004\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5053: {
    serialNumber: 5053,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5053\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5054: {
    serialNumber: 5054,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5054\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3018: {
    serialNumber: 3018,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3018\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3020: {
    serialNumber: 3020,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3020\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3019: {
    serialNumber: 3019,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3019\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1018: {
    serialNumber: 1018,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1018\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1029: {
    serialNumber: 1029,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1029\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1016: {
    serialNumber: 1016,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1016\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1138: {
    serialNumber: 1138,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1138\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1133: {
    serialNumber: 1133,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1133\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1135: {
    serialNumber: 1135,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1135\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2033: {
    serialNumber: 2033,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2033\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2029: {
    serialNumber: 2029,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2029\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2024: {
    serialNumber: 2024,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2024\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5636: {
    serialNumber: 5636,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5636\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5205: {
    serialNumber: 5205,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5205\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3034: {
    serialNumber: 3034,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3034\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3032: {
    serialNumber: 3032,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3032\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3031: {
    serialNumber: 3031,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3031\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1028: {
    serialNumber: 1028,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1028\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1027: {
    serialNumber: 1027,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1027\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1024: {
    serialNumber: 1024,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1024\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1145: {
    serialNumber: 1145,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1145\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1149: {
    serialNumber: 1149,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1149\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1156: {
    serialNumber: 1156,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1156\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2020: {
    serialNumber: 2020,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2020\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2022: {
    serialNumber: 2022,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2022\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2026: {
    serialNumber: 2026,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2026\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5152: {
    serialNumber: 5152,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5152\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5165: {
    serialNumber: 5165,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5165\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3024: {
    serialNumber: 3024,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3024\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3016: {
    serialNumber: 3016,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3016\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3029: {
    serialNumber: 3029,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3029\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1031: {
    serialNumber: 1031,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1031\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1026: {
    serialNumber: 1026,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1026\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1017: {
    serialNumber: 1017,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1017\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1151: {
    serialNumber: 1151,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1151\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1148: {
    serialNumber: 1148,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1148\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1163: {
    serialNumber: 1163,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1163\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2034: {
    serialNumber: 2034,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2034\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2031: {
    serialNumber: 2031,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2031\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2023: {
    serialNumber: 2023,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2023\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5699: {
    serialNumber: 5699,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5699\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5623: {
    serialNumber: 5623,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5623\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3033: {
    serialNumber: 3033,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3033\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3025: {
    serialNumber: 3025,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3025\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3026: {
    serialNumber: 3026,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3026\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1019: {
    serialNumber: 1019,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1019\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1023: {
    serialNumber: 1023,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1023\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1020: {
    serialNumber: 1020,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1020\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1162: {
    serialNumber: 1162,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1162\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1154: {
    serialNumber: 1154,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1154\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1157: {
    serialNumber: 1157,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1157\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2032: {
    serialNumber: 2032,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2032\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2021: {
    serialNumber: 2021,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2021\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2036: {
    serialNumber: 2036,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2036\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5621: {
    serialNumber: 5621,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5621\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5624: {
    serialNumber: 5624,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5624\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3021: {
    serialNumber: 3021,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3021\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3041: {
    serialNumber: 3041,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3041\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3042: {
    serialNumber: 3042,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3042\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1033: {
    serialNumber: 1033,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1033\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1043: {
    serialNumber: 1043,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1043\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1037: {
    serialNumber: 1037,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1037\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1159: {
    serialNumber: 1159,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1159\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1161: {
    serialNumber: 1161,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1161\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1175: {
    serialNumber: 1175,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1175\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2037: {
    serialNumber: 2037,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2037\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2027: {
    serialNumber: 2027,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2027\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2041: {
    serialNumber: 2041,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2041\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5622: {
    serialNumber: 5622,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5622\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5608: {
    serialNumber: 5608,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5608\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3045: {
    serialNumber: 3045,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3045\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3047: {
    serialNumber: 3047,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3047\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3037: {
    serialNumber: 3037,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3037\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1047: {
    serialNumber: 1047,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1047\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1040: {
    serialNumber: 1040,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1040\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1035: {
    serialNumber: 1035,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1035\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1177: {
    serialNumber: 1177,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1177\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1170: {
    serialNumber: 1170,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1170\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1169: {
    serialNumber: 1169,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1169\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2051: {
    serialNumber: 2051,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2051\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2046: {
    serialNumber: 2046,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2046\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2045: {
    serialNumber: 2045,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2045\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5607: {
    serialNumber: 5607,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5607\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5605: {
    serialNumber: 5605,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5605\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3036: {
    serialNumber: 3036,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3036\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3044: {
    serialNumber: 3044,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3044\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3050: {
    serialNumber: 3050,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3050\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1036: {
    serialNumber: 1036,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1036\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1038: {
    serialNumber: 1038,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1038\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1032: {
    serialNumber: 1032,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1032\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1172: {
    serialNumber: 1172,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1172\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1165: {
    serialNumber: 1165,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1165\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1173: {
    serialNumber: 1173,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1173\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2048: {
    serialNumber: 2048,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2048\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2043: {
    serialNumber: 2043,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2043\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2040: {
    serialNumber: 2040,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2040\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5606: {
    serialNumber: 5606,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5606\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5609: {
    serialNumber: 5609,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5609\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3039: {
    serialNumber: 3039,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3039\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3048: {
    serialNumber: 3048,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3048\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3049: {
    serialNumber: 3049,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3049\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1045: {
    serialNumber: 1045,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1045\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1041: {
    serialNumber: 1041,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1041\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1042: {
    serialNumber: 1042,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1042\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1185: {
    serialNumber: 1185,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1185\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1189: {
    serialNumber: 1189,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1189\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1188: {
    serialNumber: 1188,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1188\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2058: {
    serialNumber: 2058,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2058\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2049: {
    serialNumber: 2049,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2049\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2053: {
    serialNumber: 2053,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2053\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5293: {
    serialNumber: 5293,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5293\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5292: {
    serialNumber: 5292,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5292\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3052: {
    serialNumber: 3052,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3052\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3054: {
    serialNumber: 3054,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3054\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3051: {
    serialNumber: 3051,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3051\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1034: {
    serialNumber: 1034,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1034\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1039: {
    serialNumber: 1039,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1039\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1046: {
    serialNumber: 1046,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1046\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1183: {
    serialNumber: 1183,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1183\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1181: {
    serialNumber: 1181,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1181\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1186: {
    serialNumber: 1186,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1186\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2050: {
    serialNumber: 2050,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2050\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2052: {
    serialNumber: 2052,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2052\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2047: {
    serialNumber: 2047,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2047\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5297: {
    serialNumber: 5297,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5297\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5296: {
    serialNumber: 5296,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5296\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3038: {
    serialNumber: 3038,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3038\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3043: {
    serialNumber: 3043,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3043\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3040: {
    serialNumber: 3040,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3040\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1048: {
    serialNumber: 1048,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1048\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1063: {
    serialNumber: 1063,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1063\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1058: {
    serialNumber: 1058,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1058\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1211: {
    serialNumber: 1211,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1211\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1207: {
    serialNumber: 1207,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1207\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1206: {
    serialNumber: 1206,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1206\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2059: {
    serialNumber: 2059,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2059\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2056: {
    serialNumber: 2056,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2056\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2044: {
    serialNumber: 2044,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2044\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5295: {
    serialNumber: 5295,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5295\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5298: {
    serialNumber: 5298,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5298\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3053: {
    serialNumber: 3053,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3053\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3066: {
    serialNumber: 3066,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3066\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3059: {
    serialNumber: 3059,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3059\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1060: {
    serialNumber: 1060,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1060\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1062: {
    serialNumber: 1062,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1062\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1057: {
    serialNumber: 1057,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1057\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1204: {
    serialNumber: 1204,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1204\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1209: {
    serialNumber: 1209,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1209\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1199: {
    serialNumber: 1199,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1199\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2057: {
    serialNumber: 2057,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2057\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2055: {
    serialNumber: 2055,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2055\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2042: {
    serialNumber: 2042,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2042\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5299: {
    serialNumber: 5299,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5299\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5628: {
    serialNumber: 5628,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5628\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3073: {
    serialNumber: 3073,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3073\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3060: {
    serialNumber: 3060,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3060\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3067: {
    serialNumber: 3067,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3067\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1054: {
    serialNumber: 1054,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1054\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1059: {
    serialNumber: 1059,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1059\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1055: {
    serialNumber: 1055,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1055\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1197: {
    serialNumber: 1197,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1197\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1201: {
    serialNumber: 1201,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1201\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1205: {
    serialNumber: 1205,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1205\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2064: {
    serialNumber: 2064,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2064\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2074: {
    serialNumber: 2074,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2074\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2069: {
    serialNumber: 2069,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2069\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5627: {
    serialNumber: 5627,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5627\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5629: {
    serialNumber: 5629,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5629\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3072: {
    serialNumber: 3072,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3072\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3069: {
    serialNumber: 3069,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3069\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3070: {
    serialNumber: 3070,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3070\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1061: {
    serialNumber: 1061,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1061\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1053: {
    serialNumber: 1053,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1053\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1052: {
    serialNumber: 1052,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1052\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1202: {
    serialNumber: 1202,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1202\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1217: {
    serialNumber: 1217,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1217\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1223: {
    serialNumber: 1223,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1223\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2078: {
    serialNumber: 2078,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2078\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2067: {
    serialNumber: 2067,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2067\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2066: {
    serialNumber: 2066,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2066\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5626: {
    serialNumber: 5626,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5626\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5625: {
    serialNumber: 5625,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5625\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3071: {
    serialNumber: 3071,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3071\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3055: {
    serialNumber: 3055,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3055\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3058: {
    serialNumber: 3058,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3058\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1051: {
    serialNumber: 1051,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1051\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1049: {
    serialNumber: 1049,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1049\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1056: {
    serialNumber: 1056,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1056\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1220: {
    serialNumber: 1220,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1220\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1225: {
    serialNumber: 1225,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1225\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1216: {
    serialNumber: 1216,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1216\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2075: {
    serialNumber: 2075,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2075\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2077: {
    serialNumber: 2077,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2077\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2072: {
    serialNumber: 2072,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2072\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5615: {
    serialNumber: 5615,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5615\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5618: {
    serialNumber: 5618,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5618\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3074: {
    serialNumber: 3074,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3074\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3064: {
    serialNumber: 3064,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3064\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3065: {
    serialNumber: 3065,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3065\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1079: {
    serialNumber: 1079,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1079\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1075: {
    serialNumber: 1075,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1075\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1074: {
    serialNumber: 1074,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1074\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1221: {
    serialNumber: 1221,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1221\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1213: {
    serialNumber: 1213,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1213\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1219: {
    serialNumber: 1219,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1219\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2079: {
    serialNumber: 2079,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2079\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2071: {
    serialNumber: 2071,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2071\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2063: {
    serialNumber: 2063,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2063\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5617: {
    serialNumber: 5617,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5617\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5619: {
    serialNumber: 5619,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5619\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3056: {
    serialNumber: 3056,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3056\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3057: {
    serialNumber: 3057,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3057\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3061: {
    serialNumber: 3061,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3061\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1070: {
    serialNumber: 1070,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1070\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1072: {
    serialNumber: 1072,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1072\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1069: {
    serialNumber: 1069,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1069\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1215: {
    serialNumber: 1215,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1215\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1218: {
    serialNumber: 1218,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1218\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1227: {
    serialNumber: 1227,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1227\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2090: {
    serialNumber: 2090,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2090\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2073: {
    serialNumber: 2073,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2073\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2082: {
    serialNumber: 2082,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2082\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5616: {
    serialNumber: 5616,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5616\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5612: {
    serialNumber: 5612,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5612\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3091: {
    serialNumber: 3091,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3091\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3087: {
    serialNumber: 3087,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3087\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3089: {
    serialNumber: 3089,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3089\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1066: {
    serialNumber: 1066,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1066\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1071: {
    serialNumber: 1071,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1071\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1078: {
    serialNumber: 1078,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1078\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1239: {
    serialNumber: 1239,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1239\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1231: {
    serialNumber: 1231,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1231\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1237: {
    serialNumber: 1237,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1237\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2084: {
    serialNumber: 2084,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2084\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2085: {
    serialNumber: 2085,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2085\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2086: {
    serialNumber: 2086,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2086\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5610: {
    serialNumber: 5610,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5610\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5276: {
    serialNumber: 5276,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5276\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3092: {
    serialNumber: 3092,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3092\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3090: {
    serialNumber: 3090,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3090\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3093: {
    serialNumber: 3093,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3093\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1065: {
    serialNumber: 1065,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1065\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1064: {
    serialNumber: 1064,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1064\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1073: {
    serialNumber: 1073,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1073\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1243: {
    serialNumber: 1243,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1243\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1233: {
    serialNumber: 1233,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1233\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1241: {
    serialNumber: 1241,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1241\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2088: {
    serialNumber: 2088,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2088\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2093: {
    serialNumber: 2093,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2093\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2091: {
    serialNumber: 2091,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2091\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5281: {
    serialNumber: 5281,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5281\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5171: {
    serialNumber: 5171,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5171\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3086: {
    serialNumber: 3086,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3086\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3097: {
    serialNumber: 3097,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3097\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3099: {
    serialNumber: 3099,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3099\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1068: {
    serialNumber: 1068,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1068\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1067: {
    serialNumber: 1067,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1067\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1076: {
    serialNumber: 1076,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1076\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1229: {
    serialNumber: 1229,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1229\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1235: {
    serialNumber: 1235,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1235\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1238: {
    serialNumber: 1238,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1238\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2087: {
    serialNumber: 2087,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2087\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2080: {
    serialNumber: 2080,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2080\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2089: {
    serialNumber: 2089,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2089\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5287: {
    serialNumber: 5287,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5287\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5145: {
    serialNumber: 5145,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5145\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3096: {
    serialNumber: 3096,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3096\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3100: {
    serialNumber: 3100,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3100\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3102: {
    serialNumber: 3102,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3102\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1077: {
    serialNumber: 1077,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1077\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1083: {
    serialNumber: 1083,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1083\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1091: {
    serialNumber: 1091,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1091\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1234: {
    serialNumber: 1234,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1234\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1249: {
    serialNumber: 1249,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1249\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1247: {
    serialNumber: 1247,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1247\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2083: {
    serialNumber: 2083,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2083\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2099: {
    serialNumber: 2099,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2099\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2095: {
    serialNumber: 2095,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2095\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5253: {
    serialNumber: 5253,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5253\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5282: {
    serialNumber: 5282,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5282\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3101: {
    serialNumber: 3101,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "Product ID: 3101\nComposition: 95% Polyester, 5% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-flare-sleeve-floral-bubble-hem-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3098: {
    serialNumber: 3098,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "Product ID: 3098\nComposition: 95% Polyester, 5% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-puff-sleeve-floral-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3095: {
    serialNumber: 3095,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle: "Product ID: 3095\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-tops/products/roz-ali-chain-trim-flutter-sleeve-blouse-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1087: {
    serialNumber: 1087,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1087\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1088: {
    serialNumber: 1088,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1088\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1081: {
    serialNumber: 1081,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1081\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1255: {
    serialNumber: 1255,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1255\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1245: {
    serialNumber: 1245,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1245\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1257: {
    serialNumber: 1257,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1257\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2094: {
    serialNumber: 2094,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2094\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2092: {
    serialNumber: 2092,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2092\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2098: {
    serialNumber: 2098,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2098\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5173: {
    serialNumber: 5173,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5173\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5286: {
    serialNumber: 5286,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5286\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1095: {
    serialNumber: 1095,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1095\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1084: {
    serialNumber: 1084,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1084\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1090: {
    serialNumber: 1090,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1090\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1252: {
    serialNumber: 1252,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1252\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1248: {
    serialNumber: 1248,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1248\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1259: {
    serialNumber: 1259,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1259\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2109: {
    serialNumber: 2109,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2109\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2106: {
    serialNumber: 2106,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2106\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2111: {
    serialNumber: 2111,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2111\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5153: {
    serialNumber: 5153,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5153\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5285: {
    serialNumber: 5285,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5285\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1086: {
    serialNumber: 1086,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1086\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1089: {
    serialNumber: 1089,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1089\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1094: {
    serialNumber: 1094,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1094\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1253: {
    serialNumber: 1253,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1253\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1250: {
    serialNumber: 1250,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1250\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1147: {
    serialNumber: 1147,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1147\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2117: {
    serialNumber: 2117,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2117\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2102: {
    serialNumber: 2102,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2102\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2100: {
    serialNumber: 2100,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2100\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5262: {
    serialNumber: 5262,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5262\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5150: {
    serialNumber: 5150,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5150\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1085: {
    serialNumber: 1085,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1085\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1092: {
    serialNumber: 1092,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1092\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1082: {
    serialNumber: 1082,
    title: "Moroccan Floral Veronica Dress",
    subtitle: "Product ID: 1082\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/moroccan-floral-veronica-dress",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1158: {
    serialNumber: 1158,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1158\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1153: {
    serialNumber: 1153,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1153\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1152: {
    serialNumber: 1152,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1152\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2118: {
    serialNumber: 2118,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2118\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2107: {
    serialNumber: 2107,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2107\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2115: {
    serialNumber: 2115,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2115\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5264: {
    serialNumber: 5264,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5264\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5132: {
    serialNumber: 5132,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5132\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1093: {
    serialNumber: 1093,
    title: "Odessa Embroidered Tiered Baby Doll Dress",
    subtitle: "Product ID: 1093\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/odessa-embroidered-tiered-baby-doll-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1080: {
    serialNumber: 1080,
    title: "Luna Coral Floral Hanky Hem Dress",
    subtitle:
      "Product ID: 1080\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/luna-coral-floral-hanky-hem-dress-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1190: {
    serialNumber: 1190,
    title: "H Halston Pleat Front Crop Wide Leg Pant",
    subtitle:
      "Product ID: 1190\nComposition: 52% Rayon, 48% Nylon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/new-arrivals/products/h-halston-pleat-front-crop-wide-leg-pant-misses",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1146: {
    serialNumber: 1146,
    title: "Roz & Ali Secret Agent Slim Leg Wide Waistband Pants",
    subtitle:
      "Product ID: 1146\nComposition: 63% Polyester, 43% Nylon, 4% Spandex\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/new-arrivals/products/roz-ali-secret-agent-pull-on-wide-waistband-with-tummy-control-and-slim-legs-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1160: {
    serialNumber: 1160,
    title: "Westport Signature 5 Pocket Straight Leg Jean",
    subtitle:
      "Product ID: 1160\nComposition: 72% Cotton, 24% Polyester, 3% Other, 1% Lycra\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/new-arrivals/products/westport-signature-5-pocket-straight-leg-jean-misses",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2114: {
    serialNumber: 2114,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle: "Product ID: 2114\nComposition: 100% Polyester\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/floral-bomber-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2110: {
    serialNumber: 2110,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "Product ID: 2110\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/color-block-sporty-windbreaker-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2113: {
    serialNumber: 2113,
    title: "Zipper Nylon Anorak Jacket",
    subtitle: "Product ID: 2113\nComposition: 100% Polyester\nMade In: Vietnam",
    link: "https://dressbarn.com/collections/clothing-jackets-and-coats/products/zipper-nylon-anorak-jacket",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5273: {
    serialNumber: 5273,
    title: "Lovely Pullover",
    subtitle:
      "Product ID: 5273\nComposition: 52% Cotton, 28% Polyester, 20% Rayon\nMade In: Saipan",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/lovely-pullover",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5274: {
    serialNumber: 5274,
    title: "Madison & Hudson Striped Cardigan Sweater",
    subtitle:
      "Product ID: 5274\nComposition: 100% Polyester\nMade In: Bangladesh",
    link: "https://dressbarn.com/collections/clothing-tops-sweaters/products/madison-hudson-striped-cardigan-sweater",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
};

export const stamp_products = {
  default: {},
};

export const roadshow_products = {
  3013: {
    serialNumber: 3013,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3013\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3005: {
    serialNumber: 3005,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3005\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3003: {
    serialNumber: 3003,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3003\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1013: {
    serialNumber: 1013,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1013\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1011: {
    serialNumber: 1011,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1011\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1014: {
    serialNumber: 1014,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1014\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1122: {
    serialNumber: 1122,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1122\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1117: {
    serialNumber: 1117,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1117\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1126: {
    serialNumber: 1126,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1126\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2005: {
    serialNumber: 2005,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2005\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2007: {
    serialNumber: 2007,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2007\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2000: {
    serialNumber: 2000,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2000\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5112: {
    serialNumber: 5112,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5112\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5188: {
    serialNumber: 5188,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5188\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3002: {
    serialNumber: 3002,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3002\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3004: {
    serialNumber: 3004,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3004\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3009: {
    serialNumber: 3009,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3009\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1012: {
    serialNumber: 1012,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1012\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1002: {
    serialNumber: 1002,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1002\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1001: {
    serialNumber: 1001,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1001\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1131: {
    serialNumber: 1131,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1131\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1116: {
    serialNumber: 1116,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1116\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1119: {
    serialNumber: 1119,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1119\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2017: {
    serialNumber: 2017,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2017\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2006: {
    serialNumber: 2006,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2006\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2014: {
    serialNumber: 2014,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2014\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5187: {
    serialNumber: 5187,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5187\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5674: {
    serialNumber: 5674,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5674\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3006: {
    serialNumber: 3006,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3006\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3001: {
    serialNumber: 3001,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3001\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3007: {
    serialNumber: 3007,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3007\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1015: {
    serialNumber: 1015,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1015\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1007: {
    serialNumber: 1007,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1007\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1008: {
    serialNumber: 1008,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1008\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1123: {
    serialNumber: 1123,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1123\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1124: {
    serialNumber: 1124,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1124\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1125: {
    serialNumber: 1125,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1125\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2013: {
    serialNumber: 2013,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2013\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2001: {
    serialNumber: 2001,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2001\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2008: {
    serialNumber: 2008,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2008\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5697: {
    serialNumber: 5697,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5697\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5695: {
    serialNumber: 5695,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5695\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3014: {
    serialNumber: 3014,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3014\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3010: {
    serialNumber: 3010,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3010\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3011: {
    serialNumber: 3011,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3011\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1000: {
    serialNumber: 1000,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1000\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1005: {
    serialNumber: 1005,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1005\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1003: {
    serialNumber: 1003,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1003\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1129: {
    serialNumber: 1129,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1129\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1121: {
    serialNumber: 1121,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1121\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1137: {
    serialNumber: 1137,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1137\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2010: {
    serialNumber: 2010,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2010\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2003: {
    serialNumber: 2003,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2003\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2012: {
    serialNumber: 2012,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2012\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5107: {
    serialNumber: 5107,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5107\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5105: {
    serialNumber: 5105,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5105\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3012: {
    serialNumber: 3012,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3012\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3017: {
    serialNumber: 3017,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3017\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3027: {
    serialNumber: 3027,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3027\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1006: {
    serialNumber: 1006,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1006\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1004: {
    serialNumber: 1004,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1004\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1030: {
    serialNumber: 1030,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1030\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1143: {
    serialNumber: 1143,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1143\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1141: {
    serialNumber: 1141,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1141\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1139: {
    serialNumber: 1139,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1139\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2015: {
    serialNumber: 2015,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2015\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2009: {
    serialNumber: 2009,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2009\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2004: {
    serialNumber: 2004,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2004\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5053: {
    serialNumber: 5053,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5053\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5054: {
    serialNumber: 5054,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5054\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3018: {
    serialNumber: 3018,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3018\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3020: {
    serialNumber: 3020,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3020\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3019: {
    serialNumber: 3019,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3019\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1018: {
    serialNumber: 1018,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1018\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1029: {
    serialNumber: 1029,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1029\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1016: {
    serialNumber: 1016,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1016\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1138: {
    serialNumber: 1138,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1138\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1133: {
    serialNumber: 1133,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1133\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1135: {
    serialNumber: 1135,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1135\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2033: {
    serialNumber: 2033,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2033\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2029: {
    serialNumber: 2029,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2029\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2024: {
    serialNumber: 2024,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2024\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5636: {
    serialNumber: 5636,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5636\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5205: {
    serialNumber: 5205,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5205\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3034: {
    serialNumber: 3034,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3034\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3032: {
    serialNumber: 3032,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3032\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3031: {
    serialNumber: 3031,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3031\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1028: {
    serialNumber: 1028,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1028\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1027: {
    serialNumber: 1027,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1027\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1024: {
    serialNumber: 1024,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1024\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1145: {
    serialNumber: 1145,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1145\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1149: {
    serialNumber: 1149,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1149\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1156: {
    serialNumber: 1156,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1156\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2020: {
    serialNumber: 2020,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2020\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2022: {
    serialNumber: 2022,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2022\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2026: {
    serialNumber: 2026,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2026\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5152: {
    serialNumber: 5152,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5152\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5165: {
    serialNumber: 5165,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5165\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3024: {
    serialNumber: 3024,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3024\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3016: {
    serialNumber: 3016,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3016\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3029: {
    serialNumber: 3029,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3029\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1031: {
    serialNumber: 1031,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1031\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1026: {
    serialNumber: 1026,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1026\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1017: {
    serialNumber: 1017,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1017\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1151: {
    serialNumber: 1151,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1151\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1148: {
    serialNumber: 1148,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1148\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1163: {
    serialNumber: 1163,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1163\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2034: {
    serialNumber: 2034,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2034\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2031: {
    serialNumber: 2031,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2031\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2023: {
    serialNumber: 2023,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2023\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5699: {
    serialNumber: 5699,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5699\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5623: {
    serialNumber: 5623,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5623\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3033: {
    serialNumber: 3033,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3033\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3025: {
    serialNumber: 3025,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3025\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3026: {
    serialNumber: 3026,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3026\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1019: {
    serialNumber: 1019,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1019\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1023: {
    serialNumber: 1023,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1023\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1020: {
    serialNumber: 1020,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1020\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1162: {
    serialNumber: 1162,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1162\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1154: {
    serialNumber: 1154,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1154\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1157: {
    serialNumber: 1157,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1157\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2032: {
    serialNumber: 2032,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2032\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2021: {
    serialNumber: 2021,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2021\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2036: {
    serialNumber: 2036,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2036\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5621: {
    serialNumber: 5621,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5621\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5624: {
    serialNumber: 5624,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5624\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3021: {
    serialNumber: 3021,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3021\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3041: {
    serialNumber: 3041,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3041\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3042: {
    serialNumber: 3042,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3042\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1033: {
    serialNumber: 1033,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1033\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1043: {
    serialNumber: 1043,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1043\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1037: {
    serialNumber: 1037,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1037\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1159: {
    serialNumber: 1159,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1159\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1161: {
    serialNumber: 1161,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1161\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1175: {
    serialNumber: 1175,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1175\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2037: {
    serialNumber: 2037,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2037\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2027: {
    serialNumber: 2027,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2027\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2041: {
    serialNumber: 2041,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2041\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5622: {
    serialNumber: 5622,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5622\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5608: {
    serialNumber: 5608,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5608\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3045: {
    serialNumber: 3045,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3045\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3047: {
    serialNumber: 3047,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3047\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3037: {
    serialNumber: 3037,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3037\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1047: {
    serialNumber: 1047,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1047\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1040: {
    serialNumber: 1040,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1040\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1035: {
    serialNumber: 1035,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1035\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1177: {
    serialNumber: 1177,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1177\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1170: {
    serialNumber: 1170,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1170\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1169: {
    serialNumber: 1169,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1169\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2051: {
    serialNumber: 2051,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2051\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2046: {
    serialNumber: 2046,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2046\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2045: {
    serialNumber: 2045,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2045\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5607: {
    serialNumber: 5607,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5607\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5605: {
    serialNumber: 5605,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5605\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3036: {
    serialNumber: 3036,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3036\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3044: {
    serialNumber: 3044,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3044\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3050: {
    serialNumber: 3050,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3050\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1036: {
    serialNumber: 1036,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1036\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1038: {
    serialNumber: 1038,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1038\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1032: {
    serialNumber: 1032,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1032\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1172: {
    serialNumber: 1172,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1172\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1165: {
    serialNumber: 1165,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1165\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1173: {
    serialNumber: 1173,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1173\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2048: {
    serialNumber: 2048,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2048\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2043: {
    serialNumber: 2043,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2043\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2040: {
    serialNumber: 2040,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2040\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5606: {
    serialNumber: 5606,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5606\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5609: {
    serialNumber: 5609,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5609\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3039: {
    serialNumber: 3039,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3039\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3048: {
    serialNumber: 3048,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3048\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3049: {
    serialNumber: 3049,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3049\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1045: {
    serialNumber: 1045,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1045\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1041: {
    serialNumber: 1041,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1041\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1042: {
    serialNumber: 1042,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1042\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1185: {
    serialNumber: 1185,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1185\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1189: {
    serialNumber: 1189,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1189\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1188: {
    serialNumber: 1188,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1188\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2058: {
    serialNumber: 2058,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2058\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2049: {
    serialNumber: 2049,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2049\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2053: {
    serialNumber: 2053,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2053\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5293: {
    serialNumber: 5293,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5293\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5292: {
    serialNumber: 5292,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5292\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3052: {
    serialNumber: 3052,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3052\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3054: {
    serialNumber: 3054,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3054\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3051: {
    serialNumber: 3051,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3051\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1034: {
    serialNumber: 1034,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1034\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1039: {
    serialNumber: 1039,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1039\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1046: {
    serialNumber: 1046,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1046\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1183: {
    serialNumber: 1183,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1183\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1181: {
    serialNumber: 1181,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1181\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1186: {
    serialNumber: 1186,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1186\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2050: {
    serialNumber: 2050,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2050\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2052: {
    serialNumber: 2052,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2052\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2047: {
    serialNumber: 2047,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2047\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5297: {
    serialNumber: 5297,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5297\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5296: {
    serialNumber: 5296,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5296\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3038: {
    serialNumber: 3038,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3038\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3043: {
    serialNumber: 3043,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3043\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3040: {
    serialNumber: 3040,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3040\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1048: {
    serialNumber: 1048,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1048\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1063: {
    serialNumber: 1063,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1063\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1058: {
    serialNumber: 1058,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1058\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1211: {
    serialNumber: 1211,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1211\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1207: {
    serialNumber: 1207,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1207\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1206: {
    serialNumber: 1206,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1206\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2059: {
    serialNumber: 2059,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2059\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2056: {
    serialNumber: 2056,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2056\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2044: {
    serialNumber: 2044,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2044\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5295: {
    serialNumber: 5295,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5295\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5298: {
    serialNumber: 5298,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5298\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3053: {
    serialNumber: 3053,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3053\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3066: {
    serialNumber: 3066,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3066\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3059: {
    serialNumber: 3059,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3059\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1060: {
    serialNumber: 1060,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1060\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1062: {
    serialNumber: 1062,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1062\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1057: {
    serialNumber: 1057,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1057\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1204: {
    serialNumber: 1204,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1204\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1209: {
    serialNumber: 1209,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1209\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1199: {
    serialNumber: 1199,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1199\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2057: {
    serialNumber: 2057,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2057\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2055: {
    serialNumber: 2055,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2055\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2042: {
    serialNumber: 2042,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2042\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5299: {
    serialNumber: 5299,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5299\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5628: {
    serialNumber: 5628,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5628\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3073: {
    serialNumber: 3073,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3073\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3060: {
    serialNumber: 3060,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3060\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3067: {
    serialNumber: 3067,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3067\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1054: {
    serialNumber: 1054,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1054\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1059: {
    serialNumber: 1059,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1059\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1055: {
    serialNumber: 1055,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1055\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1197: {
    serialNumber: 1197,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1197\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1201: {
    serialNumber: 1201,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1201\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1205: {
    serialNumber: 1205,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1205\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2064: {
    serialNumber: 2064,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2064\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2074: {
    serialNumber: 2074,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2074\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2069: {
    serialNumber: 2069,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2069\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5627: {
    serialNumber: 5627,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5627\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5629: {
    serialNumber: 5629,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5629\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3072: {
    serialNumber: 3072,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3072\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3069: {
    serialNumber: 3069,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3069\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3070: {
    serialNumber: 3070,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3070\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1061: {
    serialNumber: 1061,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1061\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1053: {
    serialNumber: 1053,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1053\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1052: {
    serialNumber: 1052,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1052\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1202: {
    serialNumber: 1202,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1202\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1217: {
    serialNumber: 1217,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1217\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1223: {
    serialNumber: 1223,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1223\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2078: {
    serialNumber: 2078,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2078\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2067: {
    serialNumber: 2067,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2067\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2066: {
    serialNumber: 2066,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2066\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5626: {
    serialNumber: 5626,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5626\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5625: {
    serialNumber: 5625,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5625\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3071: {
    serialNumber: 3071,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3071\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3055: {
    serialNumber: 3055,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3055\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3058: {
    serialNumber: 3058,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3058\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1051: {
    serialNumber: 1051,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1051\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1049: {
    serialNumber: 1049,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1049\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1056: {
    serialNumber: 1056,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1056\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1220: {
    serialNumber: 1220,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1220\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1225: {
    serialNumber: 1225,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1225\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1216: {
    serialNumber: 1216,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1216\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2075: {
    serialNumber: 2075,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2075\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2077: {
    serialNumber: 2077,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2077\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2072: {
    serialNumber: 2072,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2072\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5615: {
    serialNumber: 5615,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5615\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5618: {
    serialNumber: 5618,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5618\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3074: {
    serialNumber: 3074,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3074\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3064: {
    serialNumber: 3064,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3064\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3065: {
    serialNumber: 3065,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3065\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1079: {
    serialNumber: 1079,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1079\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1075: {
    serialNumber: 1075,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1075\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1074: {
    serialNumber: 1074,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1074\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1221: {
    serialNumber: 1221,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1221\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1213: {
    serialNumber: 1213,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1213\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1219: {
    serialNumber: 1219,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1219\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2079: {
    serialNumber: 2079,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2079\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2071: {
    serialNumber: 2071,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2071\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2063: {
    serialNumber: 2063,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2063\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5617: {
    serialNumber: 5617,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5617\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5619: {
    serialNumber: 5619,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5619\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3056: {
    serialNumber: 3056,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3056\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3057: {
    serialNumber: 3057,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3057\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3061: {
    serialNumber: 3061,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3061\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1070: {
    serialNumber: 1070,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1070\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1072: {
    serialNumber: 1072,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1072\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1069: {
    serialNumber: 1069,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1069\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1215: {
    serialNumber: 1215,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1215\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1218: {
    serialNumber: 1218,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1218\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1227: {
    serialNumber: 1227,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1227\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2090: {
    serialNumber: 2090,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2090\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2073: {
    serialNumber: 2073,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2073\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2082: {
    serialNumber: 2082,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2082\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5616: {
    serialNumber: 5616,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5616\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5612: {
    serialNumber: 5612,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5612\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3091: {
    serialNumber: 3091,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3091\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3087: {
    serialNumber: 3087,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3087\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3089: {
    serialNumber: 3089,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3089\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1066: {
    serialNumber: 1066,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1066\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1071: {
    serialNumber: 1071,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1071\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1078: {
    serialNumber: 1078,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1078\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1239: {
    serialNumber: 1239,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1239\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1231: {
    serialNumber: 1231,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1231\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1237: {
    serialNumber: 1237,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1237\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2084: {
    serialNumber: 2084,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2084\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2085: {
    serialNumber: 2085,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2085\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2086: {
    serialNumber: 2086,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2086\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5610: {
    serialNumber: 5610,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5610\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5276: {
    serialNumber: 5276,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5276\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3092: {
    serialNumber: 3092,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3092\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3090: {
    serialNumber: 3090,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3090\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3093: {
    serialNumber: 3093,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3093\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1065: {
    serialNumber: 1065,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1065\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1064: {
    serialNumber: 1064,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1064\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1073: {
    serialNumber: 1073,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1073\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1243: {
    serialNumber: 1243,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1243\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1233: {
    serialNumber: 1233,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1233\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1241: {
    serialNumber: 1241,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1241\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2088: {
    serialNumber: 2088,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2088\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2093: {
    serialNumber: 2093,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2093\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2091: {
    serialNumber: 2091,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2091\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5281: {
    serialNumber: 5281,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5281\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5171: {
    serialNumber: 5171,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5171\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3086: {
    serialNumber: 3086,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3086\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3097: {
    serialNumber: 3097,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3097\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3099: {
    serialNumber: 3099,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3099\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1068: {
    serialNumber: 1068,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1068\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1067: {
    serialNumber: 1067,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1067\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1076: {
    serialNumber: 1076,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1076\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1229: {
    serialNumber: 1229,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1229\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1235: {
    serialNumber: 1235,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1235\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1238: {
    serialNumber: 1238,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1238\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2087: {
    serialNumber: 2087,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2087\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2080: {
    serialNumber: 2080,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2080\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2089: {
    serialNumber: 2089,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2089\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5287: {
    serialNumber: 5287,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5287\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5145: {
    serialNumber: 5145,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5145\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3096: {
    serialNumber: 3096,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3096\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3100: {
    serialNumber: 3100,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3100\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3102: {
    serialNumber: 3102,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3102\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1077: {
    serialNumber: 1077,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1077\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1083: {
    serialNumber: 1083,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1083\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1091: {
    serialNumber: 1091,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1091\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1234: {
    serialNumber: 1234,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1234\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1249: {
    serialNumber: 1249,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1249\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1247: {
    serialNumber: 1247,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1247\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2083: {
    serialNumber: 2083,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2083\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2099: {
    serialNumber: 2099,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2099\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2095: {
    serialNumber: 2095,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2095\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5253: {
    serialNumber: 5253,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5253\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5282: {
    serialNumber: 5282,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5282\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  3101: {
    serialNumber: 3101,
    title:
      "Roz & Ali \u5587\u53ed\u8896\u82b1\u5349\u6ce1\u6ce1\u4e0b\u6446\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3101\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Flare Slee.jpg`,
  },
  3098: {
    serialNumber: 3098,
    title: "Roz & Ali \u6ce1\u6ce1\u8896\u82b1\u5349\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3098\n\u4f5c\u54c1: 95% \u6da4\u7eb6, 5% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Puff Sleev.jpg`,
  },
  3095: {
    serialNumber: 3095,
    title: "Roz & Ali \u94fe\u6761\u9970\u8fb9\u98d8\u9038\u8896\u4e0a\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 3095\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Chain Trim.jpg`,
  },
  1087: {
    serialNumber: 1087,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1087\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1088: {
    serialNumber: 1088,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1088\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1081: {
    serialNumber: 1081,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1081\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1255: {
    serialNumber: 1255,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1255\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1245: {
    serialNumber: 1245,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1245\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1257: {
    serialNumber: 1257,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1257\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2094: {
    serialNumber: 2094,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2094\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2092: {
    serialNumber: 2092,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2092\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2098: {
    serialNumber: 2098,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2098\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5173: {
    serialNumber: 5173,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5173\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5286: {
    serialNumber: 5286,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5286\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1095: {
    serialNumber: 1095,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1095\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1084: {
    serialNumber: 1084,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1084\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1090: {
    serialNumber: 1090,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1090\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1252: {
    serialNumber: 1252,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1252\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1248: {
    serialNumber: 1248,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1248\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1259: {
    serialNumber: 1259,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1259\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2109: {
    serialNumber: 2109,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2109\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2106: {
    serialNumber: 2106,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2106\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2111: {
    serialNumber: 2111,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2111\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5153: {
    serialNumber: 5153,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5153\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5285: {
    serialNumber: 5285,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5285\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1086: {
    serialNumber: 1086,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1086\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1089: {
    serialNumber: 1089,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1089\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1094: {
    serialNumber: 1094,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1094\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1253: {
    serialNumber: 1253,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1253\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1250: {
    serialNumber: 1250,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1250\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1147: {
    serialNumber: 1147,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1147\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2117: {
    serialNumber: 2117,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2117\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2102: {
    serialNumber: 2102,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2102\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2100: {
    serialNumber: 2100,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2100\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5262: {
    serialNumber: 5262,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5262\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5150: {
    serialNumber: 5150,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5150\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1085: {
    serialNumber: 1085,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1085\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1092: {
    serialNumber: 1092,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1092\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1082: {
    serialNumber: 1082,
    title: "\u6469\u6d1b\u54e5\u82b1\u5349 Veronica \u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1082\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Moroccan Floral Ver.jpg`,
  },
  1158: {
    serialNumber: 1158,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1158\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1153: {
    serialNumber: 1153,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1153\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1152: {
    serialNumber: 1152,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1152\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2118: {
    serialNumber: 2118,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2118\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2107: {
    serialNumber: 2107,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2107\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2115: {
    serialNumber: 2115,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2115\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5264: {
    serialNumber: 5264,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5264\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5132: {
    serialNumber: 5132,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5132\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
  1093: {
    serialNumber: 1093,
    title: "Odessa \u523a\u7ee3\u5206\u5c42\u5a03\u5a03\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1093\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Odessa Embroidered .jpg`,
  },
  1080: {
    serialNumber: 1080,
    title: "Luna Coral \u82b1\u5349\u4e0b\u6446\u8fde\u8863\u88d9",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1080\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Luna Coral Floral H.jpg`,
  },
  1190: {
    serialNumber: 1190,
    title: "H Halston \u8936\u88e5\u6b63\u9762\u4e5d\u5206\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1190\n\u4f5c\u54c1: 52% \u4eba\u9020\u4e1d\uff0c48% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack H Halston Pleat Fro.jpg`,
  },
  1146: {
    serialNumber: 1146,
    title: "Roz & Ali \u4fee\u8eab\u9614\u817f\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1146\n\u4f5c\u54c1: 63% \u6da4\u7eb6\u300143% \u5c3c\u9f99\u30014% \u6c28\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Roz  Ali Secret Age.jpg`,
  },
  1160: {
    serialNumber: 1160,
    title: "Westport \u4e94\u53e3\u888b\u76f4\u7b52\u725b\u4ed4\u88e4",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1160\n\u4f5c\u54c1: 72% \u68c9\uff0c24% \u6da4\u7eb6\uff0c3% \u5176\u4ed6\uff0c1% \u83b1\u5361\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Westport Signature .jpg`,
  },
  2114: {
    serialNumber: 2114,
    title: "\u7f57\u7eb9\u8fb9\u9970\u82b1\u5349\u98de\u884c\u5458\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2114\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Ribbed Trim Floral .jpg`,
  },
  2110: {
    serialNumber: 2110,
    title: "\u62fc\u8272\u8fd0\u52a8\u98ce\u8863\u5916\u5957",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2110\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Color Block Sporty .jpg`,
  },
  2113: {
    serialNumber: 2113,
    title: "\u62c9\u94fe\u5c3c\u9f99\u5939\u514b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 2113\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Zipper Nylon Anorak.jpg`,
  },
  5273: {
    serialNumber: 5273,
    title: "\u53ef\u7231\u5957\u5934\u886b",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5273\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/samplepack Lovely Pullover.jpg`,
  },
  5274: {
    serialNumber: 5274,
    title: "Madison & Hudson \u6761\u7eb9\u5f00\u886b\u6bdb\u8863",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 5274\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/samplepack Madison  Hudson Str.jpg`,
  },
};

export const rootsProducts = {
  7: {
    title: "Edie Tote Tribe",
    subtitle: "ID# 758200",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    serialNumber: 7,
  },
  18: {
    title: "Edie Tote Tribe",
    subtitle: "ID# 182840",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    serialNumber: 18,
  },
  28: {
    title: "Edie Tote Tribe",
    subtitle: "ID# 282278",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    serialNumber: 28,
  },
};

export const rootsNewProducts = {
  4: {
    serialNumber: 4,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 4\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  5: {
    serialNumber: 5,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 5\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  7: {
    serialNumber: 7,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 7\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  8: {
    serialNumber: 8,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 8\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  9: {
    serialNumber: 9,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 9\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  10: {
    serialNumber: 10,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 10\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  12: {
    serialNumber: 12,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 12\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  13: {
    serialNumber: 13,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 13\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  14: {
    serialNumber: 14,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 14\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  15: {
    serialNumber: 15,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 15\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  16: {
    serialNumber: 16,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 16\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  17: {
    serialNumber: 17,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 17\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  18: {
    serialNumber: 18,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 18\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  19: {
    serialNumber: 19,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 19\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  20: {
    serialNumber: 20,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 20\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  21: {
    serialNumber: 21,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 21\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  22: {
    serialNumber: 22,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 22\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  23: {
    serialNumber: 23,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 23\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  24: {
    serialNumber: 24,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 24\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  25: {
    serialNumber: 25,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 25\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  26: {
    serialNumber: 26,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 26\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  27: {
    serialNumber: 27,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 27\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  30: {
    serialNumber: 30,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 30\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  31: {
    serialNumber: 31,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 31\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  32: {
    serialNumber: 32,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 32\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  33: {
    serialNumber: 33,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 33\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  34: {
    serialNumber: 34,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 34\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  35: {
    serialNumber: 35,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 35\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  36: {
    serialNumber: 36,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 36\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  37: {
    serialNumber: 37,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 37\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  1: {
    serialNumber: 1,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 1\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  2: {
    serialNumber: 2,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 2\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  3: {
    serialNumber: 3,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 3\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  6: {
    serialNumber: 6,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 6\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  11: {
    serialNumber: 11,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 11\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  28: {
    serialNumber: 28,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 28\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  29: {
    serialNumber: 29,
    title: "Christie Crossbody Cervino",
    subtitle: "Product ID: 29\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/christie-crossbody-cervino-56010460.html?selectedColor=A35&cgid=LeatherLeatherBags&start=14&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Christie Crossbo.jpg`,
  },
  38: {
    serialNumber: 38,
    title: "Banff Tote Tribe",
    subtitle: "Product ID: 38\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/banff-tote-tribe-56010453.html?selectedColor=Y21&cgid=LeatherLeatherBags&start=9&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Banff Tote Tribe.jpg`,
  },
  39: {
    serialNumber: 39,
    title: "Medium Zip Pouch Cervino",
    subtitle: "Product ID: 39\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/medium-zip-pouch-cervino-53396167.html?cgid=56010458&selectedColor=001&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Medium Zip Pouch.jpg`,
  },
  44: {
    serialNumber: 44,
    title: "Andie Bag 2.0 Cervino",
    subtitle: "Product ID: 44\nComposition: Leather\nMade In: Canada",
    link: "https://www.roots.com/ca/en/andie-bag-2.0-cervino-56010458.html?selectedColor=001&cgid=LeatherLeatherBags&start=2&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/auto_rootsnew Andie Bag 2.0 Ce.jpg`,
  },
};

export const unismart_products = {
  1000: {
    serialNumber: 1000,
    title: "Edie Tote Tribe",
    subtitle: "ID# 158328",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1001: {
    serialNumber: 1001,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000041",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1002: {
    serialNumber: 1002,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000039",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1003: {
    serialNumber: 1003,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000038",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1004: {
    serialNumber: 1004,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000037",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1005: {
    serialNumber: 1005,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000026",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1006: {
    serialNumber: 1006,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000025",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1007: {
    serialNumber: 1007,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000016",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1008: {
    serialNumber: 1008,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000018",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1009: {
    serialNumber: 1009,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000015",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1010: {
    serialNumber: 1010,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000028",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1011: {
    serialNumber: 1011,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000055",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1012: {
    serialNumber: 1012,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000042",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1013: {
    serialNumber: 1013,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000009",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1014: {
    serialNumber: 1014,
    title: "Edie Tote Tribe",
    subtitle: "ID# 364258",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1015: {
    serialNumber: 1015,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000010",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1016: {
    serialNumber: 1016,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000011",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1017: {
    serialNumber: 1017,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000012",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1018: {
    serialNumber: 1018,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000013",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1019: {
    serialNumber: 1019,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000017",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1020: {
    serialNumber: 1020,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000020",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1021: {
    serialNumber: 1021,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000021",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1022: {
    serialNumber: 1022,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000029",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1023: {
    serialNumber: 1023,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000030",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1024: {
    serialNumber: 1024,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000031",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1025: {
    serialNumber: 1025,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000032",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1026: {
    serialNumber: 1026,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000033",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1027: {
    serialNumber: 1027,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000043",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1028: {
    serialNumber: 1028,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000049",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1029: {
    serialNumber: 1029,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000051",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1030: {
    serialNumber: 1030,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000052",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1031: {
    serialNumber: 1031,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000053",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1032: {
    serialNumber: 1032,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000054",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1033: {
    serialNumber: 1033,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000057",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1034: {
    serialNumber: 1034,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000058",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1035: {
    serialNumber: 1035,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000059",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1036: {
    serialNumber: 1036,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000050",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1037: {
    serialNumber: 1037,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000060",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1038: {
    serialNumber: 1038,
    title: "Edie Tote Tribe",
    subtitle: "ID# 939491",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  1039: {
    serialNumber: 1039,
    title: "Edie Tote Tribe",
    subtitle: "ID# 772406",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
  32767: {
    serialNumber: 32767,
    title: "Edie Tote Tribe",
    subtitle: "ID# 000007",
    link: "https://www.roots.com/ca/en/edie-tote-tribe-56010278.html?selectedColor=Y21&cgid=LeatherHandbags&start=12&itemsourse=productlist",
    image: `${process.env.PUBLIC_URL}/roots-bag.png`,
  },
};

export const velocityProducts = {
  20: {
    serialNumber: 2039485,
    externalLink: "https://mtag.io/p120379425",
  },
  26: {
    serialNumber: 268923,
    externalLink: "https://mtag.io/p120379979",
  },
  33: {
    serialNumber: 3386059,
    externalLink: "https://mtag.io/p120379995",
  },
  10: {
    serialNumber: 10804849,
    externalLink: "https://mtag.io/p120379979",
  },
  200: {
    serialNumber: 20098893,
    externalLink: "https://mtag.io/p120379995",
  },
};

export const isikProducts = {
  1: {
    title: "Product is protected by Invisicode™",
    subtitle: "Click on the link below to learn more:",
    image: `${process.env.PUBLIC_URL}/arylla_icon_med.png`,
    link: "https://www.arylla.com",
  },
};

export const hkProducts = {
  31000: {
    serialNumber: "129406-20",
  },
  1000: { serialNumber: "129406-1" },
  19000: { serialNumber: "129406-14" },
  27000: { serialNumber: "129406-18" },
  13000: { serialNumber: "129406-11" },
  3000: { serialNumber: "129406-3" },
  255: {
    serialNumber: "PO 51879",
  },
  304: {
    serialNumber: "PO 51880",
  },
  308: {
    serialNumber: "PO 51881",
  },
  313: {
    serialNumber: "PO 51882",
  },
  316: {
    serialNumber: "PO 51883",
  },
  318: {
    serialNumber: "PO 51884",
  },
  337: {
    serialNumber: "PO 51885",
  },
  340: {
    serialNumber: "PO 51886",
  },
  362: {
    serialNumber: "PO 51887",
  },
  376: {
    serialNumber: "PO 51888",
  },
  388: {
    serialNumber: "PO 51889",
  },
  390: {
    serialNumber: "PO 51890",
  },
  392: {
    serialNumber: "PO 51891",
  },
  424: {
    serialNumber: "PO 51892",
  },
  441: {
    serialNumber: "PO 51893",
  },
  443: {
    serialNumber: "PO 51894",
  },
  444: {
    serialNumber: "PO 51895",
  },
  459: {
    serialNumber: "PO 51896",
  },
  471: {
    serialNumber: "PO 51897",
  },
  477: {
    serialNumber: "PO 51898",
  },
  480: {
    serialNumber: "PO 51899",
  },
  491: {
    serialNumber: "PO 51900",
  },
  492: {
    serialNumber: "PO 51901",
  },
  1004: {
    serialNumber: "PO 51902",
  },
  1029: {
    serialNumber: "PO 51903",
  },
  1032: {
    serialNumber: "PO 51904",
  },
  1061: {
    serialNumber: "PO 51905",
  },
  1062: {
    serialNumber: "PO 51906",
  },
  1074: {
    serialNumber: "PO 51907",
  },
  1085: {
    serialNumber: "PO 51908",
  },
  1165: {
    serialNumber: "PO 51909",
  },
  1189: {
    serialNumber: "PO 51910",
  },
  1240: {
    serialNumber: "PO 51911",
  },
  1341: {
    serialNumber: "PO 51912",
  },
  1344: {
    serialNumber: "PO 51913",
  },
  1350: {
    serialNumber: "PO 51914",
  },
  1355: {
    serialNumber: "PO 51915",
  },
  1364: {
    serialNumber: "PO 51916",
  },
  1392: {
    serialNumber: "PO 51917",
  },
  1413: { serialNumber: "PO 51918" },
  1440: { serialNumber: "PO 51919" },
  1443: { serialNumber: "PO 51920" },
};

export const wechat_products = {
  4047: {
    serialNumber: 4047,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 4047\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Roz  Ali Flare Sleeve F.jpg`,
  },
  4053: {
    serialNumber: 4053,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 4053\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Roz  Ali Puff Sleeve Fl.jpg`,
  },
  4054: {
    serialNumber: 4054,
    title: "Roz & Ali Chain Trim Flutter Sleeve Blouse",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 4054\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Roz  Ali Chain Trim Flu.jpg`,
  },
  1377: {
    serialNumber: 1377,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1377\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Metallic Puffer Hoodie .jpg`,
  },
  1391: {
    serialNumber: 1391,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1391\n\u4f5c\u54c1: 100% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Dolman Sleeve Color Blo.jpg`,
  },
  1392: {
    serialNumber: 1392,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 1392\n\u4f5c\u54c1: 55% \u68c9\uff0c45% \u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat International Wool Like.jpg`,
  },
  6043: {
    serialNumber: 6043,
    title: "Jordan MA2",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 6043\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Jordan MA2.png`,
  },
  6048: {
    serialNumber: 6048,
    title: "Jordan Pro Strong",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 6048\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Jordan Pro Strong.png`,
  },
  6049: {
    serialNumber: 6049,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 6049\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Ribbed Trim Floral Bomb.jpg`,
  },
  151572: {
    serialNumber: 151572,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 151572\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Color Block Sporty Wind.jpg`,
  },
  151569: {
    serialNumber: 151569,
    title: "Zipper Nylon Anorak Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 151569\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Zipper Nylon Anorak Jac.jpg`,
  },
  112425: {
    serialNumber: 112425,
    title: "Lovely Pullover",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 112425\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Lovely Pullover.jpg`,
  },
  131790: {
    serialNumber: 131790,
    title: "Lovely Pullover",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 112425\n\u4f5c\u54c1: 52% \u68c9\uff0c28% \u6da4\u7eb6\uff0c20% \u4eba\u9020\u4e1d\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Lovely Pullover.jpg`,
  },
  6067: {
    serialNumber: 6067,
    title: "Roz & Ali Flare Sleeve Floral Bubble Hem Blouse",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 6067\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Roz  Ali Flare Sleeve F.jpg`,
  },
  6065: {
    serialNumber: 6065,
    title: "Roz & Ali Puff Sleeve Floral Blouse - Misses",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 4053\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Roz  Ali Puff Sleeve Fl.jpg`,
  },
  51251: {
    serialNumber: 51251,
    title: "Metallic Puffer Hoodie Coat",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 51251\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Metallic Puffer Hoodie .jpg`,
  },
  51252: {
    serialNumber: 51252,
    title: "Dolman Sleeve Color Block Nylon Anorak",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 51252\n\u4f5c\u54c1: 100% \u5c3c\u9f99\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Dolman Sleeve Color Blo.jpg`,
  },
  51337: {
    serialNumber: 51337,
    title: "International Wool Like Plaid Crop Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 51337\n\u4f5c\u54c1: 55% \u68c9\uff0c45% \u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat International Wool Like.jpg`,
  },
  51338: {
    serialNumber: 51338,
    title: "Ribbed Trim Floral Bomber Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 51338\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u585e\u73ed\u5c9b",
    image: `${process.env.PUBLIC_URL}/wechat Ribbed Trim Floral Bomb.jpg`,
  },
  51339: {
    serialNumber: 51339,
    title: "Color Block Sporty Windbreaker Jacket",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 51339\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u5b5f\u52a0\u62c9\u56fd",
    image: `${process.env.PUBLIC_URL}/wechat Color Block Sporty Wind.jpg`,
  },
  10018: {
    serialNumber: 10018,
    title: "Jordan MA2",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 10018\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Jordan MA2.png`,
  },
  10019: {
    serialNumber: 10019,
    title: "Jordan Pro Strong",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 10019\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/wechat Jordan Pro Strong.png`,
  },
  10020: {
    serialNumber: 10020,
    title: "Jordan Flight Club '91",
    subtitle:
      "\u4ea7\u54c1\u7f16\u53f7: 10020\n\u4f5c\u54c1: 100\uff05\u6da4\u7eb6\n\u5728\u5236\u4f5c: \u8d8a\u5357",
    image: `${process.env.PUBLIC_URL}/samplepack Jordan Flight Club .png`,
  },
};

export const wireframeProducts = {
  1: {
    serial: 1,
    title: "OFF WHITE 03 T-SHIRT",
    subtitle: "Valuation $150",
    image: `${process.env.PUBLIC_URL}/offwhite-tee.jpeg`,
    link: "https://www.arylla.com",
  },
};

export const test_products = {
  24459: {
    serialNumber: "PO 24459", // the serial number that is displayed
  },
};
