import { HTTP } from "Constants";

export const conditionOptions = ["New With Tags", "Like New", "Good", "Fair"];

export const singleRecommerceFetch = async (id, blueprint, endpoint) => {
  const response = await fetch(
    `${HTTP.RECOMMERCE_URL}/v1/${blueprint}/${endpoint}`,
    {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ id: id }),
    }
  );

  if (!response.ok) {
    const message = `Failed to POST ${blueprint} ${endpoint}: ${response.status}`;
    throw new Error(message);
  }

  return await response.json();
};

export const setupListing = async (id) => {
  const response = await fetch(`${HTTP.RECOMMERCE_URL}/v1/list_product/setup`, {
    method: "POST",
    headers: {
      Accept: "text/plain",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({ id: id }),
  });

  if (!response.ok) {
    const message = `Failed to POST setup listing: ${response.status}`;
    throw new Error(message);
  }
};

export const getUserLocation = async (id) => {
  const response = await fetch(`${HTTP.RECOMMERCE_URL}/v1/user_info/location`, {
    method: "POST",
    headers: {
      Accept: "text/plain",
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({ id: id }),
  });

  if (!response.ok) {
    const message = `Failed to POST to get user location: ${response.status}`;
    throw new Error(message);
  }
};

export const getProductAndPrice = async (id) => {
  await singleRecommerceFetch(id, "list_product", "product");
  await singleRecommerceFetch(id, "list_product", "pricing");
};

export const getContractInfo = async (id) => {
  //Call API to setup pricing and shipping info
  try {
    await Promise.all([
      fetch(`${HTTP.RECOMMERCE_URL}/v1/list_product/pricing`, {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({ id: id }),
      }),
      fetch(`${HTTP.RECOMMERCE_URL}/v1/list_product/ship/buy`, {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({ id: id }),
      }),
    ]);
  } catch {
    const message = `Failed to POST pricing, shipping timeline, and user info`;
    throw new Error(message);
  }

  //Get a pretty print from the contract call
  const contractResult = await fetch(
    `${HTTP.RECOMMERCE_URL}/v1/list_product/contract`,
    {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ id: id }),
    }
  );
  return await contractResult.json();
};

export const listOnEbay = async (id) => {
  //Call API to stage ebay listing
  const stageResponse = await fetch(
    `${HTTP.RECOMMERCE_URL}/v1/list_product/ebay/stage`,
    {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ id: id }),
    }
  );

  if (!stageResponse.ok) {
    const message = `Failed to POST ebay stage: ${stageResponse.status}`;
    throw new Error(message);
  }

  //Call API to publish on ebay
  const publishResponse = await fetch(
    `${HTTP.RECOMMERCE_URL}/v1/list_product/ebay/publish`,
    {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ id: id }),
    }
  );

  if (!publishResponse.ok) {
    const message = `Failed to POST ebay publish: ${publishResponse.status}`;
    throw new Error(message);
  }

  return await publishResponse.json();
};
