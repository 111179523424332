import React from "react";
import "./AryllaSpinner.scss";

class AryllaSpinner extends React.Component {
  render() {
    return (
      <>
        <label className="spinner"></label>
      </>
    );
  }
}

export default AryllaSpinner;
