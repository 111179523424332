import React from "react";

import "./Scan.scss";
import { CustomHeader, Scanner } from "SharedComponents";
import { withRouter } from "react-router";

class Scan extends React.Component {
  componentDidMount() {
    document.title = "Arylla Demo";
  }

  render() {
    return (
      <div className="scan-page">
        <CustomHeader title="Arylla Scan" />
        <Scanner
          algorithm={this.props.match.params.algorithm}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(Scan);
