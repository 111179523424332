import React from "react";
import I18n from "I18n";
import "./CustomHeader.scss";

class CustomHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  render() {
    const { title, logo, enableAndroidHeader } = this.props;
    const androidHeaderClassName = enableAndroidHeader ? "is-android" : "";
    return (
      <React.Fragment>
        <header className={`${androidHeaderClassName}`}>
          <div className="logo-container">
            <img
              alt={I18n.alt.logo}
              src={`${
                logo
                  ? process.env.PUBLIC_URL + "/" + logo
                  : process.env.PUBLIC_URL + "/logo_dark.png"
              }`}
            />
          </div>
          <div className="title-container">
            <h3 className="title">
              <span>{title}</span>
            </h3>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default CustomHeader;
