export const algorithms = [
  {
    key: 'aryllatag_woven31_white_jm25',
    projectName: 'aryllatag_woven31_white_jm25',
    name: "Arylla Demo"
  },
  {
    key: 'aryllatag_paper31',
    projectName: 'aryllatag_paper31',
    name: "Arylla Demo"
  },
  {
    key: 'rp1',
    projectName: 'rp1',
    name: "R-Pac Demo"
  },
  {
    key: 'rp1_cooper_20',
    projectName: 'rp1_cooper_20',
    name: "R-Pac Demo"
  },
  // {
  //   key: 'aryllatag_ios_woven',
  //   projectName: 'aryllatag_woven15_optimize',
  //   name: "Aryllatag iOS Woven"
  // },
  // {
  //   key: 'aryllatag_ios_paper',
  //   projectName: 'aryllatag_paper15_200um_633',
  //   name: "Aryllatag iOS Paper"
  // },
  // {
  //   key: 'aryllatag_android_woven',
  //   projectName: 'aryllatag_woven15_optimize_samsung_s8_plus',
  //   name: "Aryllatag Android Woven"
  // },
  // {
  //   key: 'aryllatag_android_paper',
  //   projectName: 'aryllatag_paper15_200um_633_samsung_s8_plus',
  //   name: "Aryllatag Android Paper"
  // },
  {
    key: 'drfeelgood',
    projectName: 'drfeelgood',
    name: "Packaging Demo"
  }
]
