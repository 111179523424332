import React from "react";

import "./CadicaScan.scss";
import { Scanner, CustomHeader } from "SharedComponents";
import { PRODUCTS_CADICA } from "Constants";
import { withRouter } from "react-router";

class EmbeddedScan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      algorithm: "greyshorts_sparse1",
    };
  }

  componentDidMount() {
    document.title = "Cadica x Arylla";
  }

  render() {
    return (
      <div className="embedded-scan-page cadica">
        <CustomHeader logo={process.env.PUBLIC_URL + "/cadica-logo.svg"} />
        <Scanner
          algorithm={this.state.algorithm}
          products={PRODUCTS_CADICA}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(EmbeddedScan);
