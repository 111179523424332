import React from "react";

import "./MockWrite.scss";
import { CustomHeader, TagWriter } from "SharedComponents";
import { withRouter } from "react-router";

class Scan extends React.Component {
  componentDidMount() {
    document.title = "Arylla Demo";
  }

  render() {
    return (
      <div className="write-page">
        <CustomHeader title={"Write to tag"} />
        <TagWriter algorithm={"aryllatag_paper31"} url={this.props.match.url} />
      </div>
    );
  }
}

export default withRouter(Scan);
