import React from "react";

import I18n from "I18n";

class DisabledScanner extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="scan-container">
          <div className="instructions-container">
            <span className="step">
              {this.props.customText.disabledMessage
                ? this.props.customText.disabledMessage
                : I18n.disabledScan.instructions.disabledMessage}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DisabledScanner;
