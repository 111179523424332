export default {
  auto_samplepack: 0.35,
  auto_samplepack2: 0.35,
  auto_samplepack3: 0.35,
  auto_samplepack4: 0.35,
  ladybug_basic: 0.35,
  fake_demo: 0.85,
  jet_beer: 0.35,
  rc_red: 0.35,
  resalestamp: 0.35,
  resalestamp_red: 0.35,
  laava: 0.65,
  laava_13mm: 0.65,
  laava_16mm: 0.65,
  laava_13mm_static: 0.35,
  laava_13mm_imc_static: 0.35,
};
