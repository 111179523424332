import React from "react";

import "./HKScan.scss";
import { CustomHeader, Scanner } from "SharedComponents";
import { PRODUCTS_HK } from "Constants";
import { withRouter } from "react-router";

class HKScan extends React.Component {
  componentDidMount() {
    // document.title = "Isik x Arylla Demo";
  }

  render() {
    console.log(this.props.match);
    return (
      <div className="scan-page">
        <CustomHeader title={"Arylla Demo"} />
        <Scanner
          algorithm={this.props.match.params.algorithm}
          products={PRODUCTS_HK}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(HKScan);
