import React from "react";

import "./RootsScan.scss";
import { Scanner, CustomHeader } from "SharedComponents";
import { PRODUCTS_ROOTS } from "Constants";
import { withRouter } from "react-router";

class RootsScan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      algorithm: "greyshorts_sparse1",
    };
  }

  componentDidMount() {
    document.title = "Roots x Arylla";
  }

  render() {
    return (
      <div className="embedded-scan-page roots">
        <CustomHeader logo={process.env.PUBLIC_URL + "/roots-logo.svg"} />
        <Scanner
          algorithm={this.state.algorithm}
          products={PRODUCTS_ROOTS}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(RootsScan);
