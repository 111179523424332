import {
  HTTP,
  TEST_PRODUCTS,
  RIBKOFF_PRODUCTS,
  UNISMART_PRODUCTS,
  ARTFAN_PRODUCTS,
  ARCTERYX_PRODUCTS,
  AVERY_PRODUCTS,
  CHINESESECRET_PRODUCTS,
  JV_DAWNRAYS_PRODUCTS,
  JV_PFIZER_PRODUCTS,
  GOAT_PRODUCTS,
  LEVIS_PRODUCTS,
  PVH_PRODUCTS,
  REDCROSS_PRODUCTS,
  REDCROSS_PRODUCTS_EN,
  REDCROSS_PRODUCTS_FR,
  STAMP_PRODUCTS,
  ROADSHOW_PRODUCTS,
  RPAC_TRIAL_PRODUCTS,
  PRODUCTS_ISIK,
  PRODUCTS_ROOTSNEW,
  WECHAT_PRODUCTS,
  WIREFRAME_PRODUCTS,
  PRODUCTS_CADICA_GROUP,
} from "Constants";

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Scan from "Pages/Scan";
import EmbeddedScan from "Pages/EmbeddedScan";
import RpacScan from "Pages/RpacScan";
import GooseScan from "Pages/GooseScan";
import TimingTest from "Pages/TimingTest";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import NikeScan from "Pages/NikeScan";
import AotcScan from "Pages/AotcScan";
import CadicaScan from "Pages/CadicaScan";
import RootsScan from "Pages/RootsScan";
import MockRead from "Pages/MockRead";
import MockWrite from "Pages/MockWrite";
import VelocityScan from "Pages/VelocityScan";
import HKScan from "Pages/HKScan";
import CustomizableScan from "Pages/CustomizableScan";
import I18n from "./I18n";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/scan/:algorithm" component={Scan} />
        <Route exact path="/timing" component={TimingTest} />
        <Route exact path="/write" component={MockWrite} />
        <Route exact path="/read" component={MockRead} />
        <Route exact path="/embeddedscan/:algorithm" component={EmbeddedScan} />
        <Route exact path="/rpac/:algorithm" component={RpacScan} />
        <Route exact path="/goose/:algorithm" component={GooseScan} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/nike/:algorithm" component={NikeScan} />
        <Route exact path="/aotc/:algorithm" component={AotcScan} />
        <Route exact path="/cadica" component={CadicaScan} />
        <Route exact path="/roots" component={RootsScan} />
        <Route exact path="/velocity" component={VelocityScan} />
        <Route exact path="/hk/:algorithm" component={HKScan} />
        <Route
          exact
          path="/example"
          component={() => (
            <CustomizableScan
              headerTitle="Header title"
              algorithm={"aryllatag_woven31_white_jm25"}
              pageTitle={"Page title"}
              logoFileName={"example-logo.png"}
              customStyleClassName={"test-class"}
              products={TEST_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/read-write"
          component={() => (
            <CustomizableScan algorithm={"fake_demo"} readWrite={true} />
          )}
        />
        <Route
          exact
          path="/therealreal"
          component={() => (
            <CustomizableScan
              algorithm={"resalesticker_tlp-ps4_aryllacolor_laser"}
              customStyleClassName={"therealreal"}
              readWrite={true}
              logoFileName={"realreal-logo.png"}
            />
          )}
        />
        <Route
          exact
          path="/isik"
          component={() => (
            <CustomizableScan
              algorithm={"greyshorts_fake"}
              customStyleClassName={"isik"}
              products={PRODUCTS_ISIK}
              headerTitle="Isik x Arylla"
            />
          )}
        />
        <Route
          exact
          path="/arcteryx"
          component={() => (
            <CustomizableScan
              algorithm={"art.E11597"}
              customStyleClassName={"arcteryx"}
              logoFileName={"arcteryx-logo-horizontal.svg"}
              products={ARCTERYX_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/avery"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              logoFileName={"ad-stretch-logo.svg"}
              customStyleClassName={"avery"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/ad"
          component={() => (
            <CustomizableScan
              algorithm={"ad_flexo"}
              logoFileName={"ad-stretch-logo.svg"}
              customStyleClassName={"avery"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/19650"
          component={() => (
            <CustomizableScan
              algorithm={"ad_scan_mock"}
              logoFileName={"ad-stretch-logo.svg"}
              customStyleClassName={"avery"}
              products={AVERY_PRODUCTS}
              customText={{
                serial: "Bottle ID:",
              }}
            />
          )}
        />
        <Route
          exact
          path="/19993"
          component={() => (
            <CustomizableScan
              algorithm={"ad_scan_mock"}
              logoFileName={"ad-stretch-logo.svg"}
              customStyleClassName={"avery"}
              products={AVERY_PRODUCTS}
              customText={{
                serial: "Bottle ID:",
              }}
            />
          )}
        />
        <Route
          exact
          path="/bw"
          component={() => <CustomizableScan algorithm={"chinesesecret_bw"} />}
        />
        <Route
          exact
          path="/bw-basic"
          component={() => (
            <CustomizableScan
              algorithm={"chinesesecret_bw_basic"}
              customStyleClassName={"wireframe"}
            />
          )}
        />
        <Route
          exact
          path="/bw-link"
          component={() => (
            <CustomizableScan
              algorithm={"chinesesecret_bw"}
              customStyleClassName={"trove"}
              products={CHINESESECRET_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/bw-basic-link"
          component={() => (
            <CustomizableScan
              algorithm={"chinesesecret_bw_basic"}
              customStyleClassName={"arcteryx"}
              products={CHINESESECRET_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/dawnrays-basic"
          component={() => (
            <CustomizableScan
              algorithm={"ladybug_basic"}
              logoFileName={"jv-dawnrays-logo.png"}
              customStyleClassName={"jv-dawnrays-basic"}
              products={JV_DAWNRAYS_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/fasiculus"
          component={() => (
            <CustomizableScan wechat={true} customStyleClassName={"wechat"} />
          )}
        />
        <Route
          exact
          path="/jetsci"
          component={() => (
            <CustomizableScan
              algorithm={"jet_beer"}
              logoFileName={"jetsci-logo.png"}
              customStyleClassName={"jetsci"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/pfizer-basic"
          component={() => (
            <CustomizableScan
              algorithm={"ladybug_basic"}
              logoFileName={"jv-pfizer-logo.jpg"}
              customStyleClassName={"jv-pfizer-basic"}
              products={JV_PFIZER_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/laava"
          component={() => (
            <CustomizableScan algorithm={"laava"} laava={true} />
          )}
        />
        <Route
          exact
          path="/laava-13mm"
          component={() => (
            <CustomizableScan algorithm={"laava_13mm"} laava={true} />
          )}
        />
        <Route
          exact
          path="/laava-13mm-static"
          component={() => <CustomizableScan algorithm={"laava_13mm_static"} />}
        />
        <Route
          exact
          path="/levis"
          component={() => (
            <CustomizableScan
              algorithm={"auto"}
              customStyleClassName={"arylla"}
              products={LEVIS_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/lole"
          component={() => (
            <CustomizableScan
              algorithm={"auto_lole"}
              customStyleClassName={"lole"}
              logoFileName={"lole-logo.svg"}
              loadColor="#ffc72c"
              loadPathColor="white"
              customText={{
                step2: "2) Tap ⚡ to turn on the flash",
                step3: "3) Take a photo of the label to trade in",
              }}
              msrpPrice={true}
              dummyButton={"Approve Listing"}
            />
          )}
        />
        <Route
          exact
          path="/pvh"
          component={() => (
            <CustomizableScan
              algorithm={"auto_lole"}
              customStyleClassName={"lole"}
              logoFileName={"lole-logo.svg"}
              products={PVH_PRODUCTS}
              customText={{
                step3: "3) Take a photo of the label to trade in",
              }}
              hideAuthentic={true}
              dummyButton={"Approve Listing"}
            />
          )}
        />
        <Route
          exact
          path="/artfan"
          component={() => (
            <CustomizableScan
              algorithm={"artfan"}
              logoFileName={"artfan-logo.jpg"}
              customStyleClassName={"artfan"}
              products={ARTFAN_PRODUCTS}
              pageTitle={"ArtFan Authentication"}
              customText={{
                step1:
                  "1) Turn over the Certificate of Authenticity to reveal the Encrypted QR Code on the backside.",
                step2:
                  "2) With the ⚡ flash turned on, take a photo of the Encrypted QR Code using the camera of your mobile device.",
                step3: "3) Select ‘Use Photo’ to authenticate.",
                errorScanMessage:
                  "Please try to take the photo again. Try to avoid any overexposure.",
              }}
              favicon={"artfan-favicon-86x86.png"}
              disableDesktop={true}
              instructionsVideo={"photo-instructions-artfan.gif"}
            />
          )}
        />
        <Route
          exact
          path="/cadica-group"
          component={() => (
            <CustomizableScan
              algorithm={"greyshorts_sparse2"}
              logoFileName={"cadica-logo.svg"}
              customStyleClassName={"cadica-dark"}
              products={PRODUCTS_CADICA_GROUP}
            />
          )}
        />
        <Route
          exact
          path="/dlr"
          component={() => (
            <CustomizableScan
              algorithm={"al3_m20_c160_t90_dlr"}
              customStyleClassName={"dlr"}
              pageTitle={"DLR Print Trial"}
              headerTitle="DLR Print Trial"
              logoFileName={"dlr-logo.svg"}
              customText={{
                instructions: "How to use the web app:",
                step1: "1. Tap below to open the camera",
                step2: "2. Turn on the camera flash",
                step3: "3. Take a photo of the label",
              }}
            />
          )}
        />
        <Route
          exact
          path="/goat"
          component={() => (
            <CustomizableScan
              algorithm={"fake_demo"}
              logoFileName={"goat-logo.svg"}
              customStyleClassName={"goat"}
              products={GOAT_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/resale"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack"}
              customStyleClassName={"recommerce"}
              pageTitle={"Demo Resale App"}
              headerTitle="Demo Resale App"
              logoFileName={"rome-miko-logo.svg"}
              instructionsVideo={"photo-instructions-recommerce.gif"}
              hideAuthentic={true}
              customText={{
                errorScanMessage:
                  "Image processing error. Please try taking a photo with the camera flash turned on.",
              }}
            />
          )}
        />
        <Route
          exact
          path="/recommerce-fake"
          component={() => (
            <CustomizableScan
              algorithm={"fake_demo"}
              recommerce={true}
              customStyleClassName={"recommerce"}
              pageTitle={"Demo Resale App"}
              headerTitle="Demo Resale App"
              logoFileName={"rome-miko-logo.svg"}
              instructionsVideo={"photo-instructions-recommerce.gif"}
              hideAuthentic={true}
            />
          )}
        />
        <Route
          exact
          path="/redcross"
          component={() => (
            <CustomizableScan
              algorithm={"rc_red"}
              logoFileName={"redcross-logo-2.svg"}
              customStyleClassName={"redcross"}
              pageTitle={"RC - Respirator Authenticate"}
              favicon={"redcross-icon.svg"}
              products={REDCROSS_PRODUCTS}
              customText={{
                instructions: "How to use RC - Respirator Authenticate",
                step1: "1. Tap below to open the camera",
                step2: "2. Take a photo of the sticker with flash on",
                step3: '3. Tap "Use Photo" to authenticate',
              }}
            />
          )}
        />
        <Route
          exact
          path="/redcross-en"
          component={() => (
            <CustomizableScan
              algorithm={"rc_red"}
              logoFileName={"redcross-logo-2.svg"}
              customStyleClassName={"redcross"}
              pageTitle={"RC - Respirator Authenticate"}
              favicon={"redcross-icon.svg"}
              products={REDCROSS_PRODUCTS_EN}
              customText={{
                instructions: "How to use RC - Respirator Authenticate",
                step1: "1. Tap below to open the camera",
                step2: "2. Take a photo of the sticker with flash on",
                step3: '3. Tap "Use Photo" to authenticate',
              }}
              linkButton={{
                text: "Version Française",
                link: `${HTTP.SELF_URL_ANONYMOUS}/redcross-fr`,
              }}
            />
          )}
        />
        <Route
          exact
          path="/redcross-fr"
          component={() => (
            <CustomizableScan
              algorithm={"rc_red"}
              logoFileName={"redcross-logo-2.svg"}
              customStyleClassName={"redcross"}
              pageTitle={"RC - Respirator Authenticate"}
              favicon={"redcross-icon.svg"}
              products={REDCROSS_PRODUCTS_FR}
              customText={{
                authentic: "Authentique",
                result: "Résultat:",
                serial: "Numéro de série:",
                counterfeitScanTitle: "Impossible de vérifier.",
                counterfeitScanMessage:
                  "Ce produit n'a pas pu être vérifié comme authentique.",
                errorScanTitle: "L'analyse a échoué.",
                errorScanMessage:
                  "Erreur de traitement d'image. Veuillez essayer à nouveau.",
                scanButton: "Caméra",
                tutorial:
                  "Vous rencontrez des difficultés pour numériser?\nAppuyez ici pour la vidéo du didacticiel.",
                instructions: "Comment utiliser RC - Respirator Authenticate",
                step1: "1. Appuyez ci-dessous pour ouvrir l'appareil photo",
                step2:
                  "2. Prenez une photo de l'autocollant avec le flash allumé",
                step3:
                  '3. Appuyez sur "Utiliser la photo" pour vous authentifier',
              }}
              linkButton={{
                text: "English Version",
                link: `${HTTP.SELF_URL_ANONYMOUS}/redcross-en`,
              }}
            />
          )}
        />
        <Route
          exact
          path="/ribkoff"
          component={() => (
            <CustomizableScan
              algorithm={"greyshorts_sparse1"}
              logoFileName={"ribkoff-logo.png"}
              customStyleClassName={"ribkoff"}
              products={RIBKOFF_PRODUCTS}
            />
          )}
        />

        <Route
          exact
          path="/roadshow"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack"}
              customStyleClassName={"sample"}
              products={ROADSHOW_PRODUCTS}
              pageTitle={"演示应用"}
              headerTitle="演示应用"
              logoFileName={"rome-miko-logo.svg"}
              instructionsVideo={"photo-instructions-recommerce.gif"}
            />
          )}
        />
        <Route
          exact
          path="/rpac2"
          component={() => (
            <CustomizableScan
              algorithm={"al3_m20_c160_rp"}
              logoFileName={"rpac-logo.jpg"}
              customStyleClassName={"rpac-trial"}
              products={RPAC_TRIAL_PRODUCTS}
              headerTitle={"印刷试用"}
            />
          )}
        />
        <Route
          exact
          path="/rpac3"
          component={() => (
            <CustomizableScan
              algorithm={"laava_13mm_static_rp"}
              logoFileName={"rpac-logo.jpg"}
              customStyleClassName={"rpac-trial"}
              products={RPAC_TRIAL_PRODUCTS}
              headerTitle={"印刷试用"}
            />
          )}
        />
        <Route
          exact
          path="/luminescence"
          component={() => (
            <CustomizableScan
              algorithm={"alwayssunny"}
              customStyleClassName={"luminescence"}
              logoFileName={"luminescence-scs-icon.png"}
              pageTitle={"Authenticate Demo"}
              headerTitle={"Authenticate Demo"}
              favicon={"luminescence-scs-icon.png"}
            />
          )}
        />
        <Route
          exact
          path="/orion-a"
          component={() => (
            <CustomizableScan
              algorithm={"generic_tag_orion_beige"}
              customStyleClassName={"sample-video"}
              cloudProducts={true}
              pageTitle={"Orion Demo A"}
              headerTitle="Orion Demo A"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/orion-b"
          component={() => (
            <CustomizableScan
              algorithm={"generic_tag_orion_black"}
              customStyleClassName={"sample-video-invert"}
              cloudProducts={true}
              pageTitle={"Orion Demo B"}
              headerTitle="Orion Demo B"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/oriona-gs1"
          component={() => (
            <CustomizableScan
              algorithm={"gs1_qr_2_orion_beige"}
              customStyleClassName={"sample-video"}
              cloudProducts={true}
              pageTitle={"GS1 Orion Demo A"}
              headerTitle="GS1 Orion Demo A"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/orionb-gs1"
          component={() => (
            <CustomizableScan
              algorithm={"gs1_qr_2_orion_black"}
              customStyleClassName={"sample-video-invert"}
              cloudProducts={true}
              pageTitle={"GS1 Orion Demo B"}
              headerTitle="GS1 Orion Demo B"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/oriona-haifa"
          component={() => (
            <CustomizableScan
              algorithm={"haifa5_32k_orion_beige"}
              customStyleClassName={"sample-video"}
              cloudProducts={true}
              pageTitle={"Haifa Orion Demo A"}
              headerTitle="Haifa Orion Demo A"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/orionb-haifa"
          component={() => (
            <CustomizableScan
              algorithm={"haifa5_32k_orion_black"}
              customStyleClassName={"sample-video-invert"}
              cloudProducts={true}
              pageTitle={"Haifa Orion Demo B"}
              headerTitle="Haifa Orion Demo B"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/sample"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack"}
              customStyleClassName={"sample"}
              cloudProducts={true}
              pageTitle={"Demo App"}
              headerTitle="Demo App"
              logoFileName={"rome-miko-logo.svg"}
            />
          )}
        />
        <Route
          exact
          path="/sample2"
          component={() => {
            window.location.href = "https://www.hindustanpetroleum.com";
            return null;
          }}
        />
        <Route
          exact
          path="/sample2-video"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack2"}
              customStyleClassName={"sample-video"}
              cloudProducts={true}
              pageTitle={"Demo Video App"}
              headerTitle="Demo Video App"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/sample3"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack3"}
              customStyleClassName={"sample"}
              cloudProducts={true}
              pageTitle={"Demo App"}
              headerTitle="Demo App"
              logoFileName={"rome-miko-logo.svg"}
            />
          )}
        />
        <Route
          exact
          path="/sample4"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              customStyleClassName={"sample"}
              cloudProducts={true}
              pageTitle={"Demo App"}
              headerTitle="Demo App"
              logoFileName={"rome-miko-logo.svg"}
            />
          )}
        />
        <Route
          exact
          path="/scanner"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              headerTitle={"Web Scanner"}
              customStyleClassName={"scanner"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/toyota"
          component={() => (
            <CustomizableScan
              algorithm={"laava_13mm_imc_static"}
              headerTitle={"Web Scanner"}
              customStyleClassName={"scanner"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/ul"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4_orion_black"}
              customText={{
                instructions: "Tap below to upload photo",
                step1: " ",
                step2: " ",
                step3: " ",
              }}
              logoFileName={"laava-logo.png"}
              cloudProducts={true}
            />
          )}
        />
        <Route
          exact
          path="/brand-armor"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              customStyleClassName={"sample"}
              cloudProducts={true}
              pageTitle={"Demo App"}
              headerTitle="Demo App"
              logoFileName={"rome-miko-logo.svg"}
            />
          )}
        />
        <Route
          exact
          path="/stamp"
          component={() => (
            <CustomizableScan
              products={STAMP_PRODUCTS}
              algorithm={"resalestamp_red"}
              customStyleClassName={"stamp"}
            />
          )}
        />
        <Route
          exact
          path="/stamp-video"
          component={() => (
            <CustomizableScan
              products={STAMP_PRODUCTS}
              algorithm={"resalestamp_red"}
              customStyleClassName={"stamp"}
              video={true}
              disableDesktop={true}
              maxScanIndex={20}
            />
          )}
        />
        <Route
          exact
          path="/trove-pressure"
          component={() => (
            <CustomizableScan
              algorithm={"resalesticker_brady-naa4_aryllacolor"}
              readWrite={true}
              headerTitle="Trove X Arylla"
              customStyleClassName={"trove"}
            />
          )}
        />
        <Route
          exact
          path="/trove-heat"
          component={() => (
            <CustomizableScan
              algorithm={"resalesticker_rs-iron4_aryllacolor_laser"}
              readWrite={true}
              headerTitle="Trove X Arylla"
              customStyleClassName={"trove-dark"}
            />
          )}
        />
        <Route
          exact
          path="/unismart"
          component={() => (
            <CustomizableScan
              algorithm={"roots"}
              logoFileName={"roots-logo.svg"}
              customStyleClassName={"unismart"}
              pageTitle={"Roots X Arylla"}
              products={UNISMART_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/roots-auth"
          component={() => (
            <CustomizableScan
              algorithm={"auto_rootsnew"}
              logoFileName={"roots-logo.svg"}
              customStyleClassName={"unismart-auth"}
              pageTitle={"Roots Demo App"}
              headerTitle="Auth App"
              products={PRODUCTS_ROOTSNEW}
            />
          )}
        />
        <Route
          exact
          path="/roots-recomm"
          component={() => (
            <CustomizableScan
              algorithm={"auto_rootsnew"}
              customStyleClassName={"unismart-recommerce"}
              pageTitle={"Roots Demo App"}
              headerTitle="Demo App"
              logoFileName={"roots-logo.svg"}
              loadColor="#1b451a"
              msrpPrice={true}
              customText={{
                step2: "2) Tap ⚡ to turn on the flash",
                step3: "3) Take a photo of the label to resell",
              }}
            />
          )}
        />
        <Route
          exact
          path="/amiri"
          component={() => (
            <CustomizableScan
              algorithm={"rp6_cheap"}
              customStyleClassName={"arylla"}
            />
          )}
        ></Route>
        <Route
          exact
          path="/sicpa"
          component={() => (
            <CustomizableScan
              algorithm={"al3_m20_c160_t90_sicpa"}
              logoFileName={"sicpa-logo.svg"}
              customStyleClassName={"sicpa"}
              headerTitle={"SICPA Print Trial"}
              customText={{
                instructions: "How to use the web app:",
                step1: "1. Tap below to open the camera",
                step2: "2. Turn on the camera flash",
                step3: '3. Tap "Use Photo" to authenticate',
              }}
            />
          )}
        />
        <Route
          exact
          path="/tscl"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              pageTitle={"TSCL " + I18n.misc.Scanner}
              headerTitle={"TSCL " + I18n.misc.Scanner}
              hideTutorial={true}
              products={WECHAT_PRODUCTS}
              customStyleClassName={"tscl"}
              customText={{
                step1: I18n.misc.totalSecurityStep1,
                step2: I18n.misc.totalSecurityStep2,
                step3: I18n.misc.totalSecurityStep3,
              }}
            />
          )}
        />
        <Route
          exact
          path="/vinsak"
          component={() => (
            <CustomizableScan
              algorithm={"al3_m20_c160_t90_vinsak"}
              logoFileName={"vinsak-logo.png"}
              customStyleClassName={"vinsak"}
              headerTitle={"Vinsak Print Trial"}
              customText={{
                instructions: "How to use the web app:",
                step1: "1. Tap below to open the camera",
                step2: "2. Turn on the camera flash",
                step3: '3. Tap "Use Photo" to authenticate',
              }}
            />
          )}
        />
        <Route
          exact
          path="/wireframe"
          component={() => (
            <CustomizableScan
              algorithm={"fake_demo"}
              customStyleClassName={"wireframe"}
              products={WIREFRAME_PRODUCTS}
            />
          )}
        />
        <Route
          exact
          path="/wechat"
          component={() => (
            <CustomizableScan
              algorithm={"auto_samplepack4"}
              customStyleClassName={"sample-video"}
              products={WECHAT_PRODUCTS}
              pageTitle={"Orion Demo A"}
              headerTitle="Orion Demo A"
              logoFileName={"rome-miko-logo.svg"}
              video={true}
              disableDesktop={true}
            />
          )}
        />
        <Route
          exact
          path="/laava-embed"
          component={() => (
            <CustomizableScan wechat={true} customStyleClassName={"wechat"} />
          )}
        />
        <Route component={fourOhFour} />
      </Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (window.location.hostname === "id.tscl.hk") {
            return <Redirect to="/tscl" />;
          } else if (window.location.hostname === "staging.arylla.com") {
            window.open(
              `https://play.google.com/store/apps/details?id=com.arylla.demo&launch=true`,
              "_self"
            );
            return <Redirect to="/scanner" />;
          } else {
            return <Redirect to="/scanner" />;
          }
        }}
      />
      <Route
        exact
        path="/rpac"
        render={() => <Redirect to="/rpac/aryllatag_woven31_white_jm25" />}
      />
      <Route
        exact
        path="/goose"
        render={() => <Redirect to="/goose/aryllatag_woven31_white_jm25" />}
      />
      <Route
        exact
        path="/nike"
        render={() => <Redirect to="/nike/aryllatag_woven31_white_jm25" />}
      />
      <Route
        exact
        path="/aotc"
        render={() => <Redirect to="/aotc/aryllatag_paper31" />}
      />
      <Route
        path="/docs"
        component={() => {
          window.location.href = "https://arylla.readme.io";
          return null;
        }}
      />
    </Router>
  );
}

const fourOhFour = () => <h1>404 - Page not found.</h1>;

export default App;
