import React from "react";

import "./VelocityScan.scss";
import { CustomHeader, Scanner } from "SharedComponents";
import { PRODUCTS_VELOCITY } from "Constants";
import { withRouter } from "react-router";

class VelocityScan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      algorithm: "greyshorts_sparse1",
    };
  }

  componentDidMount() {
    document.title = "Arylla Demo";
  }

  render() {
    return (
      <div className="scan-page">
        <CustomHeader title={"Arylla Demo"} />
        <Scanner
          algorithm={this.state.algorithm}
          products={PRODUCTS_VELOCITY}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(VelocityScan);
