import React from "react";

import "./Launcher.scss";
class Launcher extends React.Component {
  launchApp() {
    const appLink = this.props.isAndroid
      ? this.props.linkButton.androidLink
      : this.props.linkButton.iOSLink;

    window.open(appLink, "_self");
  }

  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        {(this.props.customText.title || this.props.customText.description) && (
          <div className="instructions-container">
            {this.props.customText.title && (
              <span className="title">
                <h2>{this.props.customText.title}</h2>
              </span>
            )}
            {this.props.customText.description && (
              <span className="description">
                <h3>{this.props.customText.description}</h3>
              </span>
            )}
          </div>
        )}
        <div className="launch-button-container">
          <button
            type="button"
            className="launch-button"
            onClick={() => {
              this.launchApp();
            }}
          >
            {this.props.linkButton.text}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Launcher;
