import React from "react";

import "./WeChatScanner.scss";

class WeChatScanner extends React.Component {
  render() {
    return (
      <React.Fragment>
        <iframe
          className="embedded-site"
          id="inlineFrameExample"
          title="Inline Frame Example"
          src="https://alpha-scan.laava.id/?cfg=da&n=0&f=0&pa=0"
          allow="camera;microphone"
        ></iframe>
      </React.Fragment>
    );
  }
}

export default WeChatScanner;
