import React, { useState } from "react";
import { Transition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import { v4 as uuidv4 } from "uuid";

import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { CloseOutline } from "react-ionicons";

import "./VideoScanner.scss";
import I18n from "I18n";
import {
  HTTP,
  LOWERCASE_REDIRECTS,
  PRODUCTS as PRODUCTS_GENERIC,
} from "Constants";

import * as FirestoreService from "../../firebase";

var interval = null;
var uniqueID = null;
var videoIndex = null;
var maxScanIndex = 10;
var controller = null;
var signal = null;

const videoConstraints = {
  height: 720,
  width: 1080,
  facingMode: "environment",
};

class HttpError extends Error {
  constructor(status, response) {
    super(status);
    this.name = "HttpError";
    this.status = status;
    this.response = response;
  }
}

class VideoScanner extends React.Component {
  state = {
    isLoading: false,
    showScanResult: false,
    scanResult: false,
    showHelpModal: false,
    showWriteForm: false,
    showImageModal: false,
    formValue: "",
    preview: "",
    showImage: "",
    screenshot: null,
    showVideo: false,
    hasPermission: false,
    progressBar: 0,
  };

  goodPermission = () => {
    this.setState({ hasPermission: true });
  };

  startCamera = () => {
    this.setState({ showVideo: true });
    uniqueID = uuidv4();
    videoIndex = 0;
    console.log("video ON");
    console.log(this.state.progressBar);
    controller = new AbortController();
    signal = controller.signal;
    interval = setInterval(this.sendVideoPhoto, 1000);
  };

  sendVideoPhoto = async () => {
    if (!this.state.hasPermission) return;
    console.log("took photo");
    this.setState((prevState) => {
      return {
        ...prevState,
        progressBar: prevState.progressBar + (1 / maxScanIndex) * 100,
      };
    });
    console.log(this.state.progressBar);
    videoIndex = videoIndex + 1;
    const startDateTime = Date.now();

    const imageSrc = this.refs.webcam.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());

    const formData = new FormData();

    formData.append("image", blob);
    formData.append(
      "algorithm",
      this.redirectAlgorithmCase(this.props.algorithm).toString()
    );
    formData.append("downsamplePercent", 0.355);
    if (this.props.url) {
      formData.append("source", this.props.url);
    }

    formData.append(
      "dateTimeLog",
      JSON.stringify({ start: startDateTime, send: Date.now() })
    );

    formData.append(
      "video",
      JSON.stringify({ uniqueID: uniqueID, videoIndex: videoIndex })
    );

    try {
      const response = await fetch(`${HTTP.BASE_URL}/v1/scan`, {
        method: "POST",
        body: formData,
        signal: signal,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response);
      }

      const responseJson = await response.json();

      if (responseJson.result > 0 && responseJson.videoId === uniqueID) {
        const product = await this.lookupProduct(
          responseJson.project,
          responseJson.result
        );

        const massagedResults = this.massageScanResult(responseJson, product);

        this.setState(
          {
            showScanResult: true,
            scanResult: massagedResults,
          },
          () => {
            this.stopCamera();
          }
        );
      } else {
        if (
          videoIndex >=
          (this.props.maxScanIndex ? this.props.maxScanIndex : maxScanIndex)
        ) {
          this.setState(
            {
              showScanResult: true,
              scanResult: {
                title: "Scan Timeout",
                titleStyle: "danger",
                body:
                  "Reached maximum scan attempts: " +
                  (this.props.maxScanIndex
                    ? this.props.maxScanIndex
                    : maxScanIndex),
              },
            },
            () => {
              this.stopCamera();
            }
          );
        }
      }
      console.log(responseJson);
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Other error: ", err);
      }
    }
  };

  stopCamera = () => {
    clearInterval(interval);
    uniqueID = "";
    controller.abort();
    this.setState({ showVideo: false, hasPermission: false, progressBar: 0 });
  };

  massageScanResult(response, product = null) {
    const result = response.result;
    const project = response.project;
    if (result > 0) {
      if (product.isCounterfeit) {
        return {
          title: I18n.scan.result.nonAuthentic.title,
          titleStyle: "danger",
          body: I18n.scan.result.nonAuthentic.description,
        };
      } else {
        const bodyTextArray = [I18n.scan.result.authentic.description];
        if (!product.isFound || product.showSerial) {
          bodyTextArray.pop(); //Remove the authentic description so the serial number is more obvious
          bodyTextArray.push(
            this.props.customText.serial || I18n.scan.result.authentic.serial
          );
          bodyTextArray.push(result.toString());
        }

        if (product.readWrite) {
          return {
            title: this.props.hideAuthentic
              ? ""
              : this.props.customText.authentic ||
                I18n.scan.result.authentic.title,
            body: bodyTextArray.join(" "),
            titleStyle: this.props.hideAuthentic ? "" : "success",
            project: project,
            serial: result,
            readWrite: true,
          };
        }

        return {
          title: this.props.hideAuthentic
            ? product.title
              ? product.title
              : ""
            : this.props.customText.authentic ||
              I18n.scan.result.authentic.title,
          titleStyle: this.props.hideAuthentic ? "" : "success",
          body: bodyTextArray.join(" "),
          product: product,
        };
      }
    }

    if (result === 0) {
      return {
        title:
          this.props.customText.counterfeitScanTitle ||
          I18n.scan.result.nonAuthentic.title,
        titleStyle: "danger",
        body:
          this.props.customText.counterfeitScanMessage ||
          I18n.scan.result.nonAuthentic.description,
      };
    }

    return {
      title: this.props.customText.errorScanTitle || I18n.errors.scanFailed,
      titleStyle: "warning",
      body:
        this.props.customText.errorScanMessage ||
        I18n.errors[result] ||
        I18n.errors.imageProcessingGeneric,
    };
  }

  closeResultDrawer = () => {
    if (this.state.showScanResult) {
      this.setState({ showScanResult: false });
    }
    if (this.state.showWriteForm) {
      this.setState({ showWriteForm: false });
    }
    //Reset state variables
    this.setState({
      scanResult: false,
      formValue: "",
    });
  };

  async lookupProduct(project, serialNumber) {
    if (serialNumber <= 0) return null;
    if (this.props.cloudProducts) {
      let product = await FirestoreService.getDemoProduct(
        project,
        serialNumber
      );
      if (!product) {
        const aliasDoc = await FirestoreService.getDocument("alias", project);
        if (aliasDoc) {
          product = await FirestoreService.getDemoProduct(
            aliasDoc.alias,
            serialNumber
          );
        }
      }
      if (!product) {
        product = await FirestoreService.getDemoProduct(project, "default");
      }
      if (!product) {
        const aliasDoc = await FirestoreService.getDocument("alias", project);
        if (aliasDoc) {
          product = await FirestoreService.getDemoProduct(
            aliasDoc.alias,
            "default"
          );
        }
      }
      if (product) {
        return {
          title: product.product.title,
          subtitle: product.product.description,
          imageSrc:
            `${process.env.PUBLIC_URL}` +
            product.product.image.replace("https://demo.arylla.com", ""),
          footnote: product.product.footnote
            ? product.product.footnote
            : `Serial #: ${serialNumber}`,
          productUrl: product.product.link,
          externalLink: product.product.externalLink,
          isCounterfeit: false || product.product.isCounterfeit,
          isFound: true,
          showSerial: false || product.product.showSerial,
        };
      } else {
        let productResponse = {
          isFound: false,
          isCounterfeit: false,
        };
        if (this.props.readWrite) productResponse["readWrite"] = true;
        return productResponse;
      }
    } else {
      const product = this.props.products
        ? this.props.products.hasOwnProperty(serialNumber)
          ? this.props.products[serialNumber]
          : this.props.products["default"]
        : //If the serial number doesn't exist, use 'defualt'.
          //If 'default' doesn't exist either, then it'll return an empty object,
          //which is checked next
          PRODUCTS_GENERIC[serialNumber];
      if (product) {
        return {
          title: product.title,
          titleImage: product.titleImage,
          subtitle: product.subtitle,
          imageSrc: product.image,
          footnote: product.footnote
            ? product.footnote
            : `Serial #: ${product.serialNumber}`,
          productUrl: product.link,
          isCounterfeit: false || product.isCounterfeit,
          externalLink: product.externalLink,
          isFound: true,
          showSerial: false || product.showSerial,
        };
      } else {
        let productResponse = { isFound: false };
        if (this.props.readWrite) productResponse["readWrite"] = true;
        return productResponse;
      }
    }
  }

  redirectAlgorithmCase(algorithm) {
    algorithm = algorithm.toLowerCase();
    return LOWERCASE_REDIRECTS[algorithm] || algorithm;
  }

  render() {
    const {
      isLoading,
      scanResult,
      showScanResult,
      showHelpModal,
      progressBar,
    } = this.state;
    const scanButtonText = isLoading
      ? ""
      : this.props.customText.scanButton
      ? this.props.customText.scanButton
      : I18n.scan.scanButton;
    const loadingClass = isLoading ? "loading" : "";
    const showFullDrawerClass =
      scanResult.product || scanResult.firestoreProduct ? "full" : "";

    return (
      <React.Fragment>
        <div className="scan-container-video" onClick={this.closeResultDrawer}>
          {this.props.linkButton && (
            <button
              className="link-button"
              onClick={() => window.open(this.props.linkButton.link, "_self")}
            >
              {this.props.linkButton.text}
            </button>
          )}
          <div className="instructions-container-video">
            <span className="title-video">
              <b>
                {this.props.customText.instructions
                  ? this.props.customText.instructions
                  : I18n.scan.instructions.title}
              </b>
            </span>
            <span className="step-video">
              {this.props.customText.step1
                ? this.props.customText.step1
                : I18n.scan.instructions.videoStep1}
            </span>
            <span className="step-video">
              {this.props.customText.step2
                ? this.props.customText.step2
                : I18n.scan.instructions.videoStep2}
            </span>
            <span className="step-video">
              {this.props.customText.step3
                ? this.props.customText.step3
                : I18n.scan.instructions.videoStep3}
            </span>
          </div>
          <div className="scan-button-container-video">
            <button
              className={`scan-button-video ${loadingClass}`}
              onClick={this.startCamera}
            >
              {scanButtonText}
            </button>
          </div>

          <button
            onClick={() => this.setState({ showHelpModal: true })}
            className="help-link-video"
          >
            {this.props.customText.tutorial
              ? this.props.customText.tutorial
              : I18n.scan.tutorial}
          </button>

          <div className="cam-container">
            <div className="cam-view">
              {this.state.showVideo && (
                <>
                  <Webcam
                    audio={false}
                    height={720}
                    width={1080}
                    ref="webcam"
                    screenshotFormat="image/jpeg"
                    onUserMedia={this.goodPermission}
                    videoConstraints={videoConstraints}
                  />
                  {this.state.hasPermission && (
                    <>
                      <button
                        className="close-video-stream"
                        onClick={this.stopCamera}
                      >
                        <div className="video-stream-progress">
                          <CircularProgressbarWithChildren
                            value={progressBar}
                            strokeWidth={4}
                            styles={buildStyles({
                              pathColor: "#666666",
                              trailColor: "#ffffff",
                              rotation: 0.5,
                              strokeLinecap: "round",
                            })}
                          >
                            <div>
                              <CloseOutline
                                className="close-video-stream-icon"
                                color={"#ffffff"}
                                height="70px"
                                width="70px"
                              />
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={`help-container-video ${showHelpModal ? "show" : ""}`}
          onClick={() => this.setState({ showHelpModal: false })}
        >
          <div className="help-modal-video">
            <div className="instructions-gif-video">
              <div
                className="close-button-video"
                onClick={() => this.setState({ showHelpModal: false })}
              >
                x
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/${
                  this.props.instructionsVideo || "photo-instructions.gif"
                }`}
                alt="instructional video"
              ></img>
            </div>
          </div>
        </div>

        <Transition in={showScanResult} timeout={1000}>
          {(transitionState) => (
            <div
              className={`result-container-video ${transitionState} ${showFullDrawerClass}`}
              onClick={this.closeWriteForm}
            >
              <div className="result-card-title-video">
                {this.props.customText.result || I18n.scan.result.title}
              </div>
              <div className={`results-card-video `}>
                <div className={`header-video ${scanResult.titleStyle}`}>
                  <span>{scanResult.title}</span>
                </div>
                <div className="body-video">{scanResult.body}</div>
              </div>
              <div className="result-body-video">
                {scanResult.product && (
                  <React.Fragment>
                    <h2 className="title-video">
                      {scanResult.product.titleImage && (
                        <img
                          alt={"product"}
                          className="product-image"
                          src={scanResult.product.titleImage}
                        />
                      )}
                      {this.props.hideAuthentic ? "" : scanResult.product.title}
                    </h2>
                    <h3 className="subtitle-video">
                      {scanResult.product.subtitle}
                    </h3>
                    {scanResult.product.imageSrc && (
                      <div
                        className="image-wrapper-video"
                        onClick={() => {
                          if (scanResult.product.productUrl) {
                            window.open(
                              scanResult.product.productUrl,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {scanResult.product.productUrl && (
                          <FontAwesomeIcon
                            className="link-icon-video"
                            icon={faExternalLinkAlt}
                          />
                        )}
                        <img
                          alt={"product"}
                          className="product-image-video"
                          src={scanResult.product.imageSrc}
                        />
                      </div>
                    )}
                    <span className="footnote-video">
                      {scanResult.product.footnote}
                    </span>
                    {this.props.dummyButton && scanResult.product.isFound && (
                      <button className="dummy-button-video" disabled>
                        {this.props.dummyButton}
                      </button>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}

export default VideoScanner;
