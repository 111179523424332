import React from "react";
import "./BenButton.scss";

class BenButton extends React.Component {
  constructor(props) {
    super(props);
    const textLength = props.label.length * 9 + 100;
    const windowLength = textLength + 27.1;
    const iconX = props.isDisabled
      ? props.reverse
        ? 75
        : windowLength + 40
      : props.reverse
      ? 240
      : windowLength + 220;
    const line = props.reverse
      ? "M0,76 h" +
        textLength.toString() +
        " c15,0,27.2-12.2,27.2-27.2 V27.2 c0-15-12.2-27.2-27.2-27.2l-13.5,0 L17.9,0 L0,0 l0,76 z"
      : "M265.5,0 H40.7 H27.2 C12.2,0,0,12.2,0,27.2 v21.6 C0,63.8,12.2,76,27.2,76 h" +
        textLength.toString() +
        " V0 H265.5z";

    this.state = {
      width: line,
      view: "0 0 " + windowLength.toString() + " 76",
      iconX: iconX,
    };
  }

  render() {
    const { width, view, iconX } = this.state;
    return (
      <React.Fragment>
        <svg
          className={`ben-button ${this.props.reverse ? "reverse" : ""}`}
          onClick={this.props.isDisabled ? null : this.props.handleClick}
          viewBox={view}
        >
          <path
            d={width}
            className={`ben-button-shape ${
              this.props.isDisabled ? "disabled" : ""
            }`}
          />
          <text className="ben-button-text" x="50%" y="50%" textAnchor="middle">
            {this.props.label}
            <React.Fragment>
              {this.props.isDisabled ? (
                <tspan
                  className="material-icons ben-button-x"
                  dy="14"
                  x={iconX}
                >
                  close
                </tspan>
              ) : this.props.reverse ? (
                <tspan
                  className="material-icons ben-button-arrow"
                  dy="19"
                  x={iconX}
                >
                  chevron_left
                </tspan>
              ) : (
                <tspan
                  className="material-icons ben-button-arrow"
                  dy="19"
                  x={iconX}
                >
                  chevron_right
                </tspan>
              )}
            </React.Fragment>
          </text>
        </svg>
      </React.Fragment>
    );
  }
}

export default BenButton;
